import ReactJson from 'react-json-view';
import { Grid } from '@mui/material';
import { useIdentifications } from 'src/hooks/useIdentifications';
import { xsSize, sectionStyle } from '../sites/UserDetailSite';

export const IdentificationComponent = ({ userId }: { userId: string }) => {
  const identification = useIdentifications({
    userInfo: {
      userId,
    } as any,
    page: 0,
    forceUpdate: false,
  });
  if (!identification) {
    return <p>Loading...</p>;
  }
  if (identification?.length === 0) {
    return <p></p>;
  }
  return (
    <Grid item xs={xsSize * 2} style={{ ...sectionStyle }}>
      {/* <p style={{ borderRadius: '10px', textAlign: 'center', fontSize: '110%', fontWeight: 'bold' }}>Identification Details</p> */}
      <ReactJson src={identification as object} />
    </Grid>
  );
};
