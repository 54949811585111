import { Table, TableBody, TableRow, TableCell } from '@mui/material';
import { FC } from 'react';
import { cellStyle } from 'src/sites/UserDetailSite';

export const ObjectComponent: FC<{ obj: Record<string, any> | null }> = ({ obj }) => {
  if (!obj) {
    return null;
  }
  return (
    <Table>
      <TableBody>
        {Object.keys(obj)
          .map((key) => {
            if (key === '_id' || key === '__v') {
              return null;
            }
            return (
              <TableRow key={key + obj} hover={true}>
                <TableCell style={cellStyle}>{key}</TableCell>
                <TableCell style={cellStyle}>{obj[key]}</TableCell>
              </TableRow>
            );
          })
          .filter((x) => x !== null)}
      </TableBody>
    </Table>
  );
};
