import { FC, useState } from 'react';
import { Button, Grid } from '@mui/material';
import { UserItem } from 'src/models/users';
import AddBonsDialog from 'src/components/user/AddBonsDialog';
import PayoutDialog from 'src/components/user/PayoutDialog';
import { finishRestoreProcess, getUserDocument, startRestoreProcess } from 'src/api/oApi';
import { downloadPDFDocument } from 'src/helpers/downloadFile';
import { generalStore } from 'src/stores/GeneralStore';
import { SupportCaseByIdResponse } from 'src/models/supportcaseById';
import SendMessageDialog from './support/SendMessageDialog';
import SolveCaseFailedPayoutDialog from './support/SolveCaseFailedPayoutDialog';
import SolveCaseManualDocumentCheckDialog from './support/SolveCaseManualDocumentCheckDialog';
import SolveCaseManualUploadDialog from './support/SolveCaseManualUploadDialog';
import { ConfirmationDialog } from './ui/ConfirmationDialog';

export const Actions: FC<{ user: UserItem | null; forceReload?: () => void; supportCase?: SupportCaseByIdResponse | null }> = ({
  user,
  supportCase,
  forceReload,
}) => {
  if (!user) return null;
  const [showPayoutAccountForm, setShowPayoutAccountForm] = useState(false);
  const [showBonusForm, setShowBonusForm] = useState(false);
  const [manualCheckDialog, setManualCheckDialog] = useState(false);
  const [showSendMessageDialog, setShowSendMessageDialog] = useState(false);
  const [failedPayoutDialog, setFailedPayoutDialog] = useState(false);
  const [manualDocumentUpload, setManualDocumentUpload] = useState(false);
  return (
    <Grid container xs={12} style={{ justifyContent: 'left' }}>
      <AddBonsDialog open={showBonusForm} setOpen={setShowBonusForm} userData={user} />
      <PayoutDialog open={showPayoutAccountForm} setOpen={setShowPayoutAccountForm} userData={user} />
      <EditBonusButton onClick={() => setShowBonusForm(true)} userData={user} />
      <EditPayoutAccountButton onClick={() => setShowPayoutAccountForm(true)} userData={user} />
      <DownloadIdentificationButton userData={user} />
      <RestoreProcessButtons userData={user} />
      <SolveCaseManualDocumentCheckDialog supportCase={supportCase} open={manualCheckDialog} setOpen={setManualCheckDialog} />
      <SendMessageDialog
        supportCase={supportCase}
        open={showSendMessageDialog}
        setOpen={setShowSendMessageDialog}
        forceUpdate={() => (forceReload ? forceReload() : console.log(''))}
      />
      <SolveCaseManualUploadDialog supportCase={supportCase} open={manualDocumentUpload} setOpen={setManualDocumentUpload} />
      <SolveCaseFailedPayoutDialog supportCase={supportCase} open={failedPayoutDialog} setOpen={setFailedPayoutDialog} />
      <SolveManualCheckCaseButton supportCase={supportCase} onClick={() => setManualCheckDialog(true)} />
      <SolveManualDocumentUploadCaseButton supportCase={supportCase} onClick={() => setManualDocumentUpload(true)} />
      <SolveFailedPayoutCaseButton supportCase={supportCase} onClick={() => setFailedPayoutDialog(true)} />
      {supportCase ? <ActionButton onClick={() => setShowSendMessageDialog(true)} text="Send Message" /> : null}
    </Grid>
  );
};

export const ActionButton: FC<{ onClick: () => void; text: string; loading?: boolean }> = ({ onClick, text }) => {
  return (
    <Button
      style={{
        margin: '5px',
        padding: '8px 16px 8px 16px',
        fontSize: '15px',
        fontFamily: 'Aestetico-semibold',
        backgroundColor: '#1F847F',
        fontWeight: 'normal',
        color: '#FFFFFF',
        textTransform: 'none',
      }}
      variant="contained"
      onClick={onClick}
    >
      {text}
    </Button>
  );
};

export const EditBonusButton = ({ userData, onClick }: { userData: { status: string }; onClick: any }) => {
  return userData.status === 'active' ? <ActionButton text="Add Bonus" onClick={onClick} /> : null;
};

export const EditPayoutAccountButton = ({ userData, onClick }: { userData: { status: string }; onClick: any }) => {
  return userData.status === 'active' ? <ActionButton text="Edit Payout Account" onClick={onClick} /> : null;
};

export const EditTaxIDButton = ({ userData, onClick }: { userData: { status: string }; onClick: any }) => {
  return userData.status === 'active' ? <ActionButton text="Edit Tax ID" onClick={onClick} /> : null;
};

export const DownloadIdentificationButton = ({
  userData,
}: {
  userData: { userId: string; registrationProcessStatus: { kycDocuments: string } };
}) => {
  return userData?.registrationProcessStatus?.kycDocuments === 'accepted' ? (
    <ActionButton
      text="Download Identification Document"
      onClick={async () => {
        try {
          const data = await getUserDocument(userData.userId as string);
          downloadPDFDocument(data);
        } catch (error: any) {
          generalStore.setError(error?.response?.data?.error?.message || error?.response?.data?.error || JSON.stringify(error));
        }
      }}
    />
  ) : null;
};

export const RestoreProcessButtons = ({
  userData,
}: {
  userData: { userId: string; email: string; status: string; wallet: { address?: string } };
}) => {
  const [startRestoreConfirmationDialogOpen, setStartRestoreConfirmationDialogOpen] = useState(false);
  const [finishRestoreConfirmationDialogOpen, setFinishRestoreConfirmationDialogOpen] = useState(false);

  return (
    <>
      <ConfirmationDialog
        title="Start Restore Process?"
        submitBtnLabel="Start"
        onSubmit={async () => {
          try {
            generalStore.setIsLoading(true);
            await startRestoreProcess({ userId: userData?.userId as string });
            generalStore.setMessage('Restore Process Started');
          } catch (err: any) {
            console.error(err);
            generalStore.setError(err.message, err);
          } finally {
            generalStore.setIsLoading(false);
          }
        }}
        isOpened={startRestoreConfirmationDialogOpen}
        close={() => setStartRestoreConfirmationDialogOpen(false)}
      />
      <ConfirmationDialog
        title="Finish Restore Process?"
        submitBtnLabel="Finish"
        onSubmit={async () => {
          try {
            generalStore.setIsLoading(true);
            await finishRestoreProcess({ userId: userData?.userId as string });
            generalStore.setMessage('Restore Process Finished');
          } catch (err: any) {
            console.error(err);
            generalStore.setError(err.message);
          } finally {
            generalStore.setIsLoading(false);
          }
        }}
        isOpened={finishRestoreConfirmationDialogOpen}
        close={() => setFinishRestoreConfirmationDialogOpen(false)}
      />
      {userData.status === 'active' || userData.status === 'pending' ? (
        <ActionButton
          text="Start Restore Process"
          loading={generalStore.isLoading}
          onClick={() => setStartRestoreConfirmationDialogOpen(true)}
        />
      ) : null}
      {userData.status === 'restoring' && !!userData?.wallet.address ? (
        <ActionButton
          loading={generalStore.isLoading}
          text="Finish Restore Process"
          onClick={() => setFinishRestoreConfirmationDialogOpen(true)}
        />
      ) : null}
    </>
  );
};

const SolveManualCheckCaseButton = ({ supportCase, onClick }: { supportCase?: SupportCaseByIdResponse | null; onClick: any }) => {
  if (!supportCase) return null;
  const shouldShowSolveManualCheck =
    (supportCase.supportCaseType === 'identificationManualDocumentCheck' ||
      supportCase.supportCaseType === 'identificationPEP' ||
      supportCase.supportCaseType === 'identificationWrongInputData') &&
    supportCase.status !== 'closed';
  if (!shouldShowSolveManualCheck) {
    return null;
  }
  return <ActionButton onClick={onClick} text="ManualCheck Fall lösen" />;
};

const SolveFailedPayoutCaseButton = ({ supportCase, onClick }: { supportCase?: SupportCaseByIdResponse | null; onClick: any }) => {
  if (!supportCase) return null;
  const shouldShowMangopayFailedPayout = supportCase.supportCaseType === 'mangopayFailedPayout' && supportCase.status !== 'closed';
  if (!shouldShowMangopayFailedPayout) return null;
  return <ActionButton onClick={onClick} text="FailedPayout Solve Case" />;
};

const SolveManualDocumentUploadCaseButton = ({ supportCase, onClick }: { supportCase?: SupportCaseByIdResponse | null; onClick: any }) => {
  if (!supportCase) return null;
  const shouldShowManualDocumentUpload = supportCase.supportCaseType === 'mangopayManualDocumentUpload' && supportCase.status !== 'closed';
  if (!shouldShowManualDocumentUpload) return null;
  return <ActionButton onClick={onClick} text="ManualDocumentUpload Solve Case" />;
};
