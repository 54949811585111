import { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { routes } from './AppRouter';

interface Props {
  isAuthenticated: boolean;
}

export const NotAuthRoute: FC<Props> = ({ isAuthenticated }) => {
  return !isAuthenticated ? <Outlet /> : <Navigate to={routes.USER} />;
};
