import { FC, useState } from 'react';
import { UserItem } from '../../../models/users';
import { useBlockchainOffers } from '../../../hooks/useBlockchainOffers';
import { TableCell, TableRow, Typography } from '@mui/material';
import { BrickwiseTable } from '../../mui-overrides/BrickwiseTable';
import { CopyToClipboardButton } from '../../ui/CopyToClipboardButton';
import { setStateQueryParameter } from '../../../hooks/useStateQueryParams';
import { LoadingInline } from '../../ui/LoadingInline';

const rowsPerPage = 10;

export const UserBlockchainOffers: FC<{ wallet: UserItem['wallet'] }> = ({ wallet }) => {
  const [page, setPage] = setStateQueryParameter<number>(0, 'offersPage', 'number');
  const [isLoading, setIsLoading] = useState(true);
  const offers = useBlockchainOffers(
    wallet.address,
    () => setIsLoading(true),
    () => setIsLoading(false),
  );
  const paginatedOffers = offers.slice(page * rowsPerPage, (page + 1) * rowsPerPage);
  const renderHeader = () => (
    <TableRow>
      <TableCell>Offer ID</TableCell>
      <TableCell>Asset ID</TableCell>
      <TableCell>Token ID</TableCell>
      <TableCell>Price</TableCell>
      <TableCell>Amount</TableCell>
      <TableCell>Stage</TableCell>
      <TableCell>Status</TableCell>
      <TableCell>Type</TableCell>
      <TableCell>Contact type</TableCell>
      <TableCell>First party</TableCell>
    </TableRow>
  );
  const renderRows = () => {
    if (!offers || offers.length === 0) {
      return (
        <TableRow>
          <TableCell align="center" colSpan={100}>
            No offers found.
          </TableCell>
        </TableRow>
      );
    }
    return paginatedOffers.map((offer) => (
      <TableRow key={offer.offerId}>
        <TableCell>{offer.offerId}</TableCell>
        <TableCell>{offer.assetId}</TableCell>
        <TableCell>
          <CopyToClipboardButton text={offer.tokenId} label={offer.tokenId} shouldTruncate />
        </TableCell>
        <TableCell>{offer.price}</TableCell>
        <TableCell>{offer.amount}</TableCell>
        <TableCell>{offer.assetStage}</TableCell>
        <TableCell>{offer.status}</TableCell>
        <TableCell>{offer.type}</TableCell>
        <TableCell>{offer.contactType}</TableCell>
        <TableCell>{<CopyToClipboardButton text={offer.firstParty} label={offer.firstParty} shouldTruncate />}</TableCell>
      </TableRow>
    ));
  };
  return (
    <>
      <Typography variant="h6" mt={6} component="h2" align="center">
        Offers
      </Typography>
      {isLoading ? (
        <LoadingInline />
      ) : (
        <BrickwiseTable
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          hidePagination={false}
          hideChangePageLengthChange={false}
          length={offers?.length ?? 0}
          renderHeader={renderHeader}
          renderRows={renderRows}
        />
      )}
    </>
  );
};
