import { useEffect, useState } from 'react';
import { apiErrorHandling } from 'src/api/apierrorhandling';
import { getSupportCases } from 'src/api/oApi';
import { SupportCaseResponse } from 'src/models/supportcases';
import { generalStore } from 'src/stores/GeneralStore';
import { cleanFilter } from './useOffers';

export const useSupportCases = (params: { page?: number; status?: string; type?: string; userId?: string }) => {
  const [supportCases, setSupportCases] = useState<SupportCaseResponse | null>(null);
  const { page, status, type, userId } = params;
  useEffect(() => {
    const load = async () => {
      generalStore.setIsLoading(true);
      try {
        const filters = {
          page,
          status,
          type,
          userId,
        };
        cleanFilter(filters);
        const response = await getSupportCases(filters);
        console.log('filters', filters);
        console.log('supportcases', response.data.data);
        setSupportCases(response.data.data);
        generalStore.setIsLoading(false);
      } catch (error: any) {
        apiErrorHandling(error);
        generalStore.setIsLoading(false);
      } finally {
      }
    };
    load();
  }, [page, status, type, userId]);
  return supportCases;
};

export const fetchSupportCases = async (params: { page?: number; status?: string; type?: string; userId?: string }) => {
  const { page, status, type, userId } = params;
  generalStore.setIsLoading(true);
  const filters = {
    page,
    status,
    type,
    userId,
  };
  cleanFilter(filters);
  const response = await getSupportCases(filters);
  generalStore.setIsLoading(false);
  return response.data.data;
};
