import { FC, ReactElement } from 'react';
import { UserItem } from 'src/models/users';

export const PayoutAccountComponent: FC<{ accounts: UserItem['payoutAccounts'] }> = ({ accounts }) => {
  if (accounts?.length === 0) {
    return null;
  }
  // if user does have a active account show only active account
  const activeAccount = accounts?.find((account) => account.isActive);
  const showingAccouns = activeAccount ? [activeAccount] : accounts;
  if (!showingAccouns) {
    return null;
  }
  const Component = showingAccouns.map((payoutAccount) => (
    <>
      <p key={payoutAccount.id} style={{ margin: '5px', marginTop: '10px' }}>
        <span
          style={{
            fontWeight: 'normal',
            color: payoutAccount.isActive ? 'rgb(140, 204, 163)' : 'rgb(241, 132, 122)',
          }}
        >
          {payoutAccount.isActive ? 'Active' : 'Inactive'}
        </span>
        <span
          style={{
            borderRadius: '4px',
            borderStyle: 'solid',
            border: 'none',
            padding: '4px',
            margin: '9px',
          }}
        >
          {payoutAccount.IBAN}
        </span>
      </p>
    </>
  ));

  return Component as unknown as ReactElement<any, any>;
};
