import { render } from 'react-dom';
import App from './App';
import './i18n/i18n';
import './index.css';
import reportWebVitals from './reportWebVitals';

const container = document.getElementById('app');

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
render(<App />, container);

reportWebVitals();
