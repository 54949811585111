import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import { LoadingButton } from '@mui/lab';

export const ConfirmationDialog = ({
  title,
  submitBtnLabel,
  isOpened,
  close,
  onSubmit,
}: {
  title: string;
  submitBtnLabel: string;
  isOpened: boolean;
  close: () => void;
  onSubmit: () => any;
}) => {
  return (
    <Dialog open={isOpened} onClose={close} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogActions>
        <Button variant="outlined" onClick={close}>
          Cancel
        </Button>
        <LoadingButton onClick={onSubmit} fullWidth sx={{ m: 1 }} color="primary" variant="contained">
          {submitBtnLabel}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
