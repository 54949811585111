import { useEffect, useState } from 'react';
import { apiErrorHandling } from 'src/api/apierrorhandling';
import { getTransactions, TransactionMethod, TransactionStatus, TransactionType } from 'src/api/oApi';
import { TransactionsResponse } from 'src/models/transaction';
import { generalStore } from 'src/stores/GeneralStore';
import { cleanFilter } from './useOffers';

export const useTransactions = (
  params: {
    page?: number;
    to?: string;
    from?: string;
    method?: TransactionMethod;
    sort?: 'oldest' | 'newest';
    status?: TransactionStatus;
    type?: TransactionType;
    userId?: string;
  },
  reload: boolean,
) => {
  const [transactions, setTransactions] = useState<TransactionsResponse['data'] | null>(null);
  const { page, to, from, method, sort, status, type, userId } = params;
  useEffect(() => {
    const load = async () => {
      generalStore.setIsLoading(true);
      try {
        const filters = {
          page,
          dateMax: to,
          dateMin: from,
          method,
          sort,
          status,
          type,
          userId,
        };
        cleanFilter(filters);
        const response = await getTransactions(filters);
        setTransactions({
          count: response.data.data.count,
          data: response.data.data.data,
        });
      } catch (error: any) {
        apiErrorHandling(error);
      } finally {
        generalStore.setIsLoading(false);
      }
    };
    load();
  }, [page, to, from, method, sort, status, type, userId, reload]);
  return transactions;
};
