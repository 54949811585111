import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { LoadingButton } from '@mui/lab';
import { TextField } from '@mui/material';
import { postSolveManualCheckSupportCase } from 'src/api/oApi';
import { generalStore } from 'src/stores/GeneralStore';
import { SupportCaseByIdResponse } from 'src/models/supportcaseById';

// eslint-disable-next-line @typescript-eslint/naming-convention
export default function SolveCaseManualDocumentCheckDialog({
  open,
  setOpen,
  supportCase,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  supportCase?: SupportCaseByIdResponse | null;
}) {
  if (!supportCase) return null;
  const handleClose = () => {
    setOpen(false);
  };

  const [message, setMessage] = React.useState<string | null>(null);

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Manual Check</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div>
              Wenn ein SupportFall einfach nur einen manuellen Check benötigt (PEP / Andere Daten im Dokument / Falsche Ausweisdaten) kann
              dieser hier freigegeben werden. Vergewissere dich, dass davor alle nötigen Änderungen gemacht wurden.
            </div>
            <TextField
              fullWidth
              sx={{ m: 1 }}
              placeholder="Message"
              type="text"
              name="messageCase1"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            disabled={!message}
            onClick={async () => {
              try {
                generalStore.setIsLoading(true);
                await postSolveManualCheckSupportCase({
                  message: message as string,
                  supportCaseId: supportCase.id,
                });
                generalStore.setMessage('success: Successfully Created the Message');
                generalStore.setIsLoading(false);
                setOpen(false);
                setMessage(null);
              } catch (error: any) {
                generalStore.setIsLoading(false);
                generalStore.setError(error?.response?.data?.error?.message || error?.response?.data?.error);
              }
            }}
            fullWidth
            color="primary"
            variant="contained"
          >
            User freigeben
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
