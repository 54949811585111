/**
 * Api for centralized services.
 */

import axios from 'axios';
import { BackendResponse } from 'src/models';
import { PostLoginResponse } from 'src/models/login';
import { GetQuestionsResponse, PostQuestionPayload } from 'src/models/questions';
import { REACT_APP_API_PLATFORM_URL } from 'src/util/config';

const api = axios.create({
  baseURL: REACT_APP_API_PLATFORM_URL,
  withCredentials: true,
});

export const postLogin = async (email: string, password: string) => {
  return await api.post<BackendResponse<PostLoginResponse>>('/auth/login', {
    email,
    password,
  });
};

export const getSession = () => {
  return api.get<BackendResponse<PostLoginResponse>>('/auth/checksession');
};

export const postLogout = async () => {
  return api.post('/auth/logout');
};

export const postQuestion = async (body: PostQuestionPayload) => {
  return api.post('/api/v1/support/question', body);
};

export const putQuestion = async (questionId: string, body: PostQuestionPayload) => {
  return api.put(`/api/v1/support/question/${questionId}`, body);
};

export const deleteQuestion = async (questionId: string) => {
  return api.delete(`/api/v1/support/question/${questionId}`);
};

export const getQuestions = async () => {
  return api.get<GetQuestionsResponse[]>('/api/v1/support/questions');
};
