import { Grid, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { FC, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

interface Props {
  userId?: string | null;
  setUserId?: any;
  firstName?: string | null;
  setFirstName?: any;
  type?: string | null;
  setType?: any;
  method?: string | null;
  setMethod?: any;
  status?: string | null;
  setStatus?: any;
  sort?: string | null;
  setSort?: any;
  from?: string | null;
  setFrom?: any;
  to?: string | null;
  setTo?: any;
  setPage?: any;
}

export const TransactionFilterDialog: FC<Props> = ({
  userId,
  setUserId,
  type,
  setType,
  method,
  setMethod,
  status,
  setStatus,
  sort,
  setSort,
  from,
  setFrom,
  setTo,
  to,
}) => {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    setUserId(searchParams.get('userId'));
    setType(searchParams.get('type'));
    setMethod(searchParams.get('method'));
    setStatus(searchParams.get('status'));
    setSort(searchParams.get('sort'));
    setFrom(searchParams.get('from'));
    setTo(searchParams.get('to'));
  }, []);
  const selectWidth = '200px';
  return (
    <>
      <Grid container spacing={1}>
        <Grid item>
          <FormControl variant="filled">
            <TextField
              variant="filled"
              id="UserId-filter"
              value={userId}
              label="UserId"
              onChange={(e) => setUserId(e.target.value)}
              style={{ width: selectWidth }}
            />
          </FormControl>
        </Grid>

        <Grid item>
          <FormControl variant="filled">
            <InputLabel id="type-filter-label">Type</InputLabel>
            <Select
              labelId="type-filter-label"
              id="type-filter"
              value={type}
              label="Type"
              onChange={(e) => setType(e.target.value)}
              style={{ width: selectWidth }}
            >
              <MenuItem value={'All'}>All</MenuItem>
              <MenuItem value={'buy'}>Buy</MenuItem>
              <MenuItem value={'sell'}>Sell</MenuItem>
              <MenuItem value={'charge'}>Charge</MenuItem>
              <MenuItem value={'payout'}>Payout</MenuItem>
              <MenuItem value={'rent'}>Rent</MenuItem>
              <MenuItem value={'bonus'}>Bonus</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item>
          <FormControl variant="filled">
            <InputLabel id="sort-filter-label">Sort</InputLabel>
            <Select
              labelId="sort-filter-label"
              id="sort-filter"
              value={sort}
              label="Sort"
              onChange={(e) => setSort(e.target.value as 'newest' | 'oldest')}
              style={{ width: selectWidth }}
            >
              <MenuItem value={'newest'}>Newest</MenuItem>
              <MenuItem value={'oldest'}>oldest</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item>
          <FormControl variant="filled">
            <InputLabel id="method-filter-label">Method</InputLabel>
            <Select
              labelId="method-filter-label"
              id="method-filter"
              value={method}
              label="Method"
              variant="filled"
              onChange={(e) => {
                console.log(e);
                setMethod(e.target.value);
              }}
              style={{ width: selectWidth }}
            >
              {[
                { value: 'All', label: 'All' },
                { value: 'transfer', label: 'transfer' },
                { value: 'account', label: 'account' },
                { value: 'creditCard', label: 'creditCard' },
              ].map(({ value, label }) => {
                return (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>

        <Grid item>
          <FormControl variant="filled">
            <InputLabel id="status-filter-label">Status</InputLabel>
            <Select
              labelId="status-filter-label"
              id="status-filter"
              value={status}
              variant="filled"
              label="Status"
              onChange={(e) => {
                console.log(e);
                setStatus(e.target.value);
              }}
              style={{ width: selectWidth }}
            >
              {[
                { value: 'All', label: 'All' },
                { value: 'init', label: 'init' },
                { value: 'pending', label: 'pending' },
                { value: 'charged', label: 'charged' },
                { value: 'reserved', label: 'reserved' },
                { value: 'success', label: 'success' },
                { value: 'error', label: 'error' },
                { value: 'canceled', label: 'canceled' },
              ].map(({ value, label }) => {
                return (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>

        <Grid item>
          <MobileDatePicker
            label="From"
            value={from}
            onChange={setFrom}
            renderInput={(params) => <TextField variant="filled" {...params} />}
          />
        </Grid>

        <Grid item>
          <MobileDatePicker label="To" value={to} onChange={setTo} renderInput={(params) => <TextField variant="filled" {...params} />} />
        </Grid>
      </Grid>
    </>
  );
};
