import { useEffect, useState } from 'react';
import { apiErrorHandling } from 'src/api/apierrorhandling';
import { getAppConfigs } from 'src/api/oApi';
import { IAppConfig } from 'src/models/appconfig';
import { generalStore } from 'src/stores/GeneralStore';

export const useAppConfigs = () => {
  const [appconfigs, setAppConfigs] = useState<IAppConfig[] | null>(null);
  useEffect(() => {
    const load = async () => {
      generalStore.setIsLoading(true);
      try {
        const response = await getAppConfigs();
        setAppConfigs(response.data.data);
      } catch (error: any) {
        apiErrorHandling(error);
      } finally {
        generalStore.setIsLoading(false);
      }
    };
    load();
  }, []);
  return appconfigs;
};
