import { IconButton, InputAdornment, TextField } from '@mui/material';
import { Search } from '@mui/icons-material';
import { FC } from 'react';

export const TextSearchField: FC<{
  field: any;
  setField: any;
  label: string;
  setPage?: any;
  // eslint-disable-next-line @typescript-eslint/ban-types
  setSearchParams?: Function;
  name: string;
  onBlur?: any;
  submit?: () => void;
}> = ({ submit, field, setField, label, setPage, setSearchParams, name, onBlur }) => {
  return (
    <TextField
      onKeyDown={(e) => {
        if (e.keyCode === 13 && submit) {
          setPage(0);
          submit();
        }
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              edge="end"
              onClick={() => {
                if (submit) submit();
              }}
            >
              <Search />
            </IconButton>
          </InputAdornment>
        ),
      }}
      id={'filled-search' + name}
      key={'filled-search' + name}
      label={label}
      style={{ margin: '2px' }}
      type="text"
      variant="filled"
      name={name}
      value={field ? field : ''}
      onBlur={onBlur}
      onChange={(e: any) => {
        setField(e.target.value);
        // When there is a submit probably we do not want
        if (!submit) {
          setPage(0);
        }
        const params = new URLSearchParams(window.location.search);
        params.delete(name);
        params.append(name, e.target.value);
        if (setSearchParams) {
          setSearchParams(params);
        }
      }}
    />
  );
};
