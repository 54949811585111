import { useEffect, useState } from 'react';
import { apiErrorHandling } from 'src/api/apierrorhandling';
import { getUsersWithQuery } from 'src/api/oApi';
import { GetUsersResponse } from 'src/models/users';
import { generalStore } from 'src/stores/GeneralStore';

export const useUsers = (params: {
  email?: string;
  firstName?: string;
  lastName?: string;
  page?: number;
  userId?: string;
  wallet?: string;
  phone?: string;
  referredBy?: string;
  forceUpdate?: boolean;
}) => {
  const [users, setUsers] = useState<GetUsersResponse | null>(null);
  const { email, firstName, lastName, page, userId, wallet, phone, referredBy, forceUpdate } = params;
  useEffect(() => {
    let canceled = false;
    const load = async () => {
      generalStore.setIsLoading(true);
      try {
        if (!canceled) {
          const response = await getUsersWithQuery({
            email,
            firstName,
            lastName,
            page,
            userId,
            wallet,
            phone,
            referredBy,
          });
          const userlist = response.data.data.users.map((user: any) => {
            return {
              ...user,
              id: user._id,
            };
          });
          setUsers({
            count: response.data.data.count,
            users: userlist,
          });
        }
      } catch (error: any) {
        apiErrorHandling(error);
      } finally {
        generalStore.setIsLoading(false);
      }
    };
    load();
    return () => {
      canceled = true;
    };
  }, [email, firstName, lastName, page, userId, wallet, phone, referredBy, forceUpdate]);
  return users;
};

export const fetchUsers = async (
  params: {
    firstName: string | null;
    lastName: string | null;
    email: string | null;
    phone: string | null;
    userId: string | null;
    referrerUserId: string | null;
    affiliatedBy: string | null;
    wallet: string | null;
    page: number | null;
  },
  setUsers: any,
) => {
  try {
    const response = await getUsersWithQuery(params);
    const userlist = response.data.data.users.map((user: any) => {
      return {
        ...user,
        id: user._id,
      };
    });
    setUsers({
      count: response.data.data.count,
      users: userlist,
    });
  } catch (error: any) {
    apiErrorHandling(error);
  } finally {
    generalStore.setIsLoading(false);
  }
};
