import { Chip, TableCell, TableRow } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BrickwiseTable } from 'src/components/mui-overrides/BrickwiseTable';
import { LinkTableCell } from 'src/components/mui-overrides/TableCell';
import { fetchUsers } from 'src/hooks/useUsers';
import { GetUsersResponse, UserItem } from 'src/models/users';
import { UserFilterForm } from 'src/components/dialogs/UserFilter';
import { UserReportsDownloadDialog } from 'src/components/UserReportsDownloadDialog';
import { getCSVReport } from 'src/api/oApi';
import { downloadFile } from 'src/helpers/downloadFile';
import { setStateQueryParameter } from 'src/hooks/useStateQueryParams';
import { CopyToClipboardButton } from 'src/components/ui/CopyToClipboardButton';
import { ActionButton } from 'src/components/Actions';
import { routes } from 'src/router/AppRouter';

export const UserSite: FC = () => {
  const [showCSVDialog, setShowCSVDialog] = useState(false);
  const [page, setPage] = setStateQueryParameter<number>(0, 'page', 'number');
  const [users, setUsers] = useState<GetUsersResponse | null>(null);
  return (
    <>
      <UserFilterForm
        submit={(values) => {
          fetchUsers(
            {
              ...values,
              page,
            },
            setUsers,
          );
        }}
        page={page}
        setPage={setPage}
        enableFilterChips={true}
      />
      <UserReportsDownloadDialog
        open={showCSVDialog}
        onClose={() => setShowCSVDialog(false)}
        onDownload={async (from: Date | null, to: Date | null) => {
          try {
            const response = await getCSVReport({
              registeredFrom: from ? new Date(from).toISOString() : undefined,
              registeredTo: to ? new Date(to).toISOString() : undefined,
            });
            downloadFile(response.data, 'users_' + from?.toLocaleDateString() + '-' + to?.toLocaleDateString());
            setShowCSVDialog(false);
          } catch (err) {
            console.error(err);
            setShowCSVDialog(false);
          }
        }}
      />
      <DownloadReportButton setShowCSVDialog={setShowCSVDialog} />
      <UsersTable page={page} users={users} setPage={setPage} />
    </>
  );
};

const linkUrl = (userId: string): string => {
  return routes.USERDETAIL.replace(':id', userId);
};

const DownloadReportButton = ({ setShowCSVDialog }: { setShowCSVDialog: (a: boolean) => void }) => {
  return (
    <div style={{ paddingBottom: 12, display: 'flex', justifyContent: 'space-between' }}>
      <ActionButton
        onClick={() => {
          setShowCSVDialog(true);
        }}
        text="Download Report"
      />
    </div>
  );
};

const UsersTable = ({ page, setPage, users }: { page: number; setPage: any; users: GetUsersResponse | null }) => {
  const { t } = useTranslation();
  return (
    <BrickwiseTable
      page={page}
      rowsPerPage={10}
      hidePagination={false}
      hideChangePageLengthChange={false}
      length={users?.count ?? 0}
      setPage={setPage}
      renderHeader={() => {
        return (
          <TableRow>
            <TableCell>{t('users.table.id')}</TableCell>
            <TableCell>{t('users.table.name')}</TableCell>
            <TableCell>{t('users.table.email')}</TableCell>
            <TableCell>{t('users.table.phone')}</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>{t('users.table.wallet')}</TableCell>
            <TableCell>{t('users.table.partnerId')}</TableCell>
            <TableCell>{t('users.table.refferredby')}</TableCell>
            <TableCell>{t('users.table.affiliatedby')}</TableCell>
          </TableRow>
        );
      }}
      renderRows={() => {
        return (
          <>
            {users?.users.map((row, index) => {
              return (
                <TableRow
                  key={index}
                  hover
                  style={{ cursor: 'pointer', textDecoration: 'none', backgroundColor: userRowColorPicker(row.status) }}
                >
                  <TableCell>
                    <CopyToClipboardButton text={row.userId} label={row.userId}></CopyToClipboardButton>
                  </TableCell>
                  <LinkTableCell to={linkUrl(row.userId)}>{(row.firstName || '') + ' ' + (row.lastName || '')}</LinkTableCell>
                  <LinkTableCell to={linkUrl(row.userId)}>{row.email}</LinkTableCell>
                  <LinkTableCell to={linkUrl(row.userId)}>{row.phone}</LinkTableCell>
                  <TableCell>{row.status}</TableCell>
                  <LinkTableCell to={linkUrl(row.userId)}>{row.wallet?.address}</LinkTableCell>
                  <LinkTableCell to={linkUrl(row.userId)}>{row.marketingData?.partnerId}</LinkTableCell>
                  <LinkTableCell to={linkUrl(row.referredBy?.userId || row.userId)}>{referralShower(row.referredBy)}</LinkTableCell>
                  <LinkTableCell to={routes.AFFILIATE + `?search=${row.affiliatedBy?.affiliateId}`}>
                    {affiliateShowerButton(row)}
                  </LinkTableCell>
                </TableRow>
              );
            })}
          </>
        );
      }}
    />
  );
};

const userRowColorPicker = (status: string) => {
  switch (status) {
    case 'pending': {
      return 'rgb(247, 251, 218)';
    }
    case 'active': {
      return 'white';
    }
    case 'restoring': {
      return 'rgb(146, 185, 255)';
    }
    default: {
      return 'white';
    }
  }
};

export const referralShower = (
  referredBy:
    | {
        firstName: string;
        lastName: string;
      }
    | undefined,
) => {
  if (referredBy && referredBy.firstName && referredBy.lastName) {
    return (
      <Chip
        sx={{
          '& :hover': {
            color: 'blue',
          },
        }}
        style={{ cursor: 'pointer' }}
        label={referredBy.firstName + ' ' + referredBy.lastName}
        variant="outlined"
      />
    );
  }
  return '';
};

export const affiliateShowerButton = (row: UserItem) => {
  if (row.affiliatedBy) {
    return (
      <Chip
        sx={{
          '& :hover': {
            color: 'blue',
          },
        }}
        style={{ cursor: 'pointer' }}
        label={row.affiliatedBy.name}
        variant="outlined"
      />
    );
  }
  return '';
};
