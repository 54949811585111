import { useEffect, useState } from 'react';
import { apiErrorHandling } from 'src/api/apierrorhandling';
import { getPartners } from 'src/api/oApi';
import { GetPartnersResponse } from 'src/models/partners';
import { generalStore } from 'src/stores/GeneralStore';

export const usePartners = () => {
  const [partners, setPartners] = useState<GetPartnersResponse | null>(null);
  useEffect(() => {
    const load = async () => {
      generalStore.setIsLoading(true);
      try {
        const partners = await getPartners();
        setPartners(partners);
      } catch (error: any) {
        console.log(error);
        apiErrorHandling(error);
      } finally {
        generalStore.setIsLoading(false);
      }
    };
    load();
  }, []);
  return partners;
};
