/* eslint-disable @typescript-eslint/no-empty-function */
import { TableRow, TableCell } from '@mui/material';
import { useFormik } from 'formik';
import { t } from 'i18next';
import { FC, useState } from 'react';
import ReactJson from 'react-json-view';
import { BrickwiseTable } from 'src/components/mui-overrides/BrickwiseTable';
import { useBonusCodes } from 'src/hooks/useBonusCodes';
import { BonusCodeFilterDialog } from 'src/components/dialogs/BonusCodeFilterDialog';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { AddBonusCodeDialog } from 'src/components/dialogs/AddBonusCodeDialog';
import { format } from 'date-fns';
export const BonusCodeSite: FC = () => {
  const [page, setPage] = useState(0);
  const [reload, setReload] = useState(false);
  const formik = useFormik({
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      code: '',
      onlyValid: true,
    },
    onSubmit: async () => {},
  });
  const [showAddBonusCodeDialog, setShowAddBonusCodeDialog] = useState(false);

  const bonusCodes = useBonusCodes(reload, { page, code: formik.values.code, onlyValid: formik.values.onlyValid });
  const [jsonDetail, setJSONDetail] = useState<object | null>(null);

  return (
    <>
      <BonusCodeFilterDialog formik={formik} setPage={setPage} />
      <AddBonusCodeDialog
        open={showAddBonusCodeDialog}
        onClose={() => setShowAddBonusCodeDialog(false)}
        onCloseAndReload={() => {
          setShowAddBonusCodeDialog(false);
          setReload(!reload);
        }}
      />
      <div
        style={{
          position: 'fixed',
          bottom: 24,
          right: 24,
        }}
      >
        <Fab color="secondary" onClick={() => setShowAddBonusCodeDialog(true)}>
          <AddIcon />
        </Fab>
      </div>

      <BrickwiseTable
        page={page}
        rowsPerPage={10}
        hidePagination={false}
        hideChangePageLengthChange={false}
        length={bonusCodes?.count ?? 0}
        setPage={setPage}
        renderHeader={() => {
          return (
            <TableRow>
              {/* <TableCell>{t('users.table.name')}</TableCell> */}
              <TableCell style={{ textAlign: 'center' }}>{t('bonuscodes.table.code')}</TableCell>
              <TableCell style={{ textAlign: 'center' }}>{t('bonuscodes.table.partnerId')}</TableCell>
              <TableCell style={{ textAlign: 'center' }}>{t('bonuscodes.table.totalbonuses')}</TableCell>
              <TableCell style={{ textAlign: 'center' }}>{t('bonuscodes.table.remainedbonuses')}</TableCell>
              <TableCell style={{ textAlign: 'center' }}>{t('bonuscodes.table.bonusowner')}</TableCell>
              <TableCell style={{ textAlign: 'center' }}>{t('bonuscodes.table.assetid')}</TableCell>
              <TableCell style={{ textAlign: 'center' }}>{t('bonuscodes.table.from')}</TableCell>
              <TableCell style={{ textAlign: 'center' }}>{t('bonuscodes.table.to')}</TableCell>
              <TableCell style={{ textAlign: 'center' }}>{t('bonuscodes.table.type')}</TableCell>
            </TableRow>
          );
        }}
        renderRows={() => {
          return (
            <>
              {bonusCodes?.bonusCodes.map((row, index) => {
                return (
                  <TableRow
                    onClick={() => {
                      if (row === jsonDetail) {
                        setJSONDetail(null);
                        return;
                      } else {
                        setJSONDetail(row);
                      }
                    }}
                    key={index}
                    hover
                    style={{ cursor: 'pointer', textDecoration: 'none' }}
                  >
                    <TableCell>{row.code}</TableCell>
                    <TableCell>{row.partnerId}</TableCell>
                    <TableCell>{row.initialCount}</TableCell>
                    <TableCell>{row.remainedCount}</TableCell>
                    <TableCell>{row.ownerUserId}</TableCell>
                    <TableCell>{row.assetId}</TableCell>
                    <TableCell>{row.availableFrom ? format(new Date(row.availableFrom), 'dd.MM.yyyy') : '-'}</TableCell>
                    <TableCell>{row.expiresAt ? format(new Date(row.expiresAt), 'dd.MM.yyyy') : '-'}</TableCell>
                    <TableCell>{row.bonusType}</TableCell>
                    <TableCell>{row.bonusAmount}</TableCell>
                    <TableCell>{row.minimumNumberOfShares}</TableCell>
                    <TableCell>{row.minVolume}</TableCell>
                  </TableRow>
                );
              })}
            </>
          );
        }}
      />
      {jsonDetail && <ReactJson src={jsonDetail} />}
    </>
  );
};
