/* eslint-disable @typescript-eslint/naming-convention */
import { Autocomplete, Box, FilledInputProps, MenuItem, TextField } from '@mui/material';
import { CSSProperties, FC } from 'react';

interface TextFieldProps {
  label: string;
  field: string;
  fieldList?: string[];
  formik: any;
  placeholder?: string;
  inputProps?: Partial<FilledInputProps>;
  muiInputProps?: Partial<FilledInputProps>;
  disabled?: boolean;
  size?: 'small' | 'medium';
  style?: CSSProperties;
  variant?: 'standard' | 'outlined' | 'filled';
  hideHelperText?: boolean;
  type?: 'text' | 'number' | 'email' | 'password';
}

export const DefaultTextField: FC<TextFieldProps> = ({
  label,
  field,
  formik,
  placeholder,
  inputProps,
  muiInputProps,
  disabled,
  size,
  style,
  variant,
  type,
}) => {
  return (
    <TextField
      style={style}
      size={size}
      margin="dense"
      label={label}
      {...formik.getFieldProps(field)}
      type={type}
      variant={variant ?? 'outlined'}
      fullWidth
      disabled={disabled}
      placeholder={placeholder}
      value={formik.values[field]}
      onChange={formik.handleChange}
      error={formik.touched[field] && Boolean(formik.errors[field])}
      helperText={(formik.touched[field] && formik.errors[field]) ?? ' '}
      inputProps={inputProps}
      InputProps={muiInputProps}
    />
  );
};

interface OptionItem {
  value: string;
  label: string;
}

interface SelectTextFieldProps {
  label: string;
  field: string;
  formik: any;
  options: OptionItem[];
  disabled?: boolean;
}

export const DefaultSelectField: FC<SelectTextFieldProps> = ({ label, field, formik, options, disabled }) => {
  return (
    <TextField
      label={label}
      {...formik.getFieldProps(field)}
      type="text"
      margin="normal"
      variant="outlined"
      fullWidth
      disabled={disabled}
      value={formik.values[field]}
      onChange={formik.handleChange}
      error={formik.touched[field] && Boolean(formik.errors[field])}
      helperText={(formik.touched[field] && formik.errors[field]) ?? ' '}
      select
      style={{ textAlign: 'left' }}
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

export const DefaultMultiSelectField: FC<SelectTextFieldProps> = ({ label, field, formik, options }) => {
  return (
    <TextField
      margin="normal"
      label={label}
      {...formik.getFieldProps(field)}
      type="text"
      variant="outlined"
      fullWidth
      select
      SelectProps={{
        multiple: true,
      }}
      onChange={formik.handleChange}
      value={formik.values[field]}
      error={formik.touched[field] && Boolean(formik.errors[field])}
      helperText={(formik.touched[field] && formik.errors[field]) ?? ' '}
      style={{ textAlign: 'left' }}
    >
      {options.map((option, index) => (
        <MenuItem key={index} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

interface AutoCompleteTextFieldProps {
  label: string;
  field: string;
  formik: any;
  options: string[];
  disabled?: boolean;
}

export const DefaultAutoCompleteField: FC<AutoCompleteTextFieldProps> = ({ label, field, formik, options, disabled }) => {
  return (
    <>
      <Autocomplete
        fullWidth
        options={options}
        renderOption={(props, option: string) => (
          <Box component="li" {...props} key={option}>
            {option}
          </Box>
        )}
        freeSolo
        {...formik.getFieldProps(field)}
        onChange={(_, value) => {
          if (value) {
            formik.setFieldValue(field, value);
          } else {
            formik.setFieldValue(field, '');
          }
        }}
        clearIcon={null}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            margin="normal"
            label={label}
            error={formik.touched[field] && Boolean(formik.errors[field])}
            helperText={(formik.touched[field] && formik.errors[field]) ?? ' '}
            onChange={(_: any, subvalue: any) => {
              if (subvalue) {
                formik.setFieldValue(field, subvalue);
              } else {
                formik.setFieldValue(field, '');
              }
            }}
            {...formik.getFieldProps(field)}
          />
        )}
      />
    </>
  );
};
