import { createTheme } from '@mui/material';

export const Colors = {
  PRIMARY_COLOR: '#B3CCCA',
  PRIMARY_COLOR_LIGHT: '#D1E0DF',
  PRIMARY_COLOR_DARK: '#1F2E2D',
  SECONDARY_COLOR: '#E85E4A',
  SECONARY_COLOR_LIGHT: '#F9D7D2',
  SECONARY_COLOR_DARK: '#B52C17',
  MINT25: '#334D4B',
  BLACK_BLUE: '#26313B',
  GREY: '#A8ACB0',
  GREY50: 'rgb(249 250 251)',
  E5GREY: '#E5E5E5',
  F2GREY: '#F2F2F2',
  WHITE: '#FFFFFF',
  ERROR: '#d32f2f',
  SUCCESS: '#4caf50',
};

export const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: Colors.PRIMARY_COLOR,
    },
    secondary: {
      main: Colors.SECONDARY_COLOR,
    },
  },
});
