import { Table, TableRow, TableCell, TableHead, TableBody } from '@mui/material';
import { SupportCaseByIdResponse } from 'src/models/supportcaseById';
import { CopyToClipboardButton } from './ui/CopyToClipboardButton';
import { cellStyle } from 'src/sites/UserDetailSite';
import { SectionTitle } from 'src/components/SectionTitle';

const columnTitleStyle = { fontFamily: 'aestetico-semibold' };
export const SupportDetail = ({ supportCase }: { supportCase: SupportCaseByIdResponse | null }) => {
  if (!supportCase) return null;
  return (
    <>
      <SectionTitle>Support Case</SectionTitle>
      <Table>
        <TableBody>
          <TableRow hover={true}>
            <TableCell style={columnTitleStyle}>ID</TableCell>
            <TableCell>{supportCase._id}</TableCell>
          </TableRow>

          <TableRow hover={true}>
            <TableCell style={columnTitleStyle}>UserID</TableCell>
            <TableCell>
              <CopyToClipboardButton text={supportCase.userId} label={supportCase.userId} />
            </TableCell>
          </TableRow>
          <TableRow hover={true}>
            <TableCell style={columnTitleStyle}>Type</TableCell>
            <TableCell>{supportCase.supportCaseType}</TableCell>
          </TableRow>
          <TableRow hover={true}>
            <TableCell style={columnTitleStyle}>Urgency</TableCell>
            <TableCell>{supportCase.supportCaseUrgency}</TableCell>
          </TableRow>
          <TableRow hover={true}>
            <TableCell style={columnTitleStyle}>Status</TableCell>
            <TableCell>{supportCase.status}</TableCell>
          </TableRow>
          <TableRow hover={true}>
            <TableCell style={columnTitleStyle}>GeneratedBy</TableCell>
            <TableCell>{supportCase.generatedBy}</TableCell>
          </TableRow>
          <TableRow hover={true}>
            <TableCell style={columnTitleStyle}>CreatedAt</TableCell>
            <TableCell>{supportCase.createdAt}</TableCell>
          </TableRow>
          <TableRow hover={true}>
            <TableCell style={columnTitleStyle}>UpdatedAt</TableCell>
            <TableCell>{supportCase.updatedAt}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};

export const SupportMessages = ({ supportCase }: { supportCase: SupportCaseByIdResponse | null }) => {
  if (!supportCase) return null;
  return (
    <>
      <SectionTitle>Messages</SectionTitle>
      <Table>
        <TableHead>
          <TableRow hover={true}>
            <TableCell style={columnTitleStyle} key={'header-_id'}>
              id
            </TableCell>
            <TableCell style={columnTitleStyle} id={'header-message'} key={'header-message'}>
              message
            </TableCell>
            <TableCell style={columnTitleStyle} id={'header-name'} key={'header-name'}>
              name
            </TableCell>
          </TableRow>
        </TableHead>

        {supportCase.information
          ? // eslint-disable-next-line @typescript-eslint/naming-convention
            supportCase.information.map(({ _id, message, name }, index) => {
              return (
                <TableRow hover={true} key={name}>
                  <TableCell style={cellStyle} id={`_id-${index}-${name}`} key={`_id-${index}-${name}`}>
                    <CopyToClipboardButton text={_id}></CopyToClipboardButton>
                  </TableCell>
                  <TableCell id={`message-${index}-${name}`} key={`message-${index}-${name}`}>
                    {message}
                  </TableCell>
                  <TableCell id={`name-${index}-${name}`} key={`name-${index}-${name}`}>
                    {name}
                  </TableCell>
                </TableRow>
              );
            })
          : null}
      </Table>
    </>
  );
};
