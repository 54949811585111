import { TableCell, TableRow } from '@mui/material';
import { FC, useState } from 'react';
import { TextSearchField } from 'src/components/TextSearchField';
import { BrickwiseTable } from 'src/components/mui-overrides/BrickwiseTable';
import { usePotentialUsers } from 'src/hooks/usePotentialUsers';

export const PotentialUsersSite = () => {
  const [page, setPage] = useState(0);
  const [email, setEmail] = useState<undefined | string>(undefined);
  const potentialUsers = usePotentialUsers({ page, email });
  console.log(email);
  if (!potentialUsers) {
    return null;
  }
  return (
    <>
      <TextSearchField field={email} setField={setEmail} label={'Email'} setPage={setPage} name="email" />
      <PotentialUserList users={potentialUsers.data} count={potentialUsers.count} page={page} setPage={setPage} />
    </>
  );
};
export const PotentialUserList: FC<{ users: any; page: number; setPage: any; count: number }> = ({ users, page, setPage, count }) => {
  return (
    <BrickwiseTable
      hidePagination={false}
      page={page}
      setPage={setPage}
      rowsPerPage={20}
      length={count}
      hideChangePageLengthChange={true}
      renderHeader={() => {
        return (
          <TableRow>
            <TableCell>Email</TableCell>
            <TableCell>Id</TableCell>
          </TableRow>
        );
      }}
      renderRows={() => {
        return (
          <>
            {users?.map((row: any, index: number) => {
              return (
                <TableRow
                  key={'users-' + index}
                  hover
                  style={{
                    cursor: 'pointer',
                    textDecoration: 'none',
                  }}
                >
                  <TableCell>{row.email}</TableCell>
                  <TableCell>{row.id}</TableCell>
                </TableRow>
              );
            })}
          </>
        );
      }}
    />
  );
};
