import { Chip, TableCell, TableRow } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BrickwiseTable } from 'src/components/mui-overrides/BrickwiseTable';
import { IdentificationsReport, useIdentifications } from 'src/hooks/useIdentifications';
import { SwitchIdentificationButton, SwitchIdentificationFourthlineStatusButton } from 'src/components/SwitchIdentificationButton';
import ReactJson from 'react-json-view';
import { UserFieldsFilter, UserFilter } from 'src/components/dialogs/UserFilter';
import { Formik, FormikProps } from 'formik';

export const IdentificationSite: FC = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [update, setUpdate] = useState(false);
  const [currentRow, setCurrentRow] = useState<null | IdentificationsReport[0]>(null);
  return (
    <>
      <Formik
        initialValues={{ firstName: '', lastName: '', email: '', phone: '', userId: '', referrerUserId: '', wallet: '', affiliatedBy: '' }}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onSubmit={() => {
          setUpdate(!update);
        }}
      >
        {(formikObject: FormikProps<UserFieldsFilter>) => {
          const identifications = useIdentifications({
            page,
            forceUpdate: update,
            userInfo: {
              firstName: formikObject.values.firstName,
              lastName: formikObject.values.lastName,
              email: formikObject.values.email,
              phone: formikObject.values.phone,
              userId: formikObject.values.userId,
              referrerUserId: formikObject.values.referrerUserId,
              affiliatedBy: formikObject.values.affiliatedBy,
              wallet: formikObject.values.wallet,
            },
          });
          return (
            <>
              <UserFilter setPage={setPage} formikObject={formikObject} />
              <BrickwiseTable
                hidePagination={false}
                hideChangePageLengthChange={false}
                setPage={setPage}
                page={page}
                length={40 + page * 20}
                rowsPerPage={20}
                renderHeader={() => {
                  return (
                    <TableRow>
                      <TableCell>{t('identifications.table.date')}</TableCell>
                      <TableCell>{t('identifications.table.time')}</TableCell>
                      <TableCell>{t('identifications.table.name')}</TableCell>
                      <TableCell style={{ textAlign: 'center' }}>{t('identifications.table.userStatus')}</TableCell>
                      <TableCell>{t('identifications.table.email')}</TableCell>
                      <TableCell style={{ textAlign: 'center' }}>{t('identifications.table.idnowStatus')}</TableCell>
                      <TableCell style={{ textAlign: 'center' }}>{t('identifications.table.fourthlineStatus')}</TableCell>
                      <TableCell style={{ textAlign: 'center' }}>Actions</TableCell>
                    </TableRow>
                  );
                }}
                renderRows={() => {
                  return (
                    <>
                      {identifications?.map((row, index) => {
                        return (
                          <TableRow
                            onClick={() => setCurrentRow(row)}
                            key={index}
                            style={{ cursor: 'pointer', backgroundColor: '4D56EB', textDecoration: 'none' }}
                          >
                            <TableCell>{new Date(row.identificationCreatedAt).toLocaleDateString() as unknown as string}</TableCell>
                            <TableCell>{new Date(row.identificationCreatedAt).toLocaleTimeString() as unknown as string}</TableCell>
                            <TableCell>{row.firstName + ' ' + row.lastName}</TableCell>
                            <TableCell
                              style={{ textAlign: 'center', textTransform: 'uppercase', color: userStatusColorPicker(row.status) }}
                            >
                              {row.status}
                            </TableCell>
                            <TableCell>{row.email}</TableCell>
                            <TableCell style={{ textAlign: 'center' }}>
                              {row.idnow?.length ? (
                                <Chip
                                  style={{ fontSize: '110%' }}
                                  color={statusColorPicker(row.idnow[0].status) as any}
                                  label={row.idnow[0].status}
                                />
                              ) : (
                                ''
                              )}
                            </TableCell>
                            <TableCell style={{ textAlign: 'center' }}>
                              {row.fourthline?.length ? (
                                <Chip
                                  style={{ fontSize: '110%' }}
                                  color={statusColorPicker(row.fourthline[0].status) as any}
                                  label={row.fourthline[0].status}
                                />
                              ) : (
                                ''
                              )}
                            </TableCell>
                            <TableCell style={{ textAlign: 'center' }}>
                              {shouldShowSwitchButton(row) ? (
                                <SwitchIdentificationButton
                                  forceUpdateFunction={() => {
                                    setUpdate(!update);
                                  }}
                                  userId={row.userId}
                                  destinationType={row.idnow.length ? 'Fourthline' : 'IDNow'}
                                />
                              ) : null}
                              {'   '}
                              {row.fourthline && row.fourthline[0]?.status === FourthLineIdentificationStatus.manualCheck ? (
                                <SwitchIdentificationFourthlineStatusButton
                                  userId={row.userId}
                                  forceUpdateFunction={() => {
                                    setUpdate(!update);
                                  }}
                                />
                              ) : null}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </>
                  );
                }}
              />
            </>
          );
        }}
      </Formik>
      <ReactJson src={currentRow || {}} />
    </>
  );
};

export const enum FourthLineIdentificationStatus {
  new = 'new',
  pending = 'pending',
  manualCheck = 'manualCheck',
  kycRequired = 'kycRequired',
  qesRequired = 'qesRequired',
  success = 'success',
  rejected = 'rejected', // because of fraud or risk reasons
}

const colorMap = {
  new: 'primary',
  init: 'primary',
  success: 'success',
  error: 'error',
  rejected: 'error',
  kycRequired: 'info',
  qesRequired: 'info',
  pending: 'info',
};

type ColorMapKeys = keyof typeof colorMap;

function statusColorPicker(status: string) {
  return colorMap[status as ColorMapKeys] ? colorMap[status as ColorMapKeys] : 'secondary';
}
function userStatusColorPicker(status: string) {
  return userStatusColorMap[status as unknown as UserStatusColorMaps];
}
type UserStatusColorMaps = keyof typeof userStatusColorMap;
const userStatusColorMap = {
  new: 'black',
  verified: 'green',
  pending: 'brown',
  active: 'green',
  locked: 'grey',
  restoring: 'yellow',
  deleted: 'red',
};

const shouldShowSwitchButton = (row: IdentificationsReport[0]) => {
  if (row.fourthline && row.fourthline[0]) {
    return row.fourthline[0].status !== 'success';
  } else if (row.idnow && row.idnow[0]) {
    return row.idnow[0].status !== 'success';
  } else {
    return false;
  }
};
