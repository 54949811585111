import { TransactionResponse } from '../../models/transaction';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { postRefund } from '../../api/oApi';
import { generalStore } from '../../stores/GeneralStore';
import { LoadingButton } from '@mui/lab';

interface Props {
  open: boolean;
  transaction: TransactionResponse['transaction'];
  handleClose: () => void;
  triggerRefresh: () => void;
}

export const RefundDialog: FC<Props> = ({ open, transaction, handleClose, triggerRefresh }) => {
  const [t] = useTranslation();
  const [isLoading, setLoading] = useState(false);

  const postRequestApproval = async () => {
    setLoading(true);
    try {
      await postRefund(transaction.paymentProviderInformation.transactionId);
      generalStore.setMessage(t('transactions.refundDialog.successMsg'));
      triggerRefresh();
      handleClose();
    } catch (error: any) {
      generalStore.setError(error?.response?.data?.error?.message || error?.response?.data?.error || error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <>
        <DialogTitle>{t('transactions.refundDialog.title')}</DialogTitle>
        <DialogContent>
          <Typography>{t('transactions.refundDialog.content')}</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            {t('transactions.refundDialog.cancel')}
          </Button>
          <LoadingButton loading={isLoading} variant="contained" disableElevation color="primary" onClick={postRequestApproval}>
            {t('transactions.refundDialog.submit')}
          </LoadingButton>
        </DialogActions>
      </>
    </Dialog>
  );
};
