// breakpoints
export const breakpointMobile = 480;
export const breakpointTabletSmall = 768;
export const breakpointTabletMedium = 1000;
export const breakpointTablet = 1160;
export const breakpointSmallDesktop = 1550;

// querys
export const isDesktopQuery = `(min-width:${breakpointSmallDesktop}px)`;
export const isSmallDesktopQuery = `(max-width:${breakpointSmallDesktop}px) and (min-width:${breakpointTablet}px)`;
export const isTabletQuery = `(max-width:${breakpointTablet}px) and (min-width:${breakpointTabletMedium}px)`;
export const isTabletMediumQuery = `(max-width:${breakpointTabletMedium}px) and (min-width:${breakpointTabletSmall}px)`;
export const isTabletSmallQuery = `(max-width:${breakpointTabletSmall}px) and (min-width:${breakpointMobile}px)`;
export const isMobileQuery = `(max-width:${breakpointMobile}px)`;

// mobile, tablet, tablet-medium, tablet-small, tablet
export const isMobileDeviceQuery = `(max-width:${breakpointTablet}px)`;

// mobile, tablet-medium, tablet-small
export const isSmallMobileDeviceQuery = `(max-width:${breakpointTabletMedium}px)`;

// tablet, tablet-medium, tablet-small
export const isTabletDeviceQuery = `(min-width:${breakpointMobile}px and max-width:${breakpointTablet}px)`;

// desktop, desktop-small
export const isDesktopDeviceQuery = `(min-width:${breakpointSmallDesktop}px)`;
