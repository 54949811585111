import { useEffect, useState } from 'react';
import { apiErrorHandling } from 'src/api/apierrorhandling';
import { getQuestions } from 'src/api/csApi';
import { GetQuestionsResponse } from 'src/models/questions';
import { generalStore } from 'src/stores/GeneralStore';

export const useQuestions = (reload: boolean) => {
  const [questions, setQuestions] = useState<GetQuestionsResponse[] | null>(null);
  const [sections, setSections] = useState<string[]>([]);
  useEffect(() => {
    const load = async () => {
      generalStore.setIsLoading(true);
      try {
        const response = await getQuestions();
        // sort by section and order
        const sorted = response?.data?.sort((a, b) => {
          if (a.zendeskSection === b.zendeskSection) {
            return (a?.onPageFAQSectionOrder || 0) - (b?.onPageFAQSectionOrder || 0);
          }
          return a.zendeskSection > b.zendeskSection ? 1 : -1;
        });
        setQuestions(sorted || []);
        // get sections distinct
        const sections = response?.data?.map((item) => item.zendeskSection).filter((value, index, self) => self.indexOf(value) === index);
        setSections(sections || []);
      } catch (error: any) {
        apiErrorHandling(error);
      } finally {
        generalStore.setIsLoading(false);
      }
    };
    load();
  }, [reload]);
  return { questions, sections };
};
