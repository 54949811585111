import { useEffect, useState } from 'react';
import { apiErrorHandling } from 'src/api/apierrorhandling';
import { getUserWithQuery } from 'src/api/oApi';
import { GetUserResponse } from 'src/models/users';
import { generalStore } from 'src/stores/GeneralStore';

export const useUser = (userId: string, forceReload = false) => {
  const [user, setUser] = useState<GetUserResponse | null>(null);
  useEffect(() => {
    let canceled = false;
    const load = async () => {
      generalStore.setIsLoading(true);
      try {
        if (!canceled) {
          /* `const response = await getUserWithQuery(userId);` is making an API call to retrieve user
          data based on the `userId` parameter passed to the function. The response is then used to
          update the state of the `users` variable. */
          const response = await getUserWithQuery(userId);
          setUser({
            user: response.data.data.user,
          });
        }
      } catch (error: any) {
        apiErrorHandling(error);
      } finally {
        generalStore.setIsLoading(false);
      }
    };
    load();
    return () => {
      canceled = true;
    };
  }, [userId, forceReload]);
  return user;
};

// export const fetchUsers = async (
//   params: {
//     firstName: string | null;
//     lastName: string | null;
//     email: string | null;
//     phone: string | null;
//     userId: string | null;
//     referrerUserId: string | null;
//     wallet: string | null;
//     page: number | null;
//   },
//   setUsers: any,
// ) => {
//   try {
//     const response = await getUsersWithQuery(params);
//     const userlist = response.data.data.users.map((user: any) => {
//       return {
//         ...user,
//         id: user._id,
//       };
//     });
//     setUsers({
//       count: response.data.data.count,
//       users: userlist,
//     });
//   } catch (error: any) {
//     apiErrorHandling(error);
//   } finally {
//     generalStore.setIsLoading(false);
//   }
// };
