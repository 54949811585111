import { FC, useState } from 'react';
import { TableCell, TableRow } from '@mui/material';
import { useEmails } from 'src/hooks/useEmails';
import { BrickwiseTable } from '../mui-overrides/BrickwiseTable';

export const UserEmailComponent: FC<{
  userId: string;
}> = ({ userId }) => {
  if (!userId) {
    return null;
  }
  const [page, setPage] = useState(0);
  const emails = useEmails({ userId, page }) || [];
  return (
    <BrickwiseTable
      page={page}
      setPage={setPage}
      renderHeader={() => (
        <TableRow>
          <TableCell>subject</TableCell>
          <TableCell>ArrivedAt</TableCell>
          <TableCell>Status</TableCell>
          <TableCell>AttachmentCount</TableCell>
          <TableCell>CampaignID</TableCell>
        </TableRow>
      )}
      length={100}
      rowsPerPage={20}
      hidePagination={false}
      hideChangePageLengthChange={false}
      renderRows={() => {
        return emails.map((email, index) => {
          return (
            <TableRow key={index}>
              <TableCell>{email.subject}</TableCell>
              <TableCell>{email.ArrivedAt}</TableCell>
              <TableCell>{email.Status}</TableCell>
              <TableCell>{email.AttachmentCount}</TableCell>
              <TableCell>{email.CampaignID}</TableCell>
            </TableRow>
          );
        });
      }}
    />
  );
};
