import { apiErrorHandling } from 'src/api/apierrorhandling';

export const getHumanReadableSize = (size: number, decimals = 2): string => {
  if (size === 0) {
    return '';
  }
  const k = 1024;
  const sizeFormats = ['Bytes', 'KB', 'MB', 'GB'];
  let i = Math.floor(Math.log(size) / Math.log(k));
  i = i < sizeFormats.length ? i : sizeFormats.length - 1;
  return `${parseFloat((size / Math.pow(k, i)).toFixed(decimals))} ${sizeFormats[i]}`;
};

export const substractOneDateFromAnOther = (date1: Date, date2: Date) => {
  if (date1 && date2) {
    const toDate2 = new Date(date2);
    const toDate1 = new Date(date1);
    const diffTime = Math.abs(toDate2.getTime() - toDate1.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  }
  return false;
};

export const stringLocale = 'en-US';

export const formatNumber = (input: number | string) => {
  if (typeof input === 'string') {
    return parseFloat(input).toLocaleString(stringLocale, {
      minimumFractionDigits: 2,
    });
  }
  if (typeof input === 'number') {
    return input.toLocaleString(stringLocale, { minimumFractionDigits: 2 });
  }
  return '';
};

export const awaitUtil = async (asyncFunction: any) => {
  try {
    const data = await asyncFunction;
    return [data, null];
  } catch (error: any) {
    apiErrorHandling(error);
    return [null, error];
  }
};

export const isNullUndefinedOrEmpty = (value: any) => {
  return value === null || value === undefined || value === '';
};

export const asyncForEach = async (array: any, callback: any) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
};

export const convertToInt = (value: string) => {
  const number = parseInt(value);
  if (isNaN(number)) {
    return 0;
  } else {
    return number;
  }
};

export const convertToFloat = (value: string) => {
  const number = parseFloat(value);
  if (isNaN(number)) {
    return 0;
  } else {
    return number;
  }
};
