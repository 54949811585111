import { CloseOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { IconButton, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { t } from 'i18next';
import { FC, useState } from 'react';
import { postQuestion, putQuestion } from 'src/api/csApi';
import { useAppConfigs } from 'src/hooks/useAppconfigs';
import { GetQuestionsResponse, PostQuestionPayload } from 'src/models/questions';
import { number, object, string } from 'yup';
import { HTMLEditor } from '../HTMLEditor/HtmlEditor';
import { CustomDialog } from '../mui-overrides/CustomDialog';
import { DefaultAutoCompleteField, DefaultMultiSelectField, DefaultSelectField, DefaultTextField } from '../mui-overrides/Textfield';

interface Props {
  open: boolean;
  usedSections: string[];
  oldQuestion: GetQuestionsResponse | null;
  onClose: () => void;
  onCloseAndReload: () => void;
}

export const AddEditQuestionDialog: FC<Props> = ({ open, usedSections, oldQuestion, onClose, onCloseAndReload }) => {
  const [isLoading, setIsLoading] = useState(false);
  const appconfigs = useAppConfigs();
  const formik = useFormik({
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      zendeskSection: oldQuestion?.zendeskSection ?? '',
      onPageFAQSectionOrder: oldQuestion?.onPageFAQSectionOrder?.toString() ?? '',
      locales: oldQuestion?.locales ?? 'de',
      title: oldQuestion?.title ?? '',
      html: oldQuestion?.body ?? '',
      apps: oldQuestion?.apps ?? [],
    },
    validationSchema: object({
      zendeskSection: string().required('Section is required'),
      order: number(),
      locales: string().required('Locales is required'),
      title: string().required('Title is required'),
    }),
    onSubmit: async (values: {
      zendeskSection: string;
      onPageFAQSectionOrder: string;
      locales: string;
      title: string;
      html: string;
      apps: string[];
    }) => {
      try {
        setIsLoading(true);

        // get html from editorState
        const body: PostQuestionPayload = {
          zendeskSection: values.zendeskSection,
          onPageFAQSectionOrder: parseInt(values.onPageFAQSectionOrder),
          locales: values.locales,
          title: values.title,
          body: values.html,
          apps: values.apps,
        };
        if (oldQuestion) {
          // put
          await putQuestion(oldQuestion.id, body);
          formik.resetForm();
        } else {
          // post
          await postQuestion(body);
          formik.resetForm();
        }
        onCloseAndReload();
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    },
  });

  return (
    <CustomDialog open={open} onClose={onClose}>
      <div
        style={{
          position: 'relative',
          textAlign: 'center',
          maxWidth: '1000px',
        }}
      >
        <IconButton
          style={{
            position: 'absolute',
            right: -30,
            top: -30,
          }}
          onClick={onClose}
        >
          <CloseOutlined />
        </IconButton>

        <div>
          <Typography textAlign="left" component="h1" variant="h5" paddingBottom="20px">
            {t('addEditQuestionDialog.headline.add')}
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <DefaultTextField field={'title'} formik={formik} label={t('addEditQuestionDialog.form.title')} variant="outlined" />
            <DefaultSelectField
              field={'locales'}
              formik={formik}
              label={t('addEditQuestionDialog.form.locales')}
              options={[
                {
                  value: 'de',
                  label: 'de',
                },
              ]}
            />
            <DefaultMultiSelectField
              field={'apps'}
              formik={formik}
              label={t('addEditQuestionDialog.form.apps')}
              options={
                appconfigs?.map((app) => ({
                  value: app.partnerId,
                  label: app.partnerLegalName,
                })) ?? []
              }
            />

            <DefaultTextField
              type="number"
              field={'onPageFAQSectionOrder'}
              formik={formik}
              label={t('addEditQuestionDialog.form.order')}
              variant="outlined"
            />
            <DefaultAutoCompleteField
              field={'zendeskSection'}
              formik={formik}
              label={t('addEditQuestionDialog.form.section')}
              options={usedSections}
            />

            <HTMLEditor setFieldValue={(val) => formik.setFieldValue('html', val)} value={formik.values.html} />
            <LoadingButton
              color="secondary"
              loading={isLoading}
              type="submit"
              size="large"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {t('addEditQuestionDialog.form.submit')}
            </LoadingButton>
          </form>
        </div>
      </div>
    </CustomDialog>
  );
};
