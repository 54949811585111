import { Button, styled, TextField } from '@mui/material';
import { Colors } from 'src/components/utils/Theme';

export const LoginInput = styled(TextField)({
  '.MuiFilledInput-root': {
    backgroundColor: '#fff',
    borderRadius: '4px',
    '& .MuiFilledInput-input': {
      border: '2px solid white',
      paddingTop: '20px',
      '&:focus': {
        backgroundColor: '#fff',
        border: '2px solid #26313B',
      },
    },
    '&:before': {
      borderBottom: 'unset !important',
    },
    '&:after': {
      borderBottom: 'unset !important',
    },
    '&:hover': {
      border: 'none',
      backgroundColor: '#fff',
    },
  },
  '.MuiInputLabel-root': {
    '&.Mui-focused': {
      color: '#26313B',
    },
    '&.Mui-error': {
      color: Colors.ERROR,
    },
  },
  '.Mui-error': {
    '& .MuiFilledInput-input': {
      border: '2px solid red !important',
    },
  },
});

export const LoginButton = styled(Button)({
  height: '56px',
  fontWeight: 'bold',
});
