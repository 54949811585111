/**
 * API for the blockchain.
 */

import axios, { AxiosError } from 'axios';
import { REACT_APP_API_DAPP_ADDRESS, REACT_APP_API_NODE_API_URL, REACT_APP_API_ORACLE_ADDRESS } from '../util/config';
import { UserItem } from '../models/users';
import { BlockchainAsset, BlockchainOffer } from '../models/blockchain';
import { Offer } from '../models/offers';

/** Response for a user balances request on the blockchain. */
interface BlockchainBalancesResponse {
  /** Wallet address */
  address: string;
  balances: BlockchainAsset[];
}

/** Response of unsigned offers on the blockchain oracle. */
export interface OracleOfferResponse {
  /** IDs of unsigned secondary offers. */
  offers: string[];
  /** IDs of unsigned delete offers. */
  deleteOffers: string[];
}

const nodeAPI = axios.create({
  baseURL: REACT_APP_API_NODE_API_URL,
  timeout: 100000,
  withCredentials: false,
});

/**
 * Gets all blockchain assets for a given wallet.
 * @param wallet User wallet.
 */
export const getWalletAssets = async (wallet: UserItem['wallet']['address']) => {
  const data = await nodeAPI.get<BlockchainBalancesResponse>('/assets/balance/' + wallet);
  if (data.data.balances?.length) {
    return data.data.balances.map((balance) => {
      return {
        assetId: balance.issueTransaction.name,
        tokenId: balance.assetId,
        balance: balance.balance,
        description: balance.issueTransaction.description,
      };
    });
  } else {
    return [];
  }
};

/**
 * Gets data from the blockchain.
 * @param address Address on the blockchain.
 * @param key Key to be queried.
 * @see https://node1.brickprotocol.com/api-docs/index.html#/
 * @throws Error if the request failed, or the format is incorrect.
 */
const getDataWithKey = async <T extends string = string>(address: string, key: string): Promise<T> => {
  const res = await nodeAPI.get(`/addresses/data/${address}/${key}`);
  return res.data.value;
};

/**
 * Gets all offers from the oracle.
 * @description A 404 status code occurs if the user has no offers on oracle, thus it is not an error.
 * @param walletAddress Wallet address of the user.
 * @throws Error if the request failed or the format is not correct.
 */
export const getOracleOffers = async (walletAddress: string): Promise<OracleOfferResponse> => {
  try {
    const res = await getDataWithKey(REACT_APP_API_ORACLE_ADDRESS, walletAddress);
    return JSON.parse(res as string) as OracleOfferResponse;
  } catch (err) {
    if (err instanceof AxiosError && err.response?.status === 404) {
      return { offers: [], deleteOffers: [] };
    }
    throw new Error('Fetching oracle offers failed');
  }
};

/**
 * Gets offer details from the blockchain (dApp).
 * @description Fetches each offer key on its own.
 * @param offerId ID of the offer.
 * @param assetStage Stage of the offer.
 * @throws Error if one of all offer key requests failed.
 */
export const getOfferData = async (offerId: string, assetStage: Offer['assetStage']): Promise<BlockchainOffer> => {
  try {
    const [status, type, contactType, assetId, tokenId, amount, price, firstParty] = await Promise.all([
      getDataWithKey<Offer['status']>(REACT_APP_API_DAPP_ADDRESS, offerId),
      getDataWithKey<Offer['type']>(REACT_APP_API_DAPP_ADDRESS, `${offerId}_type`),
      getDataWithKey<Offer['contactType']>(REACT_APP_API_DAPP_ADDRESS, `${offerId}_contactType`),
      getDataWithKey(REACT_APP_API_DAPP_ADDRESS, `${offerId}_assetId`),
      getDataWithKey(REACT_APP_API_DAPP_ADDRESS, `${offerId}_tokenId`),
      getDataWithKey(REACT_APP_API_DAPP_ADDRESS, `${offerId}_amount`),
      getDataWithKey(REACT_APP_API_DAPP_ADDRESS, `${offerId}_price`),
      getDataWithKey(REACT_APP_API_DAPP_ADDRESS, `${offerId}_firstParty`),
    ]);
    return {
      offerId,
      assetStage,
      status,
      type,
      contactType,
      assetId,
      tokenId,
      amount: +amount,
      price: +price / 100,
      firstParty,
    };
  } catch (err) {
    throw new Error('Fetching offer data failed.');
  }
};
