import { FC, useState } from 'react';
import { TableCell, TableRow } from '@mui/material';
import { BrickwiseTable } from '../mui-overrides/BrickwiseTable';
import { useBonus } from 'src/hooks/useBonus';

export const UserBonusComponent: FC<{
  userId: string;
}> = ({ userId }) => {
  if (!userId) {
    return null;
  }
  const [page, setPage] = useState(0);
  const result = useBonus({ userId, page }) || [];
  if (!result) {
    return null;
  }

  return (
    <BrickwiseTable
      page={page}
      setPage={setPage}
      renderHeader={() => (
        <TableRow>
          <TableCell>id</TableCell>
          <TableCell>message</TableCell>
          <TableCell>type</TableCell>
          <TableCell>amount</TableCell>
          <TableCell>assetId</TableCell>
          <TableCell>information</TableCell>
          <TableCell>minimumNumberOfShares</TableCell>
          <TableCell>minimumVolume</TableCell>
          <TableCell>partnerId</TableCell>
        </TableRow>
      )}
      length={result.count}
      rowsPerPage={20}
      hidePagination={false}
      hideChangePageLengthChange={false}
      renderRows={() => {
        return result.bonuses?.map((bonus, index) => {
          return (
            <TableRow key={index}>
              <TableCell style={{ whiteSpace: 'nowrap', maxWidth: '100px', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                {bonus.id}
              </TableCell>
              <TableCell>{bonus.message}</TableCell>
              <TableCell>{bonus.type}</TableCell>
              <TableCell>{bonus.amount}</TableCell>
              <TableCell>{bonus.assetId}</TableCell>
              <TableCell>{bonus.information}</TableCell>
              <TableCell>{bonus.minimumNumberOfShares}</TableCell>
              <TableCell>{bonus.minimumVolume}</TableCell>
              <TableCell>{bonus.partnerId}</TableCell>
            </TableRow>
          );
        });
      }}
    />
  );
};
