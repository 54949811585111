import { useEffect, useState } from 'react';
import { getOfferData, getOracleOffers } from '../api/blockchainApi';
import { apiErrorHandling } from '../api/apierrorhandling';
import { BlockchainOffer } from '../models/blockchain';
import { Offer } from '../models/offers';

export const useBlockchainOffers = (walletAddress?: string, onLoadStart?: () => void, onLoadEnd?: () => void) => {
  const [offers, setOffers] = useState<BlockchainOffer[]>([]);
  useEffect(() => {
    (async () => {
      onLoadStart?.();
      try {
        if (!walletAddress) return;
        const { offers: secondaryOffers, deleteOffers } = await getOracleOffers(walletAddress);
        const detailedOffers: BlockchainOffer[] = [];
        const addDetailedOffers = async (offerIds: string[], assetStage: Offer['assetStage']) => {
          for (const offerId of offerIds) {
            const detailedOffer = await getOfferData(offerId, assetStage);
            detailedOffers.unshift(detailedOffer);
          }
        };
        await addDetailedOffers(secondaryOffers, 'secondary');
        await addDetailedOffers(deleteOffers, 'deleteOffer');
        setOffers(detailedOffers);
      } catch (err) {
        apiErrorHandling(err);
      } finally {
        onLoadEnd?.();
      }
      return () => {
        onLoadEnd?.();
      };
    })();
  }, [walletAddress]);
  return offers;
};
