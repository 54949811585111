import { useEffect, useState } from 'react';
import { apiErrorHandling } from 'src/api/apierrorhandling';
import { getBonuses } from 'src/api/oApi';
import { BonusItem } from 'src/models/bonus';
// import { OfferResponse } from 'src/models/offers';
import { generalStore } from 'src/stores/GeneralStore';

export const useBonus = ({ userId, page = 0 }: { userId: string; page: number }) => {
  const [bonuses, setBonuses] = useState<{ bonuses: BonusItem[] | null; count: number }>({
    bonuses: [],
    count: 0,
  });
  useEffect(() => {
    const load = async () => {
      generalStore.setIsLoading(true);
      try {
        const response = await getBonuses(userId, page);
        setBonuses(response.data?.data as any);
      } catch (error: any) {
        apiErrorHandling(error);
      } finally {
        generalStore.setIsLoading(false);
      }
    };
    load();
  }, [userId, page]);
  return bonuses;
};
