import MenuIcon from '@mui/icons-material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, Menu, MenuItem, useMediaQuery } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { UserRoles } from 'src/models/enum';
import { authStore } from 'src/stores/AuthStore';
import { isSmallMobileDeviceQuery } from 'src/util/responsive';
import { Colors } from 'src/components/utils/Theme';
import { routes } from 'src/router/AppRouter';

const drawerWidth = 180;

interface Props {
  children: React.ReactNode;
}

export const BrickwiseDrawer: FC<Props> = ({ children }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const isSmallMobileDevice = useMediaQuery(isSmallMobileDeviceQuery);
  const [showDrawer, setShowDrawer] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    authStore.logout();
    handleClose();
  };

  const menuItems = [
    {
      label: t('drawer.menuitem.user'),
      path: routes.USER,
      scopes: [UserRoles.SupportUser, UserRoles.ComplianceUser],
    },
    {
      path: routes.OFFERS,
      label: t('drawer.menuitem.offers'),
      scopes: [UserRoles.SupportUser, UserRoles.ComplianceUser],
    },
    {
      path: routes.TRANSACTIONS,
      label: t('drawer.menuitem.transactions'),
      scopes: [UserRoles.ComplianceUser],
    },
    {
      path: routes.SUPPORT,
      label: t('drawer.menuitem.support'),
      scopes: [UserRoles.SupportUser, UserRoles.ComplianceUser],
    },
    {
      path: routes.BONUSCODE,
      label: t('drawer.menuitem.bonuscode'),
      scopes: [UserRoles.SupportUser, UserRoles.ComplianceUser],
    },
    {
      path: routes.AFFILIATE,
      label: t('drawer.menuitem.affiliate'),
      scopes: [UserRoles.SupportUser, UserRoles.ComplianceUser],
    },
    {
      path: routes.IDENTIFICATION,
      label: t('drawer.menuitem.identification'),
      scopes: [UserRoles.SupportUser, UserRoles.ComplianceUser],
    },
    {
      path: routes.QUESTIONS,
      label: t('drawer.menuitem.questions'),
      scopes: [UserRoles.SupportUser, UserRoles.ComplianceUser],
    },
    {
      path: routes.ASSETS,
      label: t('drawer.menuitem.assets'),
      scopes: [UserRoles.SupportUser, UserRoles.ComplianceUser],
    },
    {
      path: routes.PARTNERS,
      label: t('drawer.menuitem.partners'),
      scopes: [UserRoles.SupportUser, UserRoles.ComplianceUser],
    },
    {
      path: routes.POTENTIALUSERS,
      label: 'Potential Users',
      scopes: [UserRoles.SupportUser, UserRoles.ComplianceUser],
    },
  ];

  return (
    <Box sx={{ display: 'flex', maxWidth: '100vw' }}>
      <CssBaseline />
      <AppBar
        elevation={0}
        position="fixed"
        sx={{
          color: 'black',
          backgroundColor: 'white',
          zIndex: (theme) => theme.zIndex.drawer + 1,
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        }}
      >
        <Toolbar>
          {isSmallMobileDevice && (
            <IconButton size="large" edge="start" color="inherit" onClick={() => setShowDrawer(!showDrawer)}>
              <MenuIcon />
            </IconButton>
          )}
          <Link style={{ zIndex: 100 }} to={routes.USER}>
            <img style={{ cursor: 'pointer' }} src="/logo.svg" alt="brickwise-logo" />
          </Link>

          <div style={{ flexGrow: 1 }}></div>

          <IconButton onClick={handleClick}>
            <MoreVertIcon />
          </IconButton>
          <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
            <MenuItem disabled>{authStore.username}</MenuItem>
            <MenuItem onClick={logout}>{t('drawer.logout')}</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Drawer
        BackdropProps={{ invisible: true }}
        open={showDrawer}
        variant={isSmallMobileDevice ? 'temporary' : 'permanent'}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          ['& .MuiDrawer-paper']: { width: drawerWidth, boxSizing: 'border-box' },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>
          <List>
            {menuItems
              .filter((item) => item.scopes.includes(authStore.role as UserRoles))
              .map((item, index: number) => (
                <ListItem key={index} disablePadding>
                  <ListItemButton to={item.path} component={Link}>
                    <ListItemText
                      sx={{
                        color: location.pathname.startsWith(item.path) ? Colors.SECONDARY_COLOR : Colors.BLACK_BLUE,
                        opacity: location.pathname.startsWith(item.path) ? 1 : 0.8,
                      }}
                      primary={item.label}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
          </List>
        </Box>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3, maxWidth: '100%', overflow: 'hidden' }}>
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
};
