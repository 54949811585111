import { Grid } from '@mui/material';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { FC, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { FilterChips } from '../FilterChips';
import { TextSearchField } from '../TextSearchField';

interface Props {
  setPage?: any;
  formikObject: FormikProps<UserFieldsFilter>;
}

export type UserFieldsFilter = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  userId: string;
  referrerUserId: string;
  affiliatedBy: string;
  wallet: string;
};

export const UserFilter: FC<Props> = ({ setPage, formikObject }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    Object.keys(formikObject?.initialValues).map((key) => {
      formikObject.setFieldValue(key, searchParams.get(key));
    });
  }, []);
  return (
    <>
      {/* <h4 style={{ margin: '10px' }}>Filters</h4> */}
      <Grid container justifyContent="flex-start" spacing={1}>
        {Object.keys(formikObject?.initialValues).map((key, index) => {
          return (
            <Grid item>
              <TextSearchField
                name={key}
                submit={formikObject.submitForm}
                key={key + index}
                setSearchParams={setSearchParams}
                setPage={setPage}
                label={key}
                field={formikObject.values[key as keyof UserFieldsFilter]}
                setField={(value: any) => formikObject.setFieldValue(key, value)}
              />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

type Values = {
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  phone: string | null;
  userId: string | null;
  referrerUserId: string | null;
  affiliatedBy: string | null;
  wallet: string | null;
  // page: number | null;
};

type SubmitFunction = ((values: Values, formikHelpers?: FormikHelpers<Values>) => void) &
  ((values: Values, actions: FormikHelpers<Values>) => void);

export const UserFilterForm: FC<{ setPage: any; page: number | null; submit: SubmitFunction; enableFilterChips?: boolean }> = ({
  setPage,
  page,
  submit,
  enableFilterChips = false,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const initialValues = {
    firstName: searchParams.get('firstName'),
    lastName: searchParams.get('lastName'),
    email: searchParams.get('email'),
    phone: searchParams.get('phone'),
    userId: searchParams.get('userId'),
    referrerUserId: searchParams.get('referrerUserId'),
    affiliatedBy: searchParams.get('affiliatedBy'),
    wallet: searchParams.get('wallet'),
    page,
  };
  useEffect(() => {
    initialValues.page = page;
    submit(initialValues);
  }, [page]);
  return (
    <>
      <Formik
        onSubmit={(values) => {
          values.page = page ? page : 0;
          submit(values);
        }}
        initialValues={initialValues}
      >
        {(formikObject) => {
          return (
            <>
              {/* <h4 style={{ margin: '10px' }}>Filters</h4> */}
              <Grid container justifyContent="flex-start" spacing={1}>
                {Object.keys(formikObject?.initialValues).map((key, index) => {
                  return key === 'page' ? null : (
                    <Grid item>
                      <TextSearchField
                        name={key}
                        submit={formikObject.submitForm}
                        key={key + index}
                        setSearchParams={setSearchParams}
                        setPage={setPage}
                        label={key}
                        field={formikObject.values[key as keyof UserFieldsFilter]}
                        setField={(value: any) => formikObject.setFieldValue(key, value)}
                      />
                    </Grid>
                  );
                })}
              </Grid>
              {enableFilterChips ? <FilterChips setPage={setPage} formikObject={formikObject} /> : null}
            </>
          );
        }}
      </Formik>
    </>
  );
};
