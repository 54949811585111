import { useState } from 'react';
import { TableRow, TableCell } from '@mui/material';
import { BrickwiseTable } from 'src/components/mui-overrides/BrickwiseTable';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { SupportFilterDialog } from 'src/components/dialogs/SupportFilterDialog';
import { useSupportCases } from 'src/hooks/useOpenSupportCases';

export const SupportSite = () => {
  const [showFilter, setShowFilter] = useState(false);
  const [status, setStatus] = useState<null | undefined | SupportCaseStatus | 'All'>(SupportCaseStatus.new);
  const [type, setType] = useState<null | undefined | SupportCaseEnum | 'All'>('All');
  const [page, setPage] = useState(0);

  const [userId, setUserId] = useState('');
  const [top] = useState(0);
  const [left] = useState(0);
  return (
    <>
      <SupportFilterDialog
        left={left}
        top={top}
        open={showFilter}
        userId={userId}
        setPage={setPage}
        setUserId={setUserId}
        onClose={() => setShowFilter(false)}
        status={status}
        setStatus={setStatus}
        type={type}
        setType={setType}
      />
      <SupportTable status={status as 'All'} type={type as any} userId={userId} page={page} setPage={setPage} />
    </>
  );
};

const enum SupportCaseStatus {
  new = 'new',
  open = 'open',
  waiting = 'waiting',
  closed = 'closed',
}

const enum SupportCaseEnum {
  identificationManualDocumentCheck = 'identificationManualDocumentCheck',
  identificationPEP = 'identificationPEP',
  identificationAddProofOfResidence = 'identificationAddProofOfResidence',
  mangopayManualDocumentUpload = 'mangopayManualDocumentUpload',
  mangopayFailedPayout = 'mangopayFailedPayout',
  notaryProcess = 'notaryProcess',
  addBonus = 'addBonus',
  deleteUser = 'deleteUser',
}

const SupportTable = ({
  status,
  type,
  userId,
  page,
  setPage,
}: {
  page?: number;
  setPage?: any;
  status?: SupportCaseStatus | 'All';
  type?: SupportCaseEnum;
  userId?: string;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const cases = useSupportCases({
    page,
    status,
    type,
    userId,
  });
  return (
    <BrickwiseTable
      page={page}
      rowsPerPage={10}
      hidePagination={false}
      hideChangePageLengthChange={false}
      length={cases?.count ?? 0}
      setPage={setPage}
      renderHeader={() => {
        return (
          <TableRow>
            <TableCell>{t('support.table.type')}</TableCell>
            <TableCell>{t('support.table.supportCaseUrgency')}</TableCell>
            <TableCell>{t('support.table.status')}</TableCell>
            <TableCell>{t('support.table.createdAt')}</TableCell>
            <TableCell>{t('support.table.userId')}</TableCell>
            <TableCell>{t('support.table.userName')}</TableCell>
            <TableCell>{t('support.table.id')}</TableCell>
          </TableRow>
        );
      }}
      renderRows={() => {
        return (
          <>
            {cases?.data.map((row, index) => {
              return (
                <TableRow
                  // component={Link}

                  onClick={() => navigate(row.id)}
                  key={index}
                  hover
                  style={{ cursor: 'pointer', textDecoration: 'none', backgroundColor: colorPicker(row.status, row.supportCaseUrgency) }}
                >
                  <TableCell>{caseObject[row.supportCaseType as SupportCaseEnum]}</TableCell>
                  <TableCell>{row.supportCaseUrgency}</TableCell>
                  <TableCell>{row.status}</TableCell>
                  <TableCell>{row.createdAt}</TableCell>
                  <TableCell>{row.userId}</TableCell>
                  <TableCell>{row.user ? row.user.firstName + ' ' + row.user.lastName : ''}</TableCell>
                  <TableCell>{row.id}</TableCell>
                </TableRow>
              );
            })}
          </>
        );
      }}
    />
  );
};
const caseObject = {
  identificationManualDocumentCheck: 'Identification Manual Document Check',
  identificationPEP: 'Identification PEP',
  identificationAddProofOfResidence: 'Identification Add Proof Of Residence',
  mangopayManualDocumentUpload: 'Mangopay Manual Document Upload',
  mangopayFailedPayout: 'Mangopay Failed Payout',
  notaryProcess: 'Notary Process',
  addBonus: 'Add Bonus',
  deleteUser: 'Delete User',
};

const colorPicker = (status: string, urgency: string) => {
  if (status === 'closed') return 'lightgrey';
  if (urgency === 'high') return 'rgba(241, 132, 122, 0.5)';
  return '';
};
