import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { useState, FC } from 'react';
interface Props {
  open: boolean;
  onClose: () => void;
  onDownload: (from: Date | null, to: Date | null) => void;
  title?: string;
}

export const UserReportsDownloadDialog: FC<Props> = ({ open, onClose, title, onDownload }) => {
  const [from, setFrom] = useState<Date | null>(new Date());
  const [to, setTo] = useState<Date | null>(new Date());

  const handleFromChange = (newValue: Date | null) => {
    setFrom(newValue);
  };

  const handleToChange = (newValue: Date | null) => {
    setTo(newValue);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title || 'User Report'}</DialogTitle>
      <DialogContent style={{ padding: '15px' }} className="">
        <MobileDatePicker label="From" value={from} onChange={handleFromChange} renderInput={(params) => <TextField {...params} />} />
        <MobileDatePicker label="To" value={to} onChange={handleToChange} renderInput={(params) => <TextField {...params} />} />
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={onClose}>
          Cancel
        </Button>
        <Button color="inherit" onClick={() => onDownload(from, to)} variant="contained" disableElevation>
          Download
        </Button>
      </DialogActions>
    </Dialog>
  );
};
