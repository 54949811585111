import { Grid } from '@mui/material';
import { Formik } from 'formik';
import { FC, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
// import { FilterChips } from '../FilterChips';
import { TextSearchField } from '../TextSearchField';
import { SelectField } from '../SelectField';

interface Props {
  submit: any;
  setPage: any;
  page: number | null;
}

export const OfferFilter: FC<Props> = ({ submit, page, setPage }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const initialValues = {
    assetId: searchParams.get('assetId'),
    offerId: searchParams.get('offerId'),
    firstParty: searchParams.get('firstParty'),
    secondParty: searchParams.get('secondParty'),
    status: searchParams.get('status') || 'All',
  };
  useEffect(() => {
    submit(initialValues);
  }, [page]);

  return (
    <Formik
      onSubmit={(values) => {
        submit({
          ...values,
          page,
        });
      }}
      initialValues={initialValues}
    >
      {(formik) => {
        return (
          <>
            {/* <h4 style={{ margin: '10px' }}>Filters</h4> */}
            <Grid container spacing={1}>
              <Grid item>
                <TextSearchField
                  onBlur={formik.handleBlur}
                  name="offerId"
                  setSearchParams={setSearchParams}
                  setPage={setPage}
                  label="OfferId"
                  field={formik.values.offerId}
                  submit={formik.submitForm}
                  setField={(value: any) => formik.setFieldValue('offerId', value)}
                />
              </Grid>
              <Grid item>
                <TextSearchField
                  onBlur={formik.handleBlur}
                  name="assetId"
                  setSearchParams={setSearchParams}
                  setPage={setPage}
                  label="assetId"
                  field={formik.values.assetId}
                  submit={formik.submitForm}
                  setField={(value: any) => formik.setFieldValue('assetId', value)}
                />
              </Grid>
              <Grid item>
                <TextSearchField
                  name="firstParty"
                  onBlur={formik.handleBlur}
                  setSearchParams={setSearchParams}
                  setPage={setPage}
                  label="First Party"
                  field={formik.values.firstParty}
                  submit={formik.submitForm}
                  setField={(value: any) => formik.setFieldValue('firstParty', value)}
                />
              </Grid>
              <Grid item>
                <TextSearchField
                  onBlur={formik.handleBlur}
                  name="secondParty"
                  setSearchParams={setSearchParams}
                  setPage={setPage}
                  label="Second Party"
                  field={formik.values.secondParty}
                  submit={formik.submitForm}
                  setField={(value: any) => formik.setFieldValue('secondParty', value)}
                />
              </Grid>
              <Grid item>
                <SelectField
                  onBlur={formik.handleBlur}
                  name="status"
                  setSearchParams={setSearchParams}
                  setPage={setPage}
                  label="Status"
                  field={formik.values.status}
                  submit={formik.submitForm}
                  setField={(value: any) => formik.setFieldValue('status', value)}
                  options={[
                    { value: 'All', label: 'All' },
                    { value: 'deleted', label: 'Deleted' },
                    { value: 'closed', label: 'Closed' },
                    { value: 'pending', label: 'Pending' },
                    { value: 'charged', label: 'Charged' },
                    { value: 'accepted', label: 'Accepted' },
                    { value: 'reserved', label: 'Reserved' },
                    { value: 'init', label: 'Init' },
                    { value: 'success', label: 'Success' },
                    { value: 'error', label: 'Error' },
                    { value: 'canceled', label: 'Canceled' },
                    { value: 'canceledOffer', label: 'Canceled Offer' },
                    { value: 'refund', label: 'Refund' },
                    { value: 'open', label: 'Open' },
                  ]}
                />
              </Grid>
            </Grid>
          </>
        );
      }}
    </Formik>
  );
};

// export const UserFilterForm: FC<{ setPage: any; page: number | null; submit: SubmitFunction; enableFilterChips?: boolean }> = ({
//   setPage,
//   page,
//   submit,
//   enableFilterChips = false,
// }) => {
//   const [searchParams, setSearchParams] = useSearchParams();
//   const initialValues = {
//     firstName: searchParams.get('firstName'),
//     lastName: searchParams.get('lastName'),
//     email: searchParams.get('email'),
//     phone: searchParams.get('phone'),
//     userId: searchParams.get('userId'),
//     referrerUserId: searchParams.get('referrerUserId'),
//     wallet: searchParams.get('wallet'),
//     page,
//   };
//   useEffect(() => {
//     initialValues.page = page;
//     submit(initialValues);
//   }, [page]);
//   return (
//     <>
//       <Formik
//         onSubmit={(values) => {
//           values.page = page ? page : 0;
//           submit(values);
//         }}
//         initialValues={initialValues}
//       >
//         {(formikObject) => {
//           return (
//             <>
//               <h4 style={{ margin: '10px' }}>Filters</h4>
//               <Grid container justifyContent="flex-start" spacing={2}>
//                 {Object.keys(formikObject?.initialValues).map((key, index) => {
//                   return (
//                     <TextSearchField
//                       name={key}
//                       submit={formikObject.submitForm}
//                       key={key + index}
//                       setSearchParams={setSearchParams}
//                       setPage={setPage}
//                       label={key}
//                       field={formikObject.values[key as keyof UserFieldsFilter]}
//                       setField={(value: any) => formikObject.setFieldValue(key, value)}
//                     />
//                   );
//                 })}
//               </Grid>
//               {enableFilterChips ? <FilterChips setPage={setPage} formikObject={formikObject} /> : null}
//             </>
//           );
//         }}
//       </Formik>
//     </>
//   );
// };
