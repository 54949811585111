import { Slide, Snackbar } from '@mui/material';
import { observer } from 'mobx-react';
import { generalStore } from 'src/stores/GeneralStore';
import { Colors } from 'src/components/utils/Theme';

const SlideTransition = (props: any) => <Slide {...props} direction="up" />;

export const ErrorToast = observer(() => {
  const handleClose = () => {
    generalStore.unsetError();
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={!!generalStore.error}
      key={generalStore.error?.message}
      onClose={handleClose}
      autoHideDuration={5000}
      message={generalStore.error?.message}
      TransitionComponent={SlideTransition}
      ContentProps={{
        style: {
          backgroundColor: Colors.SECONDARY_COLOR,
          color: 'white',
        },
      }}
    />
  );
});

export const MessageToast = observer(() => {
  const handleClose = () => {
    generalStore.unsetMessage();
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={!!generalStore.message}
      key={generalStore.message?.message}
      onClose={handleClose}
      autoHideDuration={5000}
      message={generalStore.message?.message}
      TransitionComponent={SlideTransition}
      ContentProps={{
        style: {
          backgroundColor: Colors.SUCCESS,
          color: 'white',
        },
      }}
    />
  );
});
