import { Autocomplete, Box, CircularProgress, InputAdornment, TextField } from '@mui/material';
import { FC, Fragment, useState } from 'react';
import * as _ from 'lodash';
import SearchIcon from '@mui/icons-material/Search';

interface MapsAutocompleteProps {
  label: string;
  field: string;
  formik: any;
  onAutocompleteSelected: (value: google.maps.places.AutocompletePrediction) => void;
}

export const MapsAutocomplete: FC<MapsAutocompleteProps> = ({ label, onAutocompleteSelected }) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<google.maps.places.AutocompletePrediction[]>([]);
  const [loading, setLoading] = useState(false);
  let searchString = '';
  let autocompletetoken: google.maps.places.AutocompleteSessionToken;

  const debouncedValueChange = _.debounce(
    () => {
      return getAutoCompleteFromGoogle();
    },
    100,
    { trailing: true },
  );

  const getAutoCompleteFromGoogle = () => {
    if (!autocompletetoken) {
      autocompletetoken = new google.maps.places.AutocompleteSessionToken();
    }
    const autocompleteService = new google.maps.places.AutocompleteService();
    autocompleteService.getPlacePredictions(
      {
        input: searchString,
        sessionToken: autocompletetoken,
        componentRestrictions: { country: ['at', 'de'] },
      },
      (result) => {
        console.log(result);
        if (result) {
          setOptions(result);
        } else {
          setOptions([]);
        }
        setLoading(false);
      },
    );
  };

  return (
    <Autocomplete
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onChange={(event, value: any) => onAutocompleteSelected(value)}
      getOptionLabel={(option) => option.description}
      options={options}
      renderOption={(props, option: google.maps.places.AutocompletePrediction) => (
        <Box
          component="li"
          {...props}
          key={option.place_id}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start !important',
            cursor: 'pointer',
          }}
        >
          <div>{option.structured_formatting.main_text}</div>
          <small>{option.structured_formatting.secondary_text}</small>
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          label={label}
          margin="normal"
          {...params}
          onChange={(event) => {
            if (loading) {
              return;
            }
            setLoading(true);
            if (!event.target.value) {
              setOptions([]);
              debouncedValueChange.cancel();
              return;
            }
            searchString = event.target.value;
            debouncedValueChange();
          }}
          InputProps={{
            ...params.InputProps,
            autoComplete: 'off',
            type: 'text',
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
    />
  );
};
