import { FC, useState } from 'react';
import { UserItem } from '../../../models/users';
import { useWalletAssets } from '../../../hooks/useWalletAssets';
import { TableCell, TableRow, Typography } from '@mui/material';
import { CopyToClipboardButton } from '../../ui/CopyToClipboardButton';
import { BrickwiseTable } from '../../mui-overrides/BrickwiseTable';
import { setStateQueryParameter } from '../../../hooks/useStateQueryParams';
import { LoadingInline } from '../../ui/LoadingInline';

const rowsPerPage = 10;

export const UserBlockchainAssets: FC<{ wallet: UserItem['wallet'] }> = ({ wallet }) => {
  const [page, setPage] = setStateQueryParameter<number>(0, 'assetsPage', 'number');
  const [isLoading, setIsLoading] = useState(true);
  const walletAssetsMap = useWalletAssets(
    wallet,
    () => setIsLoading(true),
    () => setIsLoading(false),
  );
  const assets = !walletAssetsMap ? [] : [...walletAssetsMap.entries()].map(([key, value]) => ({ assetId: key, ...value }));
  const paginatedAssets = assets.slice(page * rowsPerPage, (page + 1) * rowsPerPage);
  const renderHeader = () => (
    <TableRow>
      <TableCell>Asset ID</TableCell>
      <TableCell>Token ID</TableCell>
      <TableCell>Description</TableCell>
      <TableCell>Balance</TableCell>
      <TableCell>Wallet address</TableCell>
    </TableRow>
  );
  const renderRows = () => {
    if (paginatedAssets.length === 0) {
      return (
        <TableRow>
          <TableCell align="center" colSpan={100}>
            No assets found.
          </TableCell>
        </TableRow>
      );
    }
    return paginatedAssets.map((asset) => (
      <TableRow key={asset.assetId}>
        <TableCell>{asset.assetId}</TableCell>
        <TableCell>
          <CopyToClipboardButton text={asset.tokenId} label={asset.tokenId} shouldTruncate />
        </TableCell>
        <TableCell>{asset.description}</TableCell>
        <TableCell>{asset.balance}</TableCell>
        <TableCell>{asset.wallet ? <CopyToClipboardButton text={asset.wallet} label={asset.wallet} shouldTruncate /> : null}</TableCell>
      </TableRow>
    ));
  };
  return (
    <>
      <Typography variant="h6" mt={3} component="h2" align="center">
        Assets
      </Typography>
      {isLoading ? (
        <LoadingInline />
      ) : (
        <BrickwiseTable
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          hidePagination={false}
          hideChangePageLengthChange={false}
          length={assets.length}
          renderHeader={renderHeader}
          renderRows={renderRows}
        ></BrickwiseTable>
      )}
    </>
  );
};
