import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { FC } from 'react';

export const SelectField: FC<{
  field: any;
  setField: any;
  label: string;
  setPage?: any;
  // eslint-disable-next-line @typescript-eslint/ban-types
  setSearchParams?: Function;
  name: string;
  onBlur?: any;
  submit?: () => void;
  options: { value: string; label: string }[];
}> = ({ submit, field, setField, label, setPage, setSearchParams, name, onBlur, options }) => {
  return (
    <FormControl variant="filled">
      <InputLabel id="status-filter-label">status</InputLabel>
      <Select
        labelId="status-filter-label"
        onBlur={onBlur}
        name="status"
        label={label}
        id="status-filter"
        value={field}
        onChange={(e: any) => {
          console.log('e.target.value', e.target.value);
          setField(e.target.value);
          // When there is a submit probably we do not want
          setPage(0);
          const params = new URLSearchParams(window.location.search);
          params.delete(name);
          params.append(name, e.target.value);
          if (setSearchParams) {
            setSearchParams(params);
          }
          if (submit) {
            submit();
          }
        }}
      >
        {options.map((option, index) => {
          return (
            <MenuItem key={label + '_option_' + index} value={option.value}>
              {option.label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
