import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { LoadingButton } from '@mui/lab';
import { TextField } from '@mui/material';
import { postSupportCaseInformation } from 'src/api/oApi';
import { generalStore } from 'src/stores/GeneralStore';
import { SupportCaseByIdResponse } from 'src/models/supportcaseById';

// eslint-disable-next-line @typescript-eslint/naming-convention
export default function SendMessageDialog({
  open,
  setOpen,
  supportCase,
  forceUpdate,
}: {
  open: boolean;
  forceUpdate?: any;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  supportCase?: SupportCaseByIdResponse | null;
}) {
  if (!supportCase) return null;
  const handleClose = () => {
    setOpen(false);
  };

  const [message, setMessage] = React.useState<string | null>(null);

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Message</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <TextField
              fullWidth
              sx={{ m: 1 }}
              placeholder="Write here"
              type="text"
              name="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            disabled={!message}
            onClick={async () => {
              try {
                await postSupportCaseInformation({
                  message: message as string,
                  supportCaseId: supportCase.id,
                });
                generalStore.setMessage('Successfully Created the Message');
                if (forceUpdate) forceUpdate();
                setOpen(false);
              } catch (error: any) {
                generalStore.setError(error?.response?.data?.error?.message || error?.response?.data?.error);
              }
            }}
            fullWidth
            sx={{ m: 1 }}
            color="primary"
            variant="contained"
          >
            Send
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
