import Chip from '@mui/material/Chip';
import { FormikProps } from 'formik/dist/types';

// eslint-disable-next-line @typescript-eslint/naming-convention
export function FilterChips<T>({ formikObject, setPage }: { formikObject: FormikProps<T>; setPage?: any }) {
  const chips = Object.keys(Object(formikObject.initialValues))
    .map((field) => {
      return {
        field: formikObject.values[field as keyof typeof formikObject.initialValues],
        name: field,
        onDelete: () => {
          formikObject.setFieldValue(field, undefined);
          if (setPage) setPage(0);
          formikObject.submitForm();
        },
      };
    })
    .map(({ field, name, onDelete }) => {
      if (field) {
        return <Chip style={{ margin: '4px' }} key={'chip-' + name} id={'chip-' + name} label={name + ':' + field} onDelete={onDelete} />;
      } else {
        return null;
      }
    })
    .filter((chip) => chip !== null);

  if (chips.length === 0) {
    return null;
  }

  if (chips.length) {
    chips.unshift(
      <Chip
        style={{ margin: '4px', backgroundColor: 'lightgrey' }}
        key={'chip-clear'}
        id={'chip-clear'}
        label={'Remove all'}
        onDelete={() => formikObject.resetForm()}
      />,
    );
  }

  return <div style={{ margin: '16px' }}>{chips}</div>;
}
