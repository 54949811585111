import { makeAutoObservable } from 'mobx';

class GeneralStore {
  isLoading = false;
  message?: {
    message: string;
    color?: string;
  };
  error?: { message: string; error?: any };

  constructor() {
    makeAutoObservable(this);
  }

  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  setMessage = (message: string, color?: string) => {
    this.message = { message, color };
  };

  unsetMessage = () => {
    this.message = undefined;
  };

  setError = (message: string, error?: any) => {
    this.error = { message, error: error };
  };

  unsetError = () => {
    this.error = undefined;
  };
}

const generalStore = new GeneralStore();

export { generalStore };
