import { Box, TablePagination } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import { FC } from 'react';

interface Props {
  hidePagination: boolean;
  hideChangePageLengthChange: boolean;
  length: number;
  page?: number;
  rowsPerPage?: number;
  setPage?: (page: number) => void;
  setRowsPerPage?: (rowsPerPage: number) => void;
  renderHeader(): React.ReactNode;
  renderRows(): React.ReactNode;
}

export const BrickwiseTable: FC<Props> = ({
  hidePagination,
  length,
  page,
  rowsPerPage,
  renderHeader,
  renderRows,
  setPage,
  setRowsPerPage,
}) => {
  return (
    <Box sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer component={Paper}>
        <Table
          stickyHeader
          style={{
            borderCollapse: 'separate',
          }}
        >
          <TableHead>{renderHeader()}</TableHead>
          <TableBody>{renderRows()}</TableBody>
        </Table>
      </TableContainer>
      {!hidePagination && setPage && rowsPerPage ? (
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={length}
          rowsPerPage={rowsPerPage}
          page={page ?? 0}
          onRowsPerPageChange={(event) => {
            if (setRowsPerPage) setRowsPerPage(event.target.value as any as number);
          }}
          onPageChange={(event, newPage) => {
            setPage(newPage);
          }}
        />
      ) : null}
    </Box>
  );
};
