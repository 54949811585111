import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { LoadingButton } from '@mui/lab';
import { MenuItem, Select, TextField } from '@mui/material';
import { changeTaxID } from 'src/api/oApi';
import { generalStore } from 'src/stores/GeneralStore';
import { CountryAlpha3, countries } from 'src/helpers/countries';
import { UserItem } from 'src/models/users';
import { EditButton } from '../EditButton';

// eslint-disable-next-line @typescript-eslint/naming-convention
export default function TaxDialog({
  open,
  setOpen,
  userData,
  forceReload,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  userData: {
    firstName: string;
    lastName: string;
    phone: string;
    userId: string;
  };
  forceReload?: () => void;
}) {
  const [taxId, setTaxId] = React.useState<string | null>(null);
  const [country, setCountry] = React.useState<string>('');
  // better version with useCallback
  const handleClose = React.useCallback(() => {
    setOpen(false);
  }, []);
  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title"> Change Tax ID</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div>
              <TextField
                fullWidth
                sx={{ m: 1 }}
                placeholder="TaxID..."
                type="text"
                value={taxId}
                onChange={(e) => setTaxId(e.target.value)}
              />
              <Select
                fullWidth
                sx={{ m: 1 }}
                labelId="countries"
                id="countries-select"
                value={country}
                label="Age"
                onChange={(e) => setCountry(e.target.value as CountryAlpha3)}
              >
                {countries.map((elem, index) => {
                  return (
                    <MenuItem key={'country-' + index} value={elem.alpha3}>
                      {elem.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            disabled={!taxId}
            onClick={async () => {
              try {
                generalStore.setIsLoading(true);
                await changeTaxID({
                  taxId: taxId as string,
                  country,
                  userId: userData?.userId as string,
                });
                setTaxId(null);
                generalStore.setMessage('Successfully Changed The Tax ID');
                generalStore.setIsLoading(false);
                if (forceReload) forceReload();
                setOpen(false);
              } catch (error) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                generalStore.setError(error?.response?.data?.error?.message || error?.response?.data?.error || 'Error on operation');
                generalStore.setIsLoading(false);
              }
            }}
            fullWidth
            sx={{ m: 1 }}
            color="primary"
            variant="contained"
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export const TaxButtonComponent: React.FC<{ forceReload: any; user: UserItem }> = ({ forceReload, user }) => {
  const [showTaxFrom, setShowTax] = React.useState(false);
  return (
    <>
      <TaxDialog open={showTaxFrom} setOpen={setShowTax} userData={user} forceReload={forceReload} />
      {user.status === 'active' ? (
        <EditButton
          onClick={() => {
            setShowTax(true);
          }}
        />
      ) : null}
    </>
  );
};
