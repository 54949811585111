import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { LoadingButton } from '@mui/lab';
import { TextField } from '@mui/material';
import { changePayoutAccount } from 'src/api/oApi';
import { generalStore } from 'src/stores/GeneralStore';
import { UserItem } from 'src/models/users';

// eslint-disable-next-line @typescript-eslint/naming-convention
export default function PayoutDialog({
  open,
  setOpen,
  userData,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  userData: {
    userId: string;
    payoutAccounts?: UserItem['payoutAccounts'];
  };
}) {
  const handleClose = () => {
    setOpen(false);
  };

  const [IBAN, setIBAN] = React.useState('');

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Edit Payout Account</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {userData?.payoutAccounts?.map((payoutAccount) => {
              return (
                <p style={{ margin: '3px' }}>
                  <span
                    style={{
                      borderRadius: '4px',
                      borderStyle: 'solid',
                      backgroundColor: 'rgb(238, 242, 240)',
                      border: 'none',
                      padding: '2px',
                      margin: '6px',
                    }}
                  >
                    {payoutAccount.IBAN}
                  </span>
                  <span
                    style={{
                      borderRadius: '4px',
                      borderStyle: 'solid',
                      backgroundColor: payoutAccount.isActive ? 'rgb(0, 255, 0)' : 'rgb(255, 0, 0)',
                      border: 'none',
                      padding: '2px',
                      margin: '6px',
                    }}
                  >
                    {payoutAccount.isActive ? 'Active' : 'Not Active'}
                  </span>
                </p>
              );
            })}
            <TextField fullWidth sx={{ m: 1 }} placeholder="IBAN..." type="text" value={IBAN} onChange={(e) => setIBAN(e.target.value)} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            disabled={!IBAN}
            onClick={async () => {
              try {
                generalStore.setIsLoading(true);
                await changePayoutAccount({
                  IBAN: IBAN as string,
                  userId: userData?.userId as string,
                });
                setIBAN('');
                generalStore.setMessage('Successfully Changed The Payout Account');
                generalStore.setIsLoading(false);
                handleClose();
              } catch (error) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                generalStore.setError(error.response.data.error.message);
                generalStore.setIsLoading(false);
              }
            }}
            fullWidth
            sx={{ m: 1 }}
            color="primary"
            variant="contained"
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
