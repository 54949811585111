import { useEffect, useState } from 'react';
import { apiErrorHandling } from 'src/api/apierrorhandling';
import { getIdentifications } from 'src/api/oApi';
import { UserFieldsFilter } from 'src/components/dialogs/UserFilter';
import { generalStore } from 'src/stores/GeneralStore';
// import { getIdentifications } from 'src/api/oApi';

export const useIdentifications = ({
  page = 0,
  forceUpdate,
  userInfo,
}: {
  page: number;
  forceUpdate: boolean;
  userInfo: UserFieldsFilter;
}) => {
  const [identifications, setIdentifications] = useState<IdentificationsReport | null>(null);
  useEffect(() => {
    const load = async () => {
      try {
        generalStore.setIsLoading(true);
        const response = await getIdentifications({
          page,
          firstName: userInfo.firstName,
          lastName: userInfo.lastName,
          email: userInfo.email,
          phone: userInfo.phone,
          userId: userInfo.userId,
          referrerUserId: userInfo.referrerUserId,
          wallet: userInfo.wallet,
        });
        const users = response.data.data;
        setIdentifications(users as unknown as IdentificationsReport);
        generalStore.setIsLoading(false);
      } catch (error: any) {
        generalStore.setIsLoading(false);
        generalStore.setError('Error In Fetching Identifications:', error);
        apiErrorHandling(error);
      } finally {
        // generalStore.setIsLoading(false);
      }
    };
    load();
  }, [
    page,
    forceUpdate,
    // userInfo.firstName,
    // userInfo.lastName,
    // userInfo.email,
    // userInfo.phone,
    // userInfo.userId,
    // userInfo.referrerUserId,
    // userInfo.wallet,
  ]);
  return identifications;
};

export type IdentificationsReport = {
  userId: string;
  email: string;
  status: string;
  idnow: [
    {
      attempts: number;
      ident: string;
      userId: string;
      status: string;
      transactionNumber: string;
      createdAt: Date;
      updatedAt: Date;
    },
  ];
  fourthline: [
    {
      attempts: number;
      fourthLineClientId: string;
      userId: string;
      fourthLineClientStatus: number;
      status: string;
      verifications: [
        {
          verificationId: string;
          fourthLineVerificationId: string;
          verificationStatus: string;
          verificationStatusFeedback: [
            {
              code: number;
              information: string;
            },
          ];
        },
      ];
      signatures: [
        {
          signatureId: string;
          signatureStatus: string;
        },
      ];
      createdAt: Date;
      updatedAt: Date;
    },
  ];
  firstName: string;
  lastName: string;
  fourthlineCreatedAt: Date;
  identificationCreatedAt: Date;
}[];
