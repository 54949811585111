import { FC } from 'react';
import { UserItem } from '../../../models/users';
import { UserBlockchainAssets } from './UserBlockchainAssets';
import { UserBlockchainOffers } from './UserBlockchainOffers';

export const UserBlockchain: FC<{ wallet: UserItem['wallet'] }> = ({ wallet }) => {
  return (
    <>
      <UserBlockchainAssets wallet={wallet} />
      <UserBlockchainOffers wallet={wallet} />
    </>
  );
};
