import { FC } from 'react';
import { TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

interface NormalDatepickerProps {
  label: string;
  field: string;
  formik: any;
  minDate?: Date;
  maxDate?: Date;
  ddmmyy?: Array<'day' | 'month' | 'year'>;
  disabled?: boolean;
}
export const NormalDatepicker: FC<NormalDatepickerProps> = ({ label, field, formik, minDate, maxDate, ddmmyy, disabled }) => {
  if (!ddmmyy) {
    ddmmyy = ['day', 'month', 'year'];
  }
  return (
    <DatePicker
      disabled={disabled}
      label={label}
      {...formik.getFieldProps(field)}
      onChange={(newValue: Date) => {
        formik.setFieldValue(field, new Date(newValue));
      }}
      minDate={minDate}
      maxDate={maxDate}
      views={ddmmyy}
      renderInput={(params) => (
        <TextField
          {...params}
          margin="normal"
          type="text"
          variant="outlined"
          fullWidth
          error={formik.touched[field] && Boolean(formik.errors[field])}
          helperText={(formik.touched[field] && formik.errors[field]) ?? ' '}
        />
      )}
    />
  );
};
