import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Switch,
  Typography,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { Formik } from 'formik';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { postAffiliate } from 'src/api/oApi';
import { DefaultAutoCompleteField, DefaultTextField } from 'src/components/mui-overrides/Textfield';
import { awaitUtil } from 'src/util/utils';
import { boolean, date, object, string } from 'yup';
import { NormalDatepicker } from '../mui-overrides/Datepicker';
import { MapsAutocomplete } from '../mui-overrides/MapsAutocomplete';

interface Props {
  open: boolean;
  handleClose: () => void;
  handleSave: () => void;
  partners: string[];
}

const schema = object({
  externalAffiliate: boolean(),
  name: string().min(1),
  email: string().email(),
  address: string().min(1).optional(),
  birthDate: date().optional(),
  partnerId: string().required(),
});

export const AddAffiliate: FC<Props> = ({ open, partners, handleClose, handleSave }) => {
  const { t } = useTranslation();
  const [sendingData, setSendingData] = useState(false);
  const [sendError, setSendError] = useState(false);
  const [externalAffiliate, setExternalAffiliate] = useState(false);

  const emptyAffiliate = {
    externalAffiliate: false,
    name: '',
    email: '',
    address: undefined,
    birthDate: undefined,
    partnerId: '',
  };

  async function sendData(values: typeof emptyAffiliate) {
    setSendingData(true);
    const [result, error] = await awaitUtil(postAffiliate({ ...values }));
    if (error) {
      setSendError(true);
      setSendingData(false);
    }
    if (result) {
      setSendingData(false);
      handleSave();
    }
  }

  const onClose = () => {
    setExternalAffiliate(false);
    setSendError(false);
    handleClose();
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth={true}>
        <DialogTitle>
          <Grid2 container>
            <Typography flexGrow={1} variant="h5" sx={{ fontWeight: 500 }}>
              {t('affiliate.add.modal.title')}
            </Typography>
            <IconButton
              onClick={() => {
                onClose();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid2>
        </DialogTitle>
        <Formik
          initialValues={emptyAffiliate}
          validationSchema={schema}
          onSubmit={async (values) => {
            await sendData(values);
          }}
        >
          {(props) => {
            return (
              <>
                <DialogContent>
                  <DialogContentText>{t('affiliate.add.modal.subTitle')}</DialogContentText>
                  <form onSubmit={props.handleSubmit}>
                    <Grid2 container rowSpacing={0} columnSpacing={3}>
                      <FormControlLabel
                        onChange={() => {
                          setExternalAffiliate(!externalAffiliate);
                          props.setFieldValue('address', null);
                          props.setFieldValue('birthDate', null);
                          props.setFieldValue('externalAffiliate', !externalAffiliate);
                        }}
                        control={<Switch defaultChecked />}
                        label={t('affiliate.add.modal.form.label.internalAffiliate')}
                        value={externalAffiliate}
                      />

                      <DefaultTextField
                        label={t('affiliate.add.modal.form.label.name')}
                        placeholder={t('affiliate.add.modal.form.label.name')}
                        field="name"
                        formik={props}
                      />

                      <DefaultTextField
                        label={t('affiliate.add.modal.form.label.email')}
                        placeholder={t('affiliate.add.modal.form.label.email')}
                        field="email"
                        formik={props}
                      />
                      <DefaultAutoCompleteField
                        options={partners.map((option) => option)}
                        label={'Partner'}
                        field={'partnerId'}
                        formik={props}
                      />
                      {externalAffiliate && (
                        <>
                          <div style={{ width: '100%', marginBottom: '16px' }}>
                            <MapsAutocomplete
                              label={t('affiliate.add.modal.form.label.address')}
                              field="address"
                              formik={props}
                              onAutocompleteSelected={(el) => {
                                props.setFieldValue('address', el.description);
                              }}
                            />
                          </div>
                          <NormalDatepicker label={t('affiliate.add.modal.form.label.birthDate')} field="birthDate" formik={props} />
                        </>
                      )}
                    </Grid2>
                  </form>
                  {sendError && <Alert severity="error">{t('affiliate.add.modal.error')}</Alert>}
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      onClose();
                    }}
                  >
                    {t('affiliate.add.modal.cancel')}
                  </Button>
                  <LoadingButton
                    variant="contained"
                    loading={sendingData}
                    onClick={props.submitForm}
                    disabled={Object.keys(props.errors).length !== 0}
                  >
                    {t('affiliate.add.modal.save')}
                  </LoadingButton>
                </DialogActions>
              </>
            );
          }}
        </Formik>
      </Dialog>
    </>
  );
};
