import { Grid } from '@mui/material';
import { useParams } from 'react-router';
import { useSupportCaseByID } from 'src/hooks/useSupportCaseByID';
import { UserDetail, UserTitle } from './UserDetailSite';
import { useState } from 'react';
import { SupportDetail, SupportMessages } from 'src/components/SupportDetail';
import { Actions } from 'src/components/Actions';

export const SupportDetailSite = () => {
  const params = useParams();
  const supportId = params.id;
  const [status, setStatus] = useState(false); // using it for forcing an update when we edited something in the support case or user
  const supportDetail = useSupportCaseByID({ id: supportId as string, forceUpdate: status });

  const user = supportDetail?.user;
  if (!supportDetail) return null;
  const supportCase = supportDetail;
  return (
    <Grid container>
      <UserTitle userData={user} />
      <Actions forceReload={() => setStatus(!status)} user={user} supportCase={supportCase} />
      <Grid item xs={5.5} style={{ borderRadius: '10px', padding: '10px', margin: '6px' }}>
        <Grid item style={{ backgroundColor: 'rgb(216, 227, 247)', borderRadius: '10px', padding: '10px', margin: '6px' }}>
          <SupportDetail supportCase={supportDetail} />
        </Grid>
        <Grid item style={{ backgroundColor: 'rgba(219, 242, 231, 0.5)', borderRadius: '10px', padding: '10px', margin: '6px' }}>
          <SupportMessages supportCase={supportDetail} />
        </Grid>
      </Grid>
      <Grid item xs={5.5}>
        <UserDetail userData={user} />
      </Grid>
    </Grid>
  );
};
