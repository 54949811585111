import { FC } from 'react';
import { Asset } from 'src/api/oApi';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { UserItem } from 'src/models/users';
import { CopyToClipboardButton } from 'src/components/ui/CopyToClipboardButton';
import { useWalletAssets } from 'src/hooks/useWalletAssets';
import { generalStore } from '../../stores/GeneralStore';

export const UserAssetComponent: FC<{
  assets: UserItem['assets'] | null;
  dbAssets: Asset[] | null;
  wallet: UserItem['wallet'] | null;
}> = ({ assets, dbAssets, wallet }) => {
  if (!assets) return null;
  if (!wallet) return null; // when user has assets then wallet must be defined
  const assetMap = new Map<string, { id: string; title: string; stage: string }>();
  if (dbAssets) {
    dbAssets.forEach((asset) => {
      assetMap.set(asset.id, { id: asset.id, title: asset.title, stage: asset.stage });
    });
  }
  const assetWallets = useWalletAssets(
    wallet,
    () => generalStore.setIsLoading(true),
    () => generalStore.setIsLoading(false),
  );
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>ID</TableCell>
          <TableCell>Title</TableCell>
          <TableCell>Stage</TableCell>
          <TableCell>Average Share Price</TableCell>
          <TableCell>Shares</TableCell>
          <TableCell>Transaction Amount</TableCell>
          <TableCell>Wallet</TableCell>
          {/* <TableCell>AssetBlockchainBalance</TableCell> */}
        </TableRow>
      </TableHead>
      <TableBody>
        {assets.map((asset) => {
          const assetWallet = assetWallets?.get(asset.id);
          const wallet = assetWallet?.wallet;
          // const balance = assetWallet?.balance;
          return (
            <TableRow key={asset._id}>
              <TableCell>{asset.id}</TableCell>
              <TableCell>{assetMap.get(asset.id)?.title}</TableCell>
              <TableCell>{assetMap.get(asset.id)?.stage}</TableCell>
              <TableCell>{asset.averageSharePrice}</TableCell>
              <TableCell>{asset.shares}</TableCell>
              <TableCell>{asset.transactionAmount}</TableCell>
              <TableCell>{wallet ? <CopyToClipboardButton text={wallet} label={wallet} shouldTruncate /> : null}</TableCell>
              {/* <TableCell>{balance ? <CopyToClipboardButton text={balance.toString()} label={balance.toString()} /> : null}</TableCell> */}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};
