import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import { FC, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { TextSearchField } from '../TextSearchField';

interface Props {
  open: boolean;
  top?: number;
  left?: number;
  onClose: () => void;
  userId: string;
  setUserId: (value: string) => void;
  setPage: any;
  status: any;
  setStatus: any;
  type: any;
  setType: any;
}

export const SupportFilterDialog: FC<Props> = ({ setUserId, setPage, setStatus, setType, type, status }) => {
  const [, setSearchParams] = useSearchParams();
  const [localUser, setLocalUser] = useState('');
  return (
    <>
      <Grid container spacing={1}>
        <Grid item>
          <FormControl variant="filled">
            <TextSearchField
              name="userId"
              setSearchParams={setSearchParams}
              setPage={setPage}
              label="userId"
              field={localUser}
              submit={() => setUserId(localUser)}
              setField={setLocalUser}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl variant="filled">
            <InputLabel id="type-filter-label">Type</InputLabel>
            <Select
              labelId="type-filter-label"
              id="type-filter"
              value={type}
              label="Type"
              onChange={(e) => setType(e.target.value)}
              // style={{ width: selectWidth }}
            >
              <MenuItem value={'All'}>All</MenuItem>
              <MenuItem value={'mangopayManualDocumentUpload'}>Mangopay Manual Document Upload</MenuItem>
              <MenuItem value={'identificationManualDocumentCheck'}>Identification Manual Document Check</MenuItem>
              <MenuItem value={'identificationPEP'}>Identification PEP</MenuItem>
              <MenuItem value={'identificationAddProofOfResidence'}>Identification Add Proof Of Residence</MenuItem>
              <MenuItem value={'mangopayFailedPayout'}>Mangopay Failed Payout</MenuItem>
              <MenuItem value={'notaryProcess'}>Notary Process</MenuItem>
              <MenuItem value={'addBonus'}>Add Bonus</MenuItem>
              <MenuItem value={'deleteUser'}>Delete User</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl variant="filled">
            <InputLabel id="status-filter-label">status</InputLabel>
            <Select
              labelId="status-filter-label"
              id="status-filter"
              value={status}
              label="status"
              onChange={(e) => setStatus(e.target.value)}
              // style={{ width: selectWidth }}
            >
              <MenuItem value={'All'}>All</MenuItem>
              <MenuItem value={'new'}>New</MenuItem>
              <MenuItem value={'open'}>Open</MenuItem>
              <MenuItem value={'waiting'}>Waiting</MenuItem>
              <MenuItem value={'closed'}>Closed</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};
