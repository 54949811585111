import { FC } from 'react';

export const SectionTitle: FC<{ children: any }> = ({ children }) => {
  return (
    <p
      style={{
        // borderRadius: '10px',
        textAlign: 'left',
        padding: '20px',
        fontFamily: 'aestetico-semibold',
        fontSize: '20px',
        color: '#1F3448',
      }}
    >
      {children}
    </p>
  );
};
