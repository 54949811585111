import { LoadingButton } from '@mui/lab';
import { changeFourthlineVerificationStatus, switchToIdentification } from 'src/api/oApi';
import { FourthLineIdentificationStatus } from 'src/sites/IdentificationSite';
import { generalStore } from 'src/stores/GeneralStore';

export const SwitchIdentificationButton = ({
  destinationType,
  userId,
  disabled,
  forceUpdateFunction,
}: {
  userId: string;
  destinationType: 'IDNow' | 'Fourthline';
  disabled?: boolean;
  forceUpdateFunction: () => void;
}) => {
  const switchMethod = async (userId: string, type: 'IDNow' | 'Fourthline') => {
    generalStore.setIsLoading(true);
    try {
      await switchToIdentification(userId, type);
      generalStore.setIsLoading(false);
      forceUpdateFunction();
    } catch (error: any) {
      console.error(error);
      generalStore.setError('Error In Fetching Identifications:' + error?.response?.data?.error);
      generalStore.setIsLoading(false);
    }
  };

  if (destinationType === 'Fourthline') {
    return (
      <LoadingButton
        disabled={disabled}
        style={{ margin: '10px', backgroundColor: 'plum' }}
        variant="contained"
        onClick={() => {
          switchMethod(userId, destinationType);
        }}
        loading={generalStore.isLoading}
      >
        Switch to Fourthline
      </LoadingButton>
    );
  } else if (destinationType === 'IDNow') {
    return (
      <LoadingButton
        style={{ margin: '10px', backgroundColor: 'coral' }}
        disabled={disabled}
        variant="contained"
        loading={generalStore.isLoading}
        onClick={() => {
          switchMethod(userId, destinationType);
        }}
      >
        Switch to IDNOW
      </LoadingButton>
    );
  } else {
    return null;
  }
};

export const SwitchIdentificationFourthlineStatusButton = ({
  userId,
  forceUpdateFunction,
}: {
  userId: string;
  forceUpdateFunction: () => void;
}) => {
  const switchStatusMethod = async () => {
    generalStore.setIsLoading(true);
    try {
      await changeFourthlineVerificationStatus({ userId, status: FourthLineIdentificationStatus.kycRequired });
      generalStore.setIsLoading(false);
      forceUpdateFunction();
    } catch (error: any) {
      console.error(error);
      generalStore.setError('Error In Changing Identification Status:' + error?.response?.data?.error);
      generalStore.setIsLoading(false);
    }
  };

  return (
    <LoadingButton
      style={{ margin: '10px', backgroundColor: 'cyan' }}
      key={'change-fourthline-status'}
      variant="contained"
      onClick={() => {
        switchStatusMethod();
      }}
      loading={generalStore.isLoading}
    >
      Status to {FourthLineIdentificationStatus.kycRequired}
    </LoadingButton>
  );
};
