/* eslint-disable @typescript-eslint/no-empty-function */
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { IconButton, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { FC, useState } from 'react';
import { string, object } from 'yup';
import { NormalDatepicker } from '../Datepicker';
import { CustomDialog } from '../mui-overrides/CustomDialog';
import { t } from 'i18next';
import { DefaultSelectField, DefaultTextField } from '../mui-overrides/Textfield';
import { LoadingButton } from '@mui/lab';
import { createBonusCode } from 'src/api/oApi';
import { useAppConfigs } from 'src/hooks/useAppconfigs';

interface Props {
  open: boolean;
  onClose: () => void;
  onCloseAndReload: () => void;
}

export const AddBonusCodeDialog: FC<Props> = ({ open, onClose, onCloseAndReload }) => {
  const [isLoading, setIsLoading] = useState(false);
  const configs = useAppConfigs();
  const partnerIdOptions = configs
    ? configs?.map((config) => {
        return {
          label: config.partnerShortName,
          value: config.partnerId,
        };
      })
    : [];
  const formik = useFormik({
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      code: '',
      bonusType: '',
      bonusAmount: '',
      initialCount: '',
      minimumNumberOfShares: '',
      minimumVolume: '',
      ownerUserId: '',
      assetId: '',
      bonusMessage: '',
      availableFrom: '',
      expiresAt: '',
      partnerId: '',
    },
    validationSchema: object({
      code: string().required('Code is required'),
      bonusType: string().required('Type is required'),
      initialCount: string().required('Initial count is required'),
      bonusAmount: string().required('Amount is required'),
      availableFrom: string().required('Available from is required'),
      expiresAt: string().required('Expires at is required'),
    }),
    onSubmit: async (values) => {
      // we must not return empty string to backend
      const data = JSON.parse(JSON.stringify(values));
      for (const key in data) {
        if (data[key] === '') {
          delete data[key];
        }
      }
      setIsLoading(true);
      try {
        await createBonusCode({
          code: data.code,
          bonusType: data.bonusType,
          bonusAmount: data.bonusAmount,
          initialCount: data.initialCount,
          minimumNumberOfShares: data.minimumNumberOfShares,
          minimumVolume: data.minimumVolume,
          ownerUserId: data.ownerUserId,
          assetId: data.assetId,
          bonusMessage: data.bonusMessage,
          availableFrom: data.availableFrom,
          partnerId: data.partnerId,
          expiresAt: data.expiresAt,
        });
        onCloseAndReload();
        formik.resetForm();
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    },
  });

  const onCloseAndReset = () => {
    formik.resetForm();
    onClose();
  };

  return (
    <CustomDialog open={open} onClose={onCloseAndReset}>
      <div
        style={{
          position: 'relative',
          textAlign: 'center',
          maxWidth: '600px',
        }}
      >
        <IconButton
          style={{
            position: 'absolute',
            right: -30,
            top: -30,
          }}
          onClick={onClose}
        >
          <CloseOutlinedIcon />
        </IconButton>

        <div>
          <Typography textAlign="left" component="h1" variant="h5" paddingBottom="20px">
            {t('addBonusCodeDialog.title')}
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <DefaultTextField field={'code'} formik={formik} label={t('addBonusCodeDialog.form.code')} variant="outlined" />
            <DefaultSelectField
              field={'bonusType'}
              formik={formik}
              label={t('addBonusCodeDialog.form.bonusType')}
              options={[
                {
                  label: 'Primary market',
                  value: 'primaryMarket',
                },
                {
                  label: 'Secondary market',
                  value: 'secondaryMarket',
                },
                {
                  label: 'Primary and secondary market',
                  value: 'primaryAndSecondary',
                },
              ]}
            />
            <DefaultTextField
              field={'bonusAmount'}
              type="number"
              formik={formik}
              label={t('addBonusCodeDialog.form.amount')}
              variant="outlined"
            />
            <DefaultTextField
              field={'initialCount'}
              type="number"
              formik={formik}
              label={t('addBonusCodeDialog.form.initialCount')}
              variant="outlined"
            />
            <DefaultTextField
              field={'minimumNumberOfShares'}
              formik={formik}
              type="number"
              label={t('addBonusCodeDialog.form.minimumNumberOfShares')}
              variant="outlined"
            />
            <DefaultTextField
              field={'minimumVolume'}
              formik={formik}
              type="number"
              label={t('addBonusCodeDialog.form.minimumVolume')}
              variant="outlined"
            />
            <DefaultTextField field={'ownerUserId'} formik={formik} label={t('addBonusCodeDialog.form.ownerUserId')} variant="outlined" />
            <DefaultTextField field={'assetId'} formik={formik} label={t('addBonusCodeDialog.form.assetId')} variant="outlined" />
            <DefaultSelectField
              field={'partnerId'}
              formik={formik}
              label={t('addBonusCodeDialog.form.partnerId')}
              options={partnerIdOptions}
            />
            <DefaultTextField field={'bonusMessage'} formik={formik} label={t('addBonusCodeDialog.form.bonusMessage')} variant="outlined" />
            <NormalDatepicker label={t('addBonusCodeDialog.form.availableFrom')} field="availableFrom" formik={formik} />
            <NormalDatepicker label={t('addBonusCodeDialog.form.expiresAt')} field="expiresAt" formik={formik} />

            <LoadingButton loading={isLoading} type="submit" size="large" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
              {t('addBonusCodeDialog.form.submit')}
            </LoadingButton>
          </form>
        </div>
      </div>
    </CustomDialog>
  );
};
