/**
 * Api for platform middleware.
 */

import axios from 'axios';
import { UserFieldsFilter } from 'src/components/dialogs/UserFilter';
import { BackendResponse } from 'src/models';
import { IAffiliate } from 'src/models/affiliate';
import { IAppConfig } from 'src/models/appconfig';
import { GetBonusResponse } from 'src/models/bonus';
import { GetBonusCodeResponse } from 'src/models/bonuscode';
import { TransactionsResponse } from 'src/models/transaction';
import { GetUserResponse, GetUsersResponse } from 'src/models/users';
import { REACT_APP_API_PLATFORM_URL } from 'src/util/config';

const api = axios.create({
  baseURL: REACT_APP_API_PLATFORM_URL,
  timeout: 100000,
  withCredentials: true,
});

export const getAppConfigs = () => {
  return api.get<BackendResponse<IAppConfig[]>>('/api/v1/appconfig');
};

export const getSupportCases = (params: { page?: number; status?: string; type?: string; userId?: string }) => {
  return api.get('/api/v1/support', {
    params,
  });
};

export function changeFourthlineVerificationStatus(params: { status: string; userId: string }) {
  return api.put(`/api/v1/identification/support/fourthLineVerificationStatus/${params.userId}/${params.status}`);
}

export function getSupportCasesById(id: string) {
  return api.get('/api/v1/support/caseDetail/' + id);
}

export const patchRegistrationData = (changeData: { userId: string; firstName?: string; lastName?: string; phone?: string }) => {
  return api.patch('/api/v1/user/registrationData', changeData);
};

export const getUserDocument = (userId: string) => {
  return api.get(`/api/v1/user/userDocument?userId=${userId}`);
};

export const postSupportCaseInformation = (body: { supportCaseId: string; message: string }) => {
  return api.post('/api/v1/support/supportCase/information', body);
};

export const postSolveManualCheckSupportCase = (body: { supportCaseId: string; message: string }) => {
  return api.post('/api/v1/support/supportCase/solveIdentificationManualCheck', body);
};

export const postMangopayUploadDocumentManually = (formData: FormData) => {
  return api.post('/api/v1/support/supportCase/mangopayUploadDocumentManually', formData);
};

export const postSolveFailedPayoutSupportCase = (body: { supportCaseId: string; message: string; resourceId: string }) => {
  return api.post('/api/v1/support/supportCase/solvedFailedMangopayPayout', body);
};

export const startRestoreProcess = (body: { userId: string }) => {
  return api.post('/api/v1/support/userRestore', body);
};

export const finishRestoreProcess = (body: { userId: string }) => {
  return api.post('/api/v1/support/userRestoreWallet', body);
};

export function getAffiliatePartners(params: { search?: string; page?: number }) {
  return api.get('/api/v1/affiliate', {
    params: {
      search: params?.search ? params.search : undefined,
      page: params?.page ? params.page : undefined,
    },
  });
}

export async function postAffiliate(affiliate: IAffiliate) {
  return api.post<BackendResponse<IAffiliate>>(`/api/v1/affiliate/${affiliate.partnerId}`, affiliate);
}

export function investedUsersReport({ partnerId, from, to }: { partnerId: string; from?: string; to?: string }) {
  return api.get(`api/v1/affiliate/invested-users/${partnerId}/csv`, {
    params: {
      from,
      to,
    },
  });
}

export function getAffiliatePartnersList(): Promise<BackendResponse<{ data: string[] }>> {
  return api.get('/api/v1/affiliate/partner/list');
}

export async function getPartners() {
  const res = await api.get('/api/v1/appconfig');
  return res.data.data;
}

type PartnerReportArg = { partnerId: string; from?: string | Date; to?: string | Date };
export const partnerReports = {
  transactions: ({ partnerId, from, to }: PartnerReportArg) => {
    return api.get('api/v1/transactions/reports/partner/', {
      params: {
        from,
        to,
        partnerId,
      },
    });
  },
  payins: ({ partnerId, from, to }: PartnerReportArg) => {
    return api.get('/api/v1/transactions/reports/partner/payin', {
      params: {
        from,
        to,
        partnerId,
      },
    });
  },
  assets: ({ partnerId, from, to }: PartnerReportArg) => {
    return api.get('/api/v1/transactions/reports/partner/partnerassets', {
      params: {
        from,
        to,
        partnerId,
      },
    });
  },
};

export async function getPotentialUsers({ offset = 0, limit = 20, email }: { offset?: number; limit?: number; email?: string }) {
  const res = await api.get('/api/v1/support/potentialUsers', {
    params: {
      offset,
      limit,
      email,
    },
  });
  return res.data.data;
}

export function commissionReport({
  partnerId,
  from,
  to,
  commission,
}: {
  partnerId: string;
  from?: string;
  to?: string;
  commission: number;
}) {
  return api.get(`api/v1/affiliate/commission/${partnerId}/csv`, {
    params: {
      from,
      to,
      commission,
    },
  });
}

export const getUsersWithQuery = ({
  email,
  firstName,
  lastName,
  page = 0,
  userId,
  wallet,
  phone,
  referredBy,
  affiliatedBy,
}: {
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  page?: number | null;
  userId?: string | null;
  phone?: string | null;
  wallet?: string | null;
  referredBy?: string | null;
  affiliatedBy?: string | null;
}) => {
  return api.get<BackendResponse<GetUsersResponse>>('/api/v1/user/search', {
    params: { email, firstName, lastName, page, userId, phone, wallet, referredBy, affiliatedBy },
  });
};

export const getUserWithQuery = (userId: string) => {
  return api.get<BackendResponse<GetUserResponse>>('/api/v1/user/search/' + userId);
};

export const getUserMessages = (userId: string, page = 0) => {
  return api.get<
    BackendResponse<
      {
        subject: string;
        ArrivedAt: string;
        Status: string;
        AttachmentCount: string;
        CampaignID: string;
      }[]
    >
  >(`/api/v1/user/search/${userId}/emails?page=${page}`);
};

export const getBonuses = (userId: string, page = 0) => {
  return api.get<BackendResponse<GetBonusResponse>>('/api/v1/bonus/', {
    params: { search: userId, page },
  });
};

export function getBonusCodes({ code, page = 0, expiresAt }: { code?: string; page?: number; expiresAt?: string }) {
  return api.get<BackendResponse<GetBonusCodeResponse>>('/api/v1/bonus/bonusCode', {
    params: { code, page, expiresAt },
  });
}

export function createBonusCode(bonusCode: any) {
  return api.post('/api/v1/bonus/bonusCode', bonusCode);
}

export function createBonus(bonus: { title: string; amount: number; userId: string }) {
  return api.post('/api/v1/support/userBonus', bonus);
}

export function changePayoutAccount(payoutInfo: { IBAN: string; userId: string }) {
  return api.post('/api/v1/support/userChangePaymentAccount', payoutInfo);
}

export function changeTaxID(body: { taxId: string; userId: string; country: string }) {
  return api.put('/api/v2/user/account/taxId', body);
}

export const getCSVReport = ({ registeredFrom, registeredTo }: { registeredFrom?: string; registeredTo?: string }) => {
  return api.get('/api/v1/user/report', {
    params: { registeredFrom, registeredTo },
  });
};

export function getIdentifications(params: { page: number } & Partial<UserFieldsFilter>) {
  return api.get('/api/v1/identification/support/users', {
    params,
  });
}

export function switchToIdentification(userId: string, type: 'IDNow' | 'Fourthline') {
  return api.put(`/api/v1/identification/support/identificationType/${userId}/${type}`);
}

export type Asset = {
  ISIN: string;
  address: string;
  apps: string[];
  availableBricks: number;
  availableBricksSecondary: number;
  bricks: number;
  campaignDate: string;
  center: { lat: number; lng: number };
  constructionStatus: string;
  createdAt: string;
  fullUrl: string;
  id: string;
  images: any[];
  investmentConditionsUrl: string;
  issuingSharePrice: number;
  likes: number;
  location: string;
  offers: any[];
  plannedFirstRentalIncomeDate: string;
  postalCode: string;
  propertyType: string;
  rentalPaymentDate: string;
  rentalReturn: number;
  securityType: string;
  sharePrice: number;
  stage: string;
  testing: false;
  title: string;
  updatedAt: string;
  url: string;
};

export function getAssets() {
  return api.get<{ data: Asset[] }>('/api/v1/assets/all');
}

export const getTransactions = ({
  page = 0,
  sort,
  dateMin,
  dateMax,
  type,
  method,
  status,
  userId,
}: {
  page?: number;
  sort?: TransactionSort;
  dateMin?: string;
  dateMax?: string;
  type?: TransactionType;
  method?: TransactionMethod;
  status?: TransactionStatus;
  userId?: any;
}): Promise<{ data: TransactionsResponse }> => {
  return api.get('/api/v1/transactions', {
    params: { page, sort, dateMin, dateMax, type, method, status, userId },
  });
};

export const getOffers = ({
  page = 0,
  offerId,
  assetId,
  firstParty,
  secondParty,
  status,
}: {
  page?: number | null;
  offerId?: string | null;
  assetId?: string | null;
  firstParty?: string | null;
  secondParty?: string | null;
  status?: OfferStatus | null;
}) => {
  return api.get('/api/v1/bulletinboard/offer/search', {
    params: { offerId, assetId, firstParty, secondParty, status, page },
  });
};

export const postRefund = (transactionId: string) => {
  return api.post(`/api/v1/payment/payin/refund/${transactionId}`);
};

export type OfferStatus = 'init' | 'new' | 'accepted' | 'closed' | 'deleted';

export type TransactionStatus = 'init' | 'pending' | 'charged' | 'reserved' | 'success' | 'error' | 'canceled';

export type TransactionMethod = 'transfer' | 'account' | 'creditCard';

export type TransactionType = 'buy' | 'sell' | 'charge' | 'payout' | 'rent';

export type TransactionSort = 'oldest' | 'newest';
