import Box from '@mui/material/Box';
import { Formik, Form } from 'formik';
import { observer } from 'mobx-react';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { LoginInput, LoginButton } from 'src/components/mui-overrides/Login';
import { ErrorMessage } from 'src/components/ui/ErrorMessage';
import { Colors } from 'src/components/utils/Theme';
import { routes } from 'src/router/AppRouter';
import { authStore } from 'src/stores/AuthStore';
import { generalStore } from 'src/stores/GeneralStore';
import { object, string } from 'yup';

const AuthLoginSite: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const validationSchema = object({
    email: string().email(t('login.email.validation.invalid')).required(t('login.email.validation.required')).trim(),
    password: string().min(8, t('login.password.validation.min')).required(t('login.password.validation.required')),
  });
  const [showError, setShowError] = useState(false);

  const initialValues = {
    email: '', // supportuser+staging@brickwise.at
    password: '', // Intersport
  };

  const handleSubmit = async (values: { email: string; password: string }) => {
    generalStore.setIsLoading(true);
    setShowError(false);
    try {
      await authStore.loginWithPassword(values.email, values.password);
      if (authStore.error) {
        setShowError(true);
      } else {
        navigate(routes.USER);
      }
    } catch (error) {
      generalStore.setError('Unknown error');
    }

    generalStore.setIsLoading(false);
  };

  return (
    <div
      style={{
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: showError ? '5px solid ' + Colors.SECONDARY_COLOR : '',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          flexGlow: 1,
          maxWidth: 350,
        }}
      >
        <img style={{ width: 170, marginBottom: 25 }} src="/logo.svg" alt="brickwise-logo" />

        <Formik enableReinitialize initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
          {(formik) => (
            <Form>
              <LoginInput
                {...formik.getFieldProps('email')}
                margin="normal"
                fullWidth
                id="email"
                label={t('login.email.label')}
                error={formik.touched.email && Boolean(formik.errors.email)}
                variant="filled"
                helperText={formik.errors.email && formik.touched.email ? formik.errors.email : ''}
              />
              <LoginInput
                {...formik.getFieldProps('password')}
                margin="normal"
                fullWidth
                label={t('login.password.label')}
                type="password"
                id="password"
                variant="filled"
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.errors.password && formik.touched.password ? formik.errors.password : ''}
              />
              <LoginButton size="large" type="submit" fullWidth variant="contained" style={{ marginTop: 16 }}>
                {t('login.button.label')}
              </LoginButton>
            </Form>
          )}
        </Formik>
        <ErrorMessage
          style={{
            visibility: showError ? 'visible' : 'hidden',
          }}
          message={t('login.error')}
        />
      </Box>
    </div>
  );
};

export default observer(AuthLoginSite);
