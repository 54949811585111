import { useEffect, useState } from 'react';
import { apiErrorHandling } from 'src/api/apierrorhandling';
import { getAffiliatePartners } from 'src/api/oApi';
import { GetPartnersResponse } from 'src/models/partners';
import { generalStore } from 'src/stores/GeneralStore';

export const useAffiliatePartners = ({ search, update, page }: { search?: string; update?: boolean; page?: number }) => {
  const [partners, setPartners] = useState<GetPartnersResponse | null>(null);
  useEffect(() => {
    const load = async () => {
      generalStore.setIsLoading(true);
      try {
        const response = await getAffiliatePartners({ search, page });
        const partnersData = response.data.data;
        setPartners(partnersData);
      } catch (error: any) {
        console.log(error);
        apiErrorHandling(error);
      } finally {
        generalStore.setIsLoading(false);
      }
    };
    load();
  }, [update, page]);
  return partners;
};
