import { FC } from 'react';
import { Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

export const EditButton: FC<{ onClick: () => void; disabled?: boolean }> = ({ onClick, disabled }) => {
  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      style={{
        float: 'right',
        backgroundColor: 'white',
        color: 'black',
        textTransform: 'none',
        fontSize: '15px',
        fontFamily: 'Aestetico-semibold',
      }}
    >
      <EditIcon />
      edit
    </Button>
  );
};
