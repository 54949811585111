import { Add } from '@mui/icons-material';
import { Button, TableCell, TableRow } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { commissionReport, investedUsersReport } from 'src/api/oApi';
import { AffiliateCommissionReportDialog } from 'src/components/Affiliate/AffiliateCommissionReportDialog';
import { AffiliateInvestedUsersDialog } from 'src/components/Affiliate/AffiliateInvestedUsersDialog';
import { TextSearchField } from 'src/components/TextSearchField';
import { AddAffiliate } from 'src/components/dialogs/AddAffiliate';
import { BrickwiseTable } from 'src/components/mui-overrides/BrickwiseTable';
import { downloadFile } from 'src/helpers/downloadFile';
import { useAffiliatePartners } from 'src/hooks/useAffiliatePartners';
import { useAffiliatePartnerList } from 'src/hooks/useAffiliatePartnerList';

export const AffiliateSite: FC = () => {
  const { t } = useTranslation();

  const [showInvestedUsersDialog, setShowInvestedUsersDialog] = useState(false);
  const [showCommissionDialog, setShowCommissionDialog] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState(searchParams.get('search') || '');
  const [page, setPage] = useState(Number(searchParams.get('page')) || 0);
  const [update, setUpdate] = useState(false);
  const partners = useAffiliatePartners({ search, update, page });
  const [currentPartner, setCurrentPartner] = useState<{ name: string; id: string } | null>(null);

  const [isAddAffiliateModalOpen, setIsAddAffiliateModalOpen] = useState<boolean>(false);
  const allPartners = useAffiliatePartnerList();
  const handleCloseModal = () => {
    setIsAddAffiliateModalOpen(false);
  };

  const handleOpenModal = () => {
    setIsAddAffiliateModalOpen(true);
  };

  const handleSaveModal = () => {
    setUpdate(!update);
    setIsAddAffiliateModalOpen(false);
  };

  return (
    <>
      <AddAffiliate open={isAddAffiliateModalOpen} handleClose={handleCloseModal} handleSave={handleSaveModal} partners={allPartners} />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '1rem',
        }}
      >
        <TextSearchField
          label="Search"
          name="search"
          setField={setSearch}
          field={search}
          setPage={setPage}
          setSearchParams={setSearchParams}
          key={'search'}
          submit={() => setUpdate(!update)}
        />
        <Button variant="contained" disableElevation color="primary" startIcon={<Add />} onClick={handleOpenModal}>
          {t('affiliate.overview.button.addAffiliate')}
        </Button>
      </div>
      <AffiliateInvestedUsersDialog
        title={currentPartner?.name + ' Invested Users Report'}
        open={showInvestedUsersDialog}
        onClose={() => setShowInvestedUsersDialog(false)}
        onDownload={async (from, to) => {
          try {
            const result = await investedUsersReport({
              partnerId: currentPartner?.id as string,
              from: from?.toISOString(),
              to: to?.toISOString(),
            });
            downloadFile(result.data, `partner_investedusers-${from?.toISOString()}-${to?.toISOString()}`);
            setShowCommissionDialog(false);
          } catch (error) {
            console.error(error);
            setShowCommissionDialog(false);
          }
        }}
      />
      <AffiliateCommissionReportDialog
        title={currentPartner?.name + ' Commission Report'}
        open={showCommissionDialog}
        onClose={() => setShowCommissionDialog(false)}
        onDownload={async (commission, from, to) => {
          try {
            const result = await commissionReport({
              partnerId: currentPartner?.id as string,
              from: from?.toISOString(),
              to: to?.toISOString(),
              commission,
            });
            downloadFile(result.data, `partner_comission-${from?.toISOString()}-${to?.toISOString()}`);
            setShowCommissionDialog(false);
          } catch (error) {
            console.error(error);
            setShowCommissionDialog(false);
          }
        }}
      />
      <BrickwiseTable
        hidePagination={false}
        rowsPerPage={10}
        length={partners?.count || 0}
        page={page}
        setPage={setPage}
        hideChangePageLengthChange={false}
        renderHeader={() => {
          return (
            <TableRow>
              <TableCell>{t('partners.table.partnerId')}</TableCell>
              <TableCell>{t('partners.table.name')}</TableCell>
              <TableCell>{t('partners.table.email')}</TableCell>
              <TableCell>{t('partners.table.affiliateLink')}</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          );
        }}
        renderRows={() => {
          return (
            <>
              {partners?.affiliates?.map((row, index) => {
                return (
                  <TableRow key={index} hover style={{ cursor: 'pointer', textDecoration: 'none' }}>
                    <TableCell>{row.partnerId}</TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.affiliateLink}</TableCell>
                    <TableCell>
                      <Button
                        color="inherit"
                        variant="outlined"
                        style={{ fontSize: '10px' }}
                        onClick={() => {
                          setShowInvestedUsersDialog(true);
                          setCurrentPartner({ name: row.name, id: row.partnerId });
                        }}
                      >
                        Invested Users Report
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button
                        color="inherit"
                        variant="outlined"
                        style={{ fontSize: '10px' }}
                        onClick={() => {
                          setShowCommissionDialog(true);
                          setCurrentPartner({ name: row.name, id: row.partnerId });
                        }}
                      >
                        Comission Report
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </>
          );
        }}
      />
    </>
  );
};
