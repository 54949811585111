export const downloadFile = (csvData: any, fileName: string) => {
  const url = window.URL.createObjectURL(new Blob([csvData]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName + '.csv');
  document.body.appendChild(link);
  link.click();
};

export async function downloadPDFDocument(documentRequest: any) {
  try {
    const linkSource = `data:application/pdf;base64,${documentRequest.data.data.data}`;
    const downloadLink = document.createElement('a');
    const fileName = 'user_auth_' + documentRequest.data.data.name;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  } catch (err) {
    console.log(err);
  }
}
