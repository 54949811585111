import { styled } from '@mui/material/styles';
import { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { BrickwiseDrawer } from 'src/components/ui/BrickwiseDrawer';
import { authStore } from 'src/stores/AuthStore';

const Container = styled('div')({
  display: 'flex',
  flexGrow: 1,
  flexDirection: 'column',
});

interface Props {
  isAuthenticated: boolean;
  scopes: string[];
}

export const PrivateGuardRoute: FC<Props> = ({ isAuthenticated, scopes }) => {
  return isAuthenticated && scopes.some((scope) => authStore.role.includes(scope)) ? (
    <Container>
      <BrickwiseDrawer>
        <Outlet />
      </BrickwiseDrawer>
    </Container>
  ) : (
    <Navigate to="/" />
  );
};
