import { Dispatch, SetStateAction, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export const setStateQueryParameter = <T>(
  initialValue: T,
  fieldName: string,
  type: 'string' | 'number',
): [T, Dispatch<SetStateAction<T>>] => {
  const [searchParams, setSearchParams] = useSearchParams();
  const initalValue = searchParams.get(fieldName)
    ? type === 'string'
      ? searchParams.get(fieldName)
      : Number(searchParams.get(fieldName))
    : initialValue;
  const [field, setField] = useState(initalValue);

  const setFunction = (value: T) => {
    const params = new URLSearchParams(window.location.search);
    params.delete(fieldName);
    if (value) {
      params.append(fieldName, value as string);
    }
    setSearchParams(params);
    if (type === 'number') setField(Number(value));
  };
  return [field as T, setFunction as Dispatch<SetStateAction<T>>];
};
