import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { LoadingButton } from '@mui/lab';
import { TextField } from '@mui/material';
import { postMangopayUploadDocumentManually } from 'src/api/oApi';
import { generalStore } from 'src/stores/GeneralStore';
import { SupportCaseByIdResponse } from 'src/models/supportcaseById';

// eslint-disable-next-line @typescript-eslint/naming-convention
export default function SolveCaseManualUploadDialog({
  open,
  setOpen,
  supportCase,
  forceUpdate,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  supportCase?: SupportCaseByIdResponse | null;
  forceUpdate?: any;
}) {
  if (!supportCase) return null;
  const handleClose = () => {
    setOpen(false);
  };

  const [message, setMessage] = React.useState<string | null>(null);

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">Manual Document Upload Fall lösen</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <div>
            Lade ein neues Dokument hoch um diesen Fall abzuschließen. Bei einem Personalausweis muss jede Seite auf einer eigenen Seite (im
            PDF) sein. Die Date sollte höchstens 1-2 MB haben.
          </div>
          <form id="uploaddocumentform" method="post" action="POST">
            <TextField
              fullWidth
              sx={{ m: 1 }}
              placeholder="Message"
              type="text"
              name="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            <TextField style={{ display: 'none' }} name="supportCaseId" type={'hidden'} value={supportCase.id} />
            <TextField fullWidth sx={{ m: 1 }} type="file" name="file" />
          </form>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          onClick={async () => {
            try {
              const form = document.getElementById('uploaddocumentform');
              const formData = new FormData(form as any);
              console.log(formData);
              await postMangopayUploadDocumentManually(formData);
              generalStore.setMessage('Successfully Created the Message');
              setOpen(false);
              setMessage(null);
              if (forceUpdate) forceUpdate();
            } catch (error: any) {
              generalStore.setError(
                'error:' + JSON.stringify(error?.response?.error || error?.response?.data?.error || JSON.stringify(error)),
              );
            }
          }}
          fullWidth
          sx={{ m: 1 }}
          color="primary"
          variant="contained"
        >
          Hochladen
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
