import { useEffect, useState } from 'react';
import { apiErrorHandling } from 'src/api/apierrorhandling';
import { getWalletAssets } from 'src/api/blockchainApi';
import { UserItem } from '../models/users';

interface AssetWalletMap {
  key: string;
  value: { wallet: string; tokenId: string; balance: number; description: string };
}

/**
 * Hook to get all assets for a user.
 * @description Fetches all assets for all wallets (incl. old ones) from the blockchain.
 */
export const useWalletAssets = (wallet: UserItem['wallet'], onLoadStart?: () => void, onLoadEnd?: () => void) => {
  const [walletAssets, setWalletAssets] = useState<Map<AssetWalletMap['key'], AssetWalletMap['value']> | null>(null);
  const oldWallets = wallet.oldWallets.map((oldWallet) => oldWallet.address);
  const wallets = [wallet.address, ...oldWallets].filter((wallet) => !!wallet) as string[];
  useEffect(() => {
    let canceled = false;
    (async () => {
      let failedWalletsCounter = 0;
      onLoadStart?.();
      if (!canceled) {
        const assetWalletMap = new Map<AssetWalletMap['key'], AssetWalletMap['value']>();
        for (const wallet of wallets) {
          try {
            const assets = await getWalletAssets(wallet);
            if (assets.length) {
              assets.forEach(({ assetId, balance, description, tokenId }) => {
                assetWalletMap.set(assetId, { wallet, balance, description, tokenId });
              });
            }
          } catch (err) {
            failedWalletsCounter++;
          }
        }
        setWalletAssets(assetWalletMap);
      }
      if (failedWalletsCounter) {
        const countText = failedWalletsCounter === wallets.length ? 'all' : failedWalletsCounter;
        apiErrorHandling(new Error(), `Failed to fetch assets for ${countText} (old) wallet${countText > 1 ? 's' : ''}.`);
      }
      onLoadEnd?.();
    })();
    return () => {
      canceled = true;
      onLoadEnd?.();
    };
  }, []);
  return walletAssets;
};
