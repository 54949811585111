import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { useState, FC } from 'react';
import { partnerReports } from 'src/api/oApi';
import { downloadFile } from 'src/helpers/downloadFile';
import { generalStore } from 'src/stores/GeneralStore';
interface Props {
  open: boolean;
  onClose: () => void;
  title?: string;
  partnerId: string;
}

export const PartnerReports: FC<Props> = ({ open, onClose, title, partnerId }) => {
  const [from, setFrom] = useState<Date | null>(new Date());
  const [to, setTo] = useState<Date | null>(new Date());

  const handleFromChange = (newValue: Date | null) => {
    setFrom(newValue);
  };

  const handleToChange = (newValue: Date | null) => {
    setTo(newValue);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title || 'Partner Report'}</DialogTitle>
      <DialogContent style={{ padding: '15px' }} className="">
        <MobileDatePicker label="From" value={from} onChange={handleFromChange} renderInput={(params) => <TextField {...params} />} />
        <MobileDatePicker label="To" value={to} onChange={handleToChange} renderInput={(params) => <TextField {...params} />} />
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={onClose}>
          Cancel
        </Button>
        <Button
          color="inherit"
          onClick={async () => {
            generalStore.setIsLoading(true);
            const response = await partnerReports.transactions({ from: from as any, to: to as any, partnerId });
            downloadFile(response.data, `${partnerId}-transactions.csv`);
            generalStore.setIsLoading(false);
          }}
          variant="contained"
          disableElevation
        >
          Transactions
        </Button>
        <Button
          color="inherit"
          onClick={async () => {
            generalStore.setIsLoading(true);
            const response = await partnerReports.payins({ from: from as any, to: to as any, partnerId });
            downloadFile(response.data, `${partnerId}-payins.csv`);
            generalStore.setIsLoading(false);
          }}
          variant="contained"
          disableElevation
        >
          Pay Ins
        </Button>
        <Button
          color="inherit"
          onClick={async () => {
            generalStore.setIsLoading(true);
            const response = await partnerReports.assets({ from: from as any, to: to as any, partnerId });
            downloadFile(response.data, `${partnerId}-assets.csv`);
            generalStore.setIsLoading(false);
          }}
          variant="contained"
          disableElevation
        >
          Assets
        </Button>
      </DialogActions>
    </Dialog>
  );
};
