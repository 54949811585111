import { Snackbar } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useState } from 'react';

export const CopyToClipboardButton = ({ text, label, shouldTruncate }: { text: string; label?: string; shouldTruncate?: boolean }) => {
  const [open, setOpen] = useState(false);
  const handleClick = (text: string) => {
    setOpen(true);
    const copyTextToClipboard = async () => {
      if ('clipboard' in navigator) {
        await navigator.clipboard.writeText(text);
      } else {
        return document.execCommand('copy', true, text);
      }
    };
    copyTextToClipboard();
  };

  return (
    <>
      <button
        style={{
          backgroundColor: 'unset',
          border: 'none',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
        }}
        onClick={() => handleClick(text)}
      >
        <ContentCopyIcon color="primary" style={{ position: 'relative' }} />
        {label ? (
          <span className={shouldTruncate ? 'truncate' : ''} style={{ position: 'relative' }}>
            {label}
          </span>
        ) : (
          ''
        )}
      </button>
      <Snackbar open={open} onClose={() => setOpen(false)} autoHideDuration={2000} message={text + ' Copied'} />
    </>
  );
};
