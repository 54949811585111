import { FC, useState } from 'react';
import PersonIcon from '@mui/icons-material/PersonOutline';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import ReactJson from 'react-json-view';
import { Alert, Grid, Table, TableBody, TableCell, TableRow } from '@mui/material';
import { UserItem } from 'src/models/users';
import { affiliateShowerButton, referralShower } from './UserSite';
import { LinkTableCell } from 'src/components/mui-overrides/TableCell';
import { useUser } from 'src/hooks/useUser';
import { CopyToClipboardButton } from 'src/components/ui/CopyToClipboardButton';
import Tabs from 'src/components/Tab';
import { OfferList } from './OfferSite';
import { TransactionSite } from './TransactionSite';
import { useAssets } from 'src/hooks/useAssets';
import { UserAssetComponent } from '../components/user/UserAssetComponent';
import { UserMarketingComponent } from '../components/user/UserMarketingComponent';
import { PayoutAccountComponent } from '../components/PayoutAccountComponent';
import { Actions } from '../components/Actions';
import { findCountry } from 'src/helpers/countries';
import { IdentificationComponent } from '../components/IdentificationComponent';
import { SectionTitle } from '../components/SectionTitle';
import { TaxButtonComponent } from 'src/components/user/TaxDialog';
import { EditDataButtonComponent } from 'src/components/user/UserDataEditorDialog';
import { Asset } from 'src/api/oApi';
import { UserEmailComponent } from 'src/components/user/UserEmailComponent';
import { UserBonusComponent } from 'src/components/user/UserBonusComponent';
import { UserBlockchain } from '../components/user/blockchain/UserBlockchain';
import { routes } from 'src/router/AppRouter';

export const xsSize = 12;
// const buttonXS = 4;
export const UserDetailSite: FC<{ userId?: string; userDetails?: UserItem }> = ({ userId, userDetails }) => {
  const params = useParams<{ id: string }>();
  const [forceReload, setForceReload] = useState(false);
  const id = userId || params.id;
  // const navigate = useNavigate();
  const user = userDetails ? { user: userDetails } : useUser(id as string, forceReload);
  const userData = user?.user;
  if (!userData) return <Alert severity="error">No Data Available For User</Alert>; // we cannot show anything when user is undefined
  return (
    <>
      <UserTitle userData={userData} />
      <Actions user={userData} forceReload={() => setForceReload(!forceReload)} />
      <UserDetail userData={userData} forceReload={() => setForceReload(!forceReload)} />
    </>
  );
};

export const sectionStyle = {
  borderRadius: '10px',
  padding: '5px',
  margin: '4px',
};
export const cellStyle = {
  fontFamily: 'aestetico-semibold',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '50px',
} as any;
export const cellStyleValue = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '50px',
} as any;

export const UserDetail = ({ userData, style, forceReload }: { userData?: UserItem | null; style?: any; forceReload?: any }) => {
  if (!userData) return <>User Data Not Provided</>;
  const dbAssets = useAssets();

  return (
    <Tabs
      key={userData.userId}
      parameterPrefix="userDetail"
      tabs={[
        { label: 'User', component: <UserComponent style={style} userData={userData} forceReload={forceReload} /> },
        { label: 'Offers', component: <OfferComponent userData={userData} dbAssets={dbAssets} /> },
        { label: 'Transactions', component: <TransactionSite userIdValue={userData.userId} /> },
        { label: 'Assets', component: <UserAssetComponent assets={userData.assets} dbAssets={dbAssets} wallet={userData.wallet} /> },
        { label: 'Emails', component: <UserEmailComponent userId={userData.userId} /> },
        { label: 'Bonus', component: <UserBonusComponent userId={userData.userId} /> },
        {
          label: 'Marketing',
          component: <UserMarketingComponent marketingData={userData.marketingData} marketingNewsletter={userData.marketingNewsletter} />,
        },
        { label: 'Identification', component: <IdentificationComponent userId={userData.userId} /> },
        { label: 'Blockchain', component: <UserBlockchain wallet={userData.wallet} /> },
      ]}
    />
  );
};

export const UserTitle: FC<{ userData: UserItem }> = ({ userData }) => {
  if (!userData) return null;
  return (
    <p style={{ fontSize: '30px', marginBottom: '20px', fontFamily: 'aestetico-semibold', display: 'flex', justifyContent: 'left' }}>
      <PersonIcon style={{ fontSize: '130%' }} />
      {userData.firstName || userData.lastName ? `${userData.firstName} ${userData.lastName}` : userData.userId}
    </p>
  );
};

const linkUrl = (userId: string): string => {
  return routes.USERDETAIL.replace(':id', userId);
};

const UserComponent: FC<{ userData: UserItem; forceReload: any; style?: any }> = ({ userData, forceReload, style = {} }) => {
  const { t } = useTranslation();
  console.log('User Component: render');
  return (
    <Grid container sx={{ flexGrow: 1 }} style={{ ...style }}>
      <Grid item xs={xsSize} style={{ backgroundColor: 'rgba(193, 197, 205, 0.5)', ...sectionStyle }}>
        <Table>
          <TableBody>
            <TableRow hover={true} key={'userId'}>
              <TableCell style={cellStyle}>{t('users.table.userId')}</TableCell>
              <TableCell style={cellStyleValue}>
                <CopyToClipboardButton text={userData.userId} label={userData.userId}></CopyToClipboardButton>
              </TableCell>
            </TableRow>
            <TableRow hover={true} key={'firstName'}>
              <TableCell style={cellStyle}>{t('users.table.firstName')}</TableCell>
              <TableCell style={cellStyleValue}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  {userData.firstName}
                  <EditDataButtonComponent userData={userData} />
                </div>
              </TableCell>
            </TableRow>
            <TableRow hover={true} key={'lastName'}>
              <TableCell style={cellStyle}>{t('users.table.lastName')}</TableCell>
              <TableCell style={cellStyleValue}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  {userData.lastName}
                  <EditDataButtonComponent userData={userData} />
                </div>
              </TableCell>
            </TableRow>
            <TableRow hover={true} key={'Status'}>
              <TableCell style={cellStyle}>Status</TableCell>
              <TableCell style={cellStyleValue}>{userData.status}</TableCell>
            </TableRow>
            <TableRow hover={true} key={'Security'}>
              <TableCell style={cellStyle}>Security App Status</TableCell>
              <TableCell style={cellStyleValue}>{userData.securityApp?.status}</TableCell>
            </TableRow>
            <TableRow hover={true} key={'Partner'}>
              <TableCell style={cellStyle}>Partner</TableCell>
              <TableCell style={cellStyleValue}>{userData.marketingData?.partnerId}</TableCell>
            </TableRow>
            <TableRow hover={true} key={'email'}>
              <TableCell style={cellStyle}>{t('users.table.email')}</TableCell>
              <TableCell style={cellStyleValue}>
                {userData.email ? <CopyToClipboardButton text={userData.email} label={userData.email}></CopyToClipboardButton> : null}
              </TableCell>
            </TableRow>
            <TableRow hover={true} key={'phone'}>
              <TableCell style={cellStyle}>{t('users.table.phone')}</TableCell>
              <TableCell style={cellStyleValue}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  {userData.phone ? <CopyToClipboardButton text={userData.phone} label={userData.phone}></CopyToClipboardButton> : null}
                  <EditDataButtonComponent userData={userData} />
                </div>
              </TableCell>
            </TableRow>
            <TableRow hover={true} key={'ReferredBy'}>
              <TableCell style={cellStyle}>ReferredBy</TableCell>
              {userData.referredBy?.userId ? (
                <LinkTableCell to={linkUrl(userData.referredBy.userId)}>{referralShower(userData)}</LinkTableCell>
              ) : null}
            </TableRow>
            <TableRow hover={true} key={'AffiliatedBy'}>
              <TableCell style={cellStyle}>Affiliated By</TableCell>
              {userData.referredBy?.userId ? (
                <LinkTableCell to={routes.AFFILIATE + `?search=${userData.affiliatedBy?.affiliateId}`}>
                  {affiliateShowerButton(userData)}
                </LinkTableCell>
              ) : null}
            </TableRow>
            <TableRow hover={true}>
              <TableCell style={cellStyle}>Knowledge And Exprience</TableCell>
              <TableCell style={cellStyleValue}>{userData.complianceInformation.knowledgeAndExperienceStatus}</TableCell>
            </TableRow>
            <TableRow hover={true}>
              <TableCell style={cellStyle}>PEP</TableCell>
              <TableCell style={cellStyleValue}>{userData.complianceInformation.PEP ? 'YES' : 'NO'}</TableCell>
            </TableRow>
            <TableRow hover={true}>
              <TableCell style={cellStyle}>Marketing Newsletter</TableCell>
              <TableCell style={cellStyleValue}>{userData.marketingNewsletter ? 'YES' : 'NO'}</TableCell>
            </TableRow>
            <TableRow style={{ verticalAlign: 'baseline' }} hover={true}>
              <TableCell style={cellStyle}>TaxIDs</TableCell>
              <TableCell style={{ ...cellStyleValue }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  {userData.account.taxIds?.map((taxId) => (
                    <span
                      style={{
                        borderRadius: '4px',
                        borderStyle: 'solid',
                        padding: '2px',
                        margin: '2px',
                        borderWidth: '1px',
                      }}
                    >
                      {findCountry(taxId.country)}:{taxId.taxId}
                    </span>
                  ))}
                  <div style={{ flex: 1 }}>
                    <TaxButtonComponent user={userData} forceReload={forceReload} />
                  </div>
                </div>
              </TableCell>
            </TableRow>
            <TableRow hover={true}>
              <TableCell style={cellStyle}>Created At</TableCell>
              <TableCell style={cellStyleValue}>{userData.createdAt as unknown as string}</TableCell>
            </TableRow>
            <TableRow hover={true}>
              <TableCell style={cellStyle}>Updated At</TableCell>
              <TableCell style={cellStyleValue}>{userData.updatedAt as unknown as string}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
      <Grid item xs={xsSize} style={{ backgroundColor: 'rgba(193, 244, 244, 0.5)', ...sectionStyle }}>
        <SectionTitle>Personal Information</SectionTitle>
        <Table>
          <TableBody>
            <TableRow hover={true}>
              <TableCell style={cellStyle}>City</TableCell>
              <TableCell style={cellStyleValue}>{userData.personalInformation?.city}</TableCell>
            </TableRow>
            <TableRow hover={true}>
              <TableCell style={cellStyle}>Postal Code</TableCell>
              <TableCell style={cellStyleValue}>{userData.personalInformation?.PLZ}</TableCell>
            </TableRow>
            <TableRow hover={true}>
              <TableCell style={cellStyle}>Street</TableCell>
              <TableCell style={cellStyleValue}>{userData.personalInformation?.street}</TableCell>
            </TableRow>
            <TableRow hover={true}>
              <TableCell style={cellStyle}>Birth Date</TableCell>
              <TableCell style={cellStyleValue}>
                {userData.personalInformation?.birthDate ? (
                  <CopyToClipboardButton
                    text={userData.personalInformation?.birthDate?.replaceAll('/', '.')}
                    label={userData.personalInformation?.birthDate?.replaceAll('/', '.')}
                  ></CopyToClipboardButton>
                ) : null}
              </TableCell>
            </TableRow>
            <TableRow hover={true}>
              <TableCell style={cellStyle}>Nationality</TableCell>
              <TableCell style={cellStyleValue}>{userData.personalInformation?.nationality}</TableCell>
            </TableRow>
            <TableRow hover={true}>
              <TableCell style={cellStyle}>Domicile</TableCell>
              <TableCell style={cellStyleValue}>{userData.personalInformation?.domicile}</TableCell>
            </TableRow>
            <TableRow hover={true}>
              <TableCell style={cellStyle}>Birth City</TableCell>
              <TableCell style={cellStyleValue}>{userData.personalInformation?.birthCity}</TableCell>
            </TableRow>
            <TableRow hover={true}>
              <TableCell style={cellStyle}>Birth Country</TableCell>
              <TableCell style={cellStyleValue}>{userData.personalInformation?.birthCountry}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
      <Grid item xs={xsSize} style={{ backgroundColor: 'rgba(239, 213, 243, 0.8)', ...sectionStyle }}>
        <SectionTitle>Payment</SectionTitle>
        <Table>
          <TableBody>
            <TableRow hover={true}>
              <TableCell width={'50%'} style={cellStyle}>
                IBAN
              </TableCell>
              <TableCell width={'50%'} style={cellStyleValue}>
                {userData.account?.IBAN ? (
                  <CopyToClipboardButton text={userData.account.IBAN} label={userData.account?.IBAN}></CopyToClipboardButton>
                ) : null}
              </TableCell>
            </TableRow>
            <TableRow hover={true}>
              <TableCell style={cellStyle}>Account Amount</TableCell>
              <TableCell style={cellStyleValue}>{userData.account?.amount}</TableCell>
            </TableRow>
            <TableRow hover={true}>
              <TableCell style={cellStyle}>Wallet Address</TableCell>
              <TableCell width={'50%'} style={cellStyleValue}>
                {userData.wallet.address ? (
                  <CopyToClipboardButton text={userData.wallet.address} label={userData.wallet.address}></CopyToClipboardButton>
                ) : null}
              </TableCell>
            </TableRow>
            <TableRow hover={true}>
              <TableCell style={cellStyle}>Payout Accounts</TableCell>
              <TableCell style={cellStyleValue}>
                <PayoutAccountComponent accounts={userData.payoutAccounts} />
              </TableCell>
            </TableRow>
            <TableRow hover={true}>
              <TableCell style={cellStyle}>Mangopay ID of Payment Provider</TableCell>
              <TableCell width={'50%'} style={cellStyleValue}>
                {userData.paymentProvider?.mangopayId ? (
                  <CopyToClipboardButton
                    text={userData.paymentProvider.mangopayId}
                    label={userData.paymentProvider.mangopayId}
                  ></CopyToClipboardButton>
                ) : null}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>

      <Grid item xs={xsSize * 2} style={{ backgroundColor: 'white', ...sectionStyle }}>
        <SectionTitle>JSON</SectionTitle>
        <ReactJson collapsed src={userData as object} />
      </Grid>
    </Grid>
  );
};

const OfferComponent: FC<{ userData: UserItem; dbAssets: Asset[] | null }> = ({ userData, dbAssets }) => {
  const [page, setPage] = useState(0);
  const offers = userData.offers.map((offer) => {
    return {
      ...offer,
      assetStage: dbAssets?.find((asset) => asset.id === offer.assetId)?.stage,
      assetTitle: dbAssets?.find((asset) => asset.id === offer.assetId)?.title,
    };
  });
  return (
    <Grid item xs={xsSize} style={{ ...sectionStyle }}>
      <OfferList
        page={page}
        setPage={setPage}
        offers={{ count: userData.offers.length, offers }}
        setOfferDetail={() => {
          console.log('set');
        }}
      />
    </Grid>
  );
};
