import { AxiosError } from 'axios';
// import { authStore } from 'src/stores/AuthStore';
import { generalStore } from 'src/stores/GeneralStore';

export const apiErrorHandling = (error: unknown, customText?: string) => {
  if (error instanceof AxiosError) {
    generalStore.setError('API Error: ' + error.response?.status);

    if (error.response?.status === 401) {
      // logout
      // authStore.logout();
    }
  } else {
    generalStore.setError(customText || 'Unknown Error');
  }
};
