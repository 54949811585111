import { TableCell, TableRow } from '@mui/material';
import { BrickwiseTable } from 'src/components/mui-overrides/BrickwiseTable';
import { Asset } from 'src/api/oApi';
import { useAssets } from 'src/hooks/useAssets';
import { CopyToClipboardButton } from 'src/components/ui/CopyToClipboardButton';

export const AssetSite = () => {
  const assets = useAssets();
  if (!assets) {
    return <></>;
  }
  return (
    <>
      <AssetList assets={assets} />
    </>
  );
};
const AssetList = ({ assets }: { assets: Asset[] }) => {
  return (
    <BrickwiseTable
      hidePagination={false}
      hideChangePageLengthChange={false}
      length={assets?.length}
      renderHeader={() => {
        return (
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Title</TableCell>
            <TableCell>Stage</TableCell>
            <TableCell>Address</TableCell>
            <TableCell>Available Bricks</TableCell>
            <TableCell>ISIN</TableCell>
            <TableCell>Issuing Share Price</TableCell>
            <TableCell>Location</TableCell>
          </TableRow>
        );
      }}
      renderRows={() => {
        return (
          <>
            {assets?.map((row, index: number) => {
              return (
                <TableRow key={index} hover>
                  <TableCell>
                    <CopyToClipboardButton label={row.id} text={row.id} />
                  </TableCell>
                  <TableCell>{row.title}</TableCell>
                  <TableCell>{row.stage}</TableCell>
                  <TableCell>{row.address}</TableCell>
                  <TableCell>{row.availableBricks}</TableCell>
                  <TableCell>{row.ISIN}</TableCell>
                  <TableCell>{row.issuingSharePrice}</TableCell>
                  <TableCell>{row.location}</TableCell>
                </TableRow>
              );
            })}
          </>
        );
      }}
    />
  );
};
