import { observer } from 'mobx-react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ErrorToast, MessageToast } from 'src/components/ui/ErrorToast';
import { LoadingOverlay } from 'src/components/ui/LoadingOverlay';
import { UserRoles } from 'src/models/enum';
import { AffiliateSite } from 'src/sites/AffiliateSite';
import AuthLoginSite from 'src/sites/AuthLoginSite';
import { BonusCodeSite } from 'src/sites/BonusCodeSite';
import { IdentificationSite } from 'src/sites/IdentificationSite';
import { NotFoundSite } from 'src/sites/NotFoundSite';
import { OfferSite } from 'src/sites/OfferSite';
import { QuestionsSite } from 'src/sites/QuestionsSite';
import { SupportDetailSite } from 'src/sites/SupportDetailSite';
import { SupportSite } from 'src/sites/SupportSite';
import { TransactionSite } from 'src/sites/TransactionSite';
import { UserDetailSite } from 'src/sites/UserDetailSite';
import { UserSite } from 'src/sites/UserSite';
import { authStore } from 'src/stores/AuthStore';
import { generalStore } from 'src/stores/GeneralStore';
import { NotAuthRoute } from './NotAuthRoute';
import { PrivateGuardRoute } from './PrivateGuardRoute';
import { AssetSite } from 'src/sites/AssetSite';
import { PartnerSite } from 'src/sites/PartnersSite';
import { PotentialUsersSite } from 'src/sites/PotentialUsersSite';

export const routes = {
  ROOT: '/',
  USER: '/user',
  USERDETAIL: '/user/:id',
  OFFERS: '/offers',
  TRANSACTIONS: '/transactions',
  SUPPORT: '/support',
  SUPPORTDETAILS: '/support/:id',
  BONUSCODE: '/bonuscode',
  AFFILIATE: '/affiliate',
  IDENTIFICATION: '/identification',
  QUESTIONS: '/questions',
  ASSETS: '/assets',
  PARTNERS: '/partners',
  POTENTIALUSERS: '/potentialusers',
};

export const AppRouter = observer(() => {
  if (!authStore.isRehydrated) {
    return <LoadingOverlay />;
  }

  return (
    <>
      <Router>
        <Routes>
          <Route element={<NotAuthRoute isAuthenticated={authStore.isAuthenticated} />}>
            <Route path={routes.ROOT} element={<AuthLoginSite />} />
          </Route>
          <Route
            element={
              <PrivateGuardRoute isAuthenticated={authStore.isAuthenticated} scopes={[UserRoles.SupportUser, UserRoles.ComplianceUser]} />
            }
          >
            <Route path={routes.USER} element={<UserSite />} />
            <Route path={routes.USERDETAIL} element={<UserDetailSite />} />
            <Route path={routes.OFFERS} element={<OfferSite />} />
            <Route path={routes.SUPPORT} element={<SupportSite />} />
            <Route path={routes.SUPPORTDETAILS} element={<SupportDetailSite />} />
            <Route path={routes.BONUSCODE} element={<BonusCodeSite />} />
            <Route path={routes.AFFILIATE} element={<AffiliateSite />} />
            <Route path={routes.IDENTIFICATION} element={<IdentificationSite />} />
            <Route path={routes.TRANSACTIONS} element={<TransactionSite />} />
            <Route path={routes.QUESTIONS} element={<QuestionsSite />} />
            <Route path={routes.ASSETS} element={<AssetSite />} />
            <Route path={routes.PARTNERS} element={<PartnerSite />} />
            <Route path={routes.POTENTIALUSERS} element={<PotentialUsersSite />} />
          </Route>

          <Route path="*" element={<NotFoundSite />} />
        </Routes>
      </Router>
      {generalStore.isLoading && <LoadingOverlay delayMs={300} />}
      <ErrorToast />
      <MessageToast />
    </>
  );
});
