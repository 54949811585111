import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { LoadingButton } from '@mui/lab';
import { TextField } from '@mui/material';
import { patchRegistrationData } from 'src/api/oApi';
import { generalStore } from 'src/stores/GeneralStore';
import { UserItem } from 'src/models/users';
import { EditButton } from '../EditButton';

// eslint-disable-next-line @typescript-eslint/naming-convention
export default function UserDataDialog({
  open,
  setOpen,
  userData,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  userData: {
    firstName: string;
    lastName: string;
    phone: string;
    userId: string;
  };
}) {
  const handleClose = () => {
    setOpen(false);
  };
  const [firstName, setFirstName] = React.useState(userData?.firstName);
  const [lastName, setLastName] = React.useState(userData?.lastName);
  const [phone, setPhone] = React.useState(userData?.phone);
  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{'Edit User Data'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <TextField
              fullWidth
              sx={{ m: 1 }}
              placeholder="First Name"
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <TextField
              fullWidth
              sx={{ m: 1 }}
              placeholder="Last Name"
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />

            <TextField fullWidth sx={{ m: 1 }} placeholder="Phone" type="text" value={phone} onChange={(e) => setPhone(e.target.value)} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            onClick={async () => {
              try {
                generalStore.setIsLoading(true);
                const userChanges: {
                  firstName?: string;
                  lastName?: string;
                  phone?: string;
                  userId: string;
                } = {
                  userId: userData?.userId as string,
                };
                if (userData?.firstName !== firstName) {
                  userChanges.firstName = firstName;
                }
                if (userData?.lastName !== lastName) {
                  userChanges.lastName = lastName;
                }
                if (userData?.phone !== phone) {
                  userChanges.phone = phone;
                }

                await patchRegistrationData(userChanges);
                generalStore.setIsLoading(false);
                generalStore.setMessage('Successfully Changed The User Data');
                handleClose();
              } catch (error: any) {
                generalStore.setIsLoading(false);
                generalStore.setError(error?.response?.data?.error?.message || error?.response?.data?.error || error.message);
              }
            }}
            fullWidth
            sx={{ m: 1 }}
            color="primary"
            variant="contained"
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export const EditDataButtonComponent: React.FC<{ userData: UserItem }> = ({ userData }) => {
  const [showDataFrom, setShowDataFrom] = React.useState(false);
  return (
    <>
      <UserDataDialog open={showDataFrom} setOpen={setShowDataFrom} userData={userData} />
      {userData.status === 'pending' ? <EditButton onClick={() => setShowDataFrom(true)} /> : null}
    </>
  );
};
