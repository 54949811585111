import { useEffect, useState } from 'react';
import { apiErrorHandling } from 'src/api/apierrorhandling';
import { getSupportCasesById, getUsersWithQuery } from 'src/api/oApi';
import { SupportCaseByIdResponse } from 'src/models/supportcaseById';

export const useSupportCaseByID = (params: { id: string; forceUpdate?: boolean }) => {
  const [supportCases, setSupportCases] = useState<SupportCaseByIdResponse | null>(null);
  const { id, forceUpdate } = params;
  useEffect(() => {
    const load = async () => {
      console.log('loading support case by id', forceUpdate);
      // generalStore.setIsLoading(true);
      try {
        const response = await getSupportCasesById(id);
        const userId = response.data.data?.userId;
        let user: any = null;
        if (userId) {
          const userResponse = await getUsersWithQuery({
            userId,
          });
          user = userResponse.data.data.users[0];
          if (user) {
            response.data.data.user = user;
          }
        }
        setSupportCases(response.data.data);
      } catch (error: any) {
        apiErrorHandling(error);
      } finally {
        // generalStore.setIsLoading(false);
      }
    };
    load();
  }, [id, forceUpdate]);
  return supportCases;
};
