import { useEffect, useState } from 'react';
import { apiErrorHandling } from 'src/api/apierrorhandling';
import { getOffers, OfferStatus } from 'src/api/oApi';
import { OffersResponse } from 'src/models/offers';
import { generalStore } from 'src/stores/GeneralStore';

export const useOffers = (params: {
  assetId?: string;
  firstParty?: string;
  offerId?: string;
  page?: number;
  secondParty?: string;
  status?: OfferStatus;
}) => {
  const [offers, setOffers] = useState<OffersResponse | null>(null);
  const { assetId, firstParty, offerId, page, secondParty, status } = params;
  useEffect(() => {
    const load = async () => {
      generalStore.setIsLoading(true);
      try {
        const filters = {
          assetId,
          firstParty,
          offerId,
          page,
          secondParty,
          status,
        };
        cleanFilter(filters);
        const response = await getOffers(filters);
        setOffers({
          count: response.data.data.count,
          offers: response.data.data.offers,
        });
      } catch (error: any) {
        apiErrorHandling(error);
      } finally {
        generalStore.setIsLoading(false);
      }
    };
    load();
  }, [assetId, firstParty, offerId, page, secondParty, status]);
  return offers;
};

export function cleanFilter(filters: any) {
  for (const [key, value] of Object.entries(filters)) {
    if (value === null || value === '' || value === 'All' || value === undefined) {
      delete filters[key as any];
    }
  }
}

export const fetchOffers = async (
  {
    assetId,
    firstParty,
    offerId,
    page,
    secondParty,
    status,
  }: {
    assetId: string | null | undefined;
    firstParty: string | null | undefined;
    offerId: string | null | undefined;
    page: number | null | undefined;
    secondParty: string | null | undefined;
    status: string | null | undefined;
  },
  setOffers: any,
) => {
  generalStore.setIsLoading(true);
  try {
    const filters = {
      assetId,
      firstParty,
      offerId,
      page,
      secondParty,
      status: status as OfferStatus,
    };
    cleanFilter(filters);
    const response = await getOffers(filters);
    setOffers({
      count: response.data.data.count,
      offers: response.data.data.offers,
    });
  } catch (error: any) {
    apiErrorHandling(error);
  } finally {
    generalStore.setIsLoading(false);
  }
};
