import { useEffect, useState } from 'react';
import { apiErrorHandling } from 'src/api/apierrorhandling';
import { Asset, getAssets } from 'src/api/oApi';
// import { OfferResponse } from 'src/models/offers';
import { generalStore } from 'src/stores/GeneralStore';

export const useAssets = () => {
  const [assets, setAssets] = useState<Asset[] | null>(null);
  useEffect(() => {
    const load = async () => {
      generalStore.setIsLoading(true);
      try {
        const response = await getAssets();
        setAssets(response.data as any);
      } catch (error: any) {
        apiErrorHandling(error);
      } finally {
        generalStore.setIsLoading(false);
      }
    };
    load();
  }, []);
  return assets;
};
