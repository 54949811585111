import { AxiosError } from 'axios';
import localforage from 'localforage';
import { makeAutoObservable, runInAction } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { getSession, postLogin, postLogout } from 'src/api/csApi';

export type AuthError = 'PasswordWrong' | 'Unknown';

class Auth {
  username = '';
  role = '';
  error: AuthError | null = null;
  isAuthenticated = false;
  isLoading = false;
  isRehydrated = false;

  constructor() {
    makeAutoObservable(this);
    this.initPersistence();
  }

  initPersistence = async () => {
    try {
      await makePersistable(this, {
        name: 'auth',
        properties: ['username', 'role'],
        storage: localforage,
      });
      if (!this.isRehydrated) {
        try {
          const response = await getSession();
          if (response.data.data.role !== 'supportUser' && response.data.data.role !== 'complianceUser') {
            throw new Error('Invalid role');
          }
          runInAction(() => {
            this.error = null;
            this.isAuthenticated = true;
            this.isLoading = false;
            this.isRehydrated = true;
          });
        } catch (error) {
          console.log('Error while trying to rehydrate auth', error);
          runInAction(() => {
            this.error = null;
            this.isAuthenticated = false;
            this.isLoading = false;
            this.isRehydrated = true;
          });
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        this.isRehydrated = true;
      });
    }
  };

  loginWithPassword = async (username: string, password: string) => {
    if (this.isLoading) {
      // bailout, noop
      return;
    }

    this.isLoading = true;

    try {
      const response = await postLogin(username, password);
      if (response.data.data.role !== 'supportUser' && response.data.data.role !== 'complianceUser') {
        throw new Error('Invalid role');
      }

      runInAction(() => {
        this.role = response.data.data.role;
        this.error = null;
        this.username = username;
        this.isLoading = false;
        this.isAuthenticated = true;
      });
    } catch (error: any) {
      console.log(error);
      runInAction(() => {
        this.isLoading = false;
      });

      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          this.wipe('PasswordWrong');
        } else {
          this.wipe('Unknown');
        }
      } else if (error.message === 'Invalid role') {
        this.wipe('PasswordWrong');
      } else {
        this.wipe('Unknown');
      }
    }
  };

  logout = async () => {
    await postLogout();
    this.wipe(null);
  };

  private wipe(error: AuthError | null) {
    this.error = error;
    this.isAuthenticated = false;
    this.isLoading = false;
    this.username = '';
    this.role = '';
  }
}

let authStore: Auth;
if (process.env.NODE_ENV === 'test') {
  class MockAuth {
    credentials: any = null;
    isAuthenticated = false;
    error: any = null;
    isRehydrated = true;

    constructor() {
      makeAutoObservable(this);
    }

    loginWithPassword = () => undefined;
    dismissError = () => undefined;
    logout = () => undefined;
  }

  authStore = new MockAuth() as any; // no localstorage support in node env
} else {
  authStore = new Auth();
}

// development, make auth available on window object...
(window as any).auth = authStore;

// singleton, exposes an instance by default
export { authStore };
