import { CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';

export const LoadingOverlay = ({ delayMs }: { delayMs?: number }) => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => setShow(true), delayMs ?? 0);
    return () => clearTimeout(timeout);
  }, [delayMs]);

  if (!show) {
    return null;
  }

  return (
    <div
      style={{
        display: 'flex',
        position: 'fixed',
        width: '100%',
        height: '100%',
        background: 'rgba(0, 0, 0, 0.75)',
        alignItems: 'center',
        justifyContent: 'center',
        left: 0,
        top: 0,
        zIndex: 9999,
      }}
    >
      <CircularProgress color="primary" />
    </div>
  );
};
