import { Grid, Checkbox, FormControlLabel } from '@mui/material';
import { FormikProps } from 'formik';
import { FC } from 'react';
import { useSearchParams } from 'react-router-dom';
import { TextSearchField } from '../TextSearchField';

interface Props {
  formik: FormikProps<{
    code: string;
    onlyValid: boolean;
  }>;
  setPage: any;
}

export const BonusCodeFilterDialog: FC<Props> = ({ formik, setPage }) => {
  const [, setSearchParams] = useSearchParams();

  return (
    <>
      <Grid container alignContent={'baseline'} spacing={1}>
        <Grid item>
          <TextSearchField
            onBlur={formik.handleBlur}
            name="code"
            setSearchParams={setSearchParams}
            setPage={setPage}
            label="Code"
            field={formik.values.code}
            setField={(field: any) => formik.setFieldValue('code', field)}
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            style={{ marginLeft: '10px' }}
            control={
              <Checkbox
                checked={formik.values.onlyValid}
                name="only valid bonus codes"
                onChange={(e) => {
                  formik.setFieldValue('onlyValid', e.target.checked);
                  setPage(0);
                }}
              />
            }
            label="Only Valid Bonus Codes"
          />
        </Grid>
      </Grid>
    </>
  );
};
