import { useEffect, useState } from 'react';
import { apiErrorHandling } from 'src/api/apierrorhandling';
import { getUserMessages } from 'src/api/oApi';
// import { OfferResponse } from 'src/models/offers';
import { generalStore } from 'src/stores/GeneralStore';

export const useEmails = ({ userId, page = 0 }: { userId: string; page: number }) => {
  const [emails, setEmails] = useState<
    | {
        subject: string;
        ArrivedAt: string;
        Status: string;
        AttachmentCount: string;
        CampaignID: string;
      }[]
    | null
  >(null);
  useEffect(() => {
    const load = async () => {
      generalStore.setIsLoading(true);
      try {
        const response = await getUserMessages(userId, page);
        setEmails(response.data?.data as any);
      } catch (error: any) {
        apiErrorHandling(error);
      } finally {
        generalStore.setIsLoading(false);
      }
    };
    load();
  }, [userId, page]);
  return emails;
};
