import { useEffect, useState } from 'react';
import { apiErrorHandling } from 'src/api/apierrorhandling';
import { getBonusCodes } from 'src/api/oApi';
import { GetBonusCodeResponse } from 'src/models/bonuscode';
import { generalStore } from 'src/stores/GeneralStore';

export const useBonusCodes = (reload: boolean, params: { code?: string; page?: number; onlyValid?: boolean }) => {
  const [bonuscodes, setBonusCodes] = useState<GetBonusCodeResponse | null>(null);

  const { code, page, onlyValid } = params;

  useEffect(() => {
    const load = async () => {
      generalStore.setIsLoading(true);
      try {
        const response = await getBonusCodes({
          ...params,
          expiresAt: onlyValid ? new Date().toISOString() : new Date('1999').toISOString(),
        });
        // const bonusCodes = response.data.data.users.map((user: any) => {
        //   return {
        //     ...user,
        //     id: user._id,
        //   };
        // });
        setBonusCodes({
          count: response.data.data.count,
          bonusCodes: response.data.data.bonusCodes,
        });
      } catch (error: any) {
        apiErrorHandling(error);
      } finally {
        generalStore.setIsLoading(false);
      }
    };
    load();
  }, [code, page, reload, onlyValid]);
  return bonuscodes;
};
