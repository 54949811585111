import { useEffect, useState } from 'react';
import { apiErrorHandling } from 'src/api/apierrorhandling';
import { getAffiliatePartnersList } from 'src/api/oApi';
import { generalStore } from 'src/stores/GeneralStore';

export const useAffiliatePartnerList = () => {
  const [partners, setPartners] = useState<string[]>([]);
  useEffect(() => {
    const load = async () => {
      generalStore.setIsLoading(true);
      try {
        const response = await getAffiliatePartnersList();

        const partnersData = response.data.data;
        setPartners(partnersData);
      } catch (error: any) {
        console.log(error);
        apiErrorHandling(error);
      } finally {
        generalStore.setIsLoading(false);
      }
    };
    load();
  }, []);
  return partners;
};
