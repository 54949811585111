import { Add, DeleteOutline, EditOutlined } from '@mui/icons-material';
import { Fab, IconButton, TableCell, TableRow } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { deleteQuestion } from 'src/api/csApi';
import { AddEditQuestionDialog } from 'src/components/dialogs/AddEditQuestionDialog';
import { BrickwiseTable } from 'src/components/mui-overrides/BrickwiseTable';
import { useQuestions } from 'src/hooks/useQuestions';
import { GetQuestionsResponse } from 'src/models/questions';
import { generalStore } from 'src/stores/GeneralStore';

export const QuestionsSite: FC = () => {
  const { t } = useTranslation();
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState<GetQuestionsResponse | null>(null);
  const [reload, setReload] = useState(false);
  const { questions, sections } = useQuestions(reload);
  return (
    <>
      <AddEditQuestionDialog
        open={openDialog}
        onClose={() => {
          setOpenDialog(false);
          setSelectedQuestion(null);
        }}
        onCloseAndReload={() => {
          setReload(!reload);
          setOpenDialog(false);
          setSelectedQuestion(null);
        }}
        usedSections={sections}
        oldQuestion={selectedQuestion}
      />
      <BrickwiseTable
        hidePagination={true}
        hideChangePageLengthChange={true}
        renderHeader={() => {
          return (
            <TableRow>
              <TableCell>{t('questions.table.title')}</TableCell>
              <TableCell>{t('questions.table.section')}</TableCell>
              <TableCell>{t('questions.table.locales')}</TableCell>
              <TableCell>{t('questions.table.order')}</TableCell>
              <TableCell>{t('questions.table.apps')}</TableCell>
              <TableCell>{t('questions.table.createdAt')}</TableCell>
              <TableCell>{t('questions.table.updatedAt')}</TableCell>
              <TableCell style={{ width: 50 }}></TableCell>
              <TableCell style={{ width: 50 }}></TableCell>
            </TableRow>
          );
        }}
        renderRows={() => {
          return (
            <>
              {questions?.map((row, index) => {
                return (
                  <TableRow key={index} style={{ textDecoration: 'none' }}>
                    <TableCell>{row.title}</TableCell>
                    <TableCell>{row.zendeskSection}</TableCell>
                    <TableCell>{row.locales}</TableCell>
                    <TableCell>{row.onPageFAQSectionOrder}</TableCell>
                    <TableCell>{row.apps?.join(',')}</TableCell>
                    <TableCell>{new Date(row.createdAt).toLocaleDateString() as unknown as string}</TableCell>
                    <TableCell>{new Date(row.updatedAt).toLocaleDateString() as unknown as string}</TableCell>

                    <TableCell>
                      <IconButton
                        sx={{ padding: 0 }}
                        color="primary"
                        component="label"
                        onClick={() => {
                          setSelectedQuestion(row);
                          setOpenDialog(true);
                        }}
                      >
                        <EditOutlined color="secondary" />
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <IconButton
                        sx={{ padding: 0 }}
                        color="primary"
                        component="label"
                        onClick={async () => {
                          try {
                            generalStore.setIsLoading(true);
                            await deleteQuestion(row.id);
                            setReload(!reload);
                          } catch (e) {
                            console.error(e);
                          } finally {
                            generalStore.setIsLoading(false);
                          }
                        }}
                      >
                        <DeleteOutline color="secondary" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </>
          );
        }}
        length={0}
      />

      <div>
        <Fab
          color="secondary"
          style={{
            position: 'fixed',
            right: 48,
            bottom: 48,
          }}
          onClick={() => setOpenDialog(true)}
        >
          <Add />
        </Fab>
      </div>
    </>
  );
};
