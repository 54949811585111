import { Box } from '@mui/material';
import { CSSProperties, FC } from 'react';
import { Colors } from 'src/components/utils/Theme';

interface Props {
  message: string;
  style?: CSSProperties;
}

export const ErrorMessage: FC<Props> = ({ message, style }) => {
  return (
    <Box
      style={style}
      sx={{ marginTop: '16px', backgroundColor: Colors.SECONDARY_COLOR, color: '#fff', padding: '14px', fontSize: '14px', fontWeight: 500 }}
    >
      {message}
    </Box>
  );
};
