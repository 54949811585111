import { ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import deLocale from 'date-fns/locale/de';
import { observer } from 'mobx-react';
import { theme } from './components/utils/Theme';
import { AppRouter } from './router/AppRouter';
import { authStore } from './stores/AuthStore';

const App: React.FunctionComponent = observer(() => {
  if (!authStore.isRehydrated) {
    return null;
  }

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={deLocale}>
        <AppRouter />
      </LocalizationProvider>
    </ThemeProvider>
  );
});

// eslint-disable-next-line import/no-default-export
export default App;
