import { FC } from 'react';
import { Grid, Table, TableBody, TableCell, TableRow } from '@mui/material';
import { UserItem } from 'src/models/users';
import { xsSize, sectionStyle, cellStyle, cellStyleValue } from '../../sites/UserDetailSite';

export const UserMarketingComponent: FC<{ marketingData: UserItem['marketingData']; marketingNewsletter: boolean }> = ({
  marketingData,
  marketingNewsletter,
}) => {
  if (!marketingData) return <></>;
  return (
    <Grid item xs={xsSize} style={{ ...sectionStyle }}>
      <Table>
        <TableBody>
          <TableRow hover={true}>
            <TableCell style={cellStyle}>Marketing Newsletter</TableCell>
            <TableCell style={cellStyleValue}>{marketingNewsletter ? 'YES' : 'NO'}</TableCell>
          </TableRow>

          <TableRow hover={true}>
            <TableCell style={cellStyle}>Last Used App</TableCell>
            <TableCell style={cellStyleValue}>{marketingData?.lastUsedApp || ''}</TableCell>
          </TableRow>

          <TableRow hover={true}>
            <TableCell style={cellStyle}>Partner</TableCell>
            <TableCell style={cellStyleValue}>{marketingData?.partnerId || ''}</TableCell>
          </TableRow>

          <TableRow hover={true}>
            <TableCell style={cellStyle}>Apps</TableCell>
            <TableCell style={cellStyleValue}>{marketingData?.usedApps?.join(',') || ''}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Grid>
  );
};
