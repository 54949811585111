import { Chip, IconButton, Menu, MenuItem, TableCell, TableRow, Tooltip } from '@mui/material';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BrickwiseTable } from 'src/components/mui-overrides/BrickwiseTable';
import { useTransactions } from 'src/hooks/useTransactions';
import ReactJson from 'react-json-view';
import { TransactionFilterDialog } from 'src/components/dialogs/TransactionFilterDialog';
import { TransactionResponse } from '../models/transaction';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { RefundDialog } from '../components/dialogs/RefundDialog';

export const TransactionSite: FC<{ userIdValue?: string }> = ({ userIdValue }: { userIdValue?: string }) => {
  const { t } = useTranslation();
  const [reload, setReload] = useState(false);
  const [page, setPage] = useState(0);
  const [name, setName] = useState('');
  const [status, setStatus] = useState(undefined);
  const [method, setMethod] = useState(undefined);
  const [type, setType] = useState(undefined);
  const [userId, setId] = useState(userIdValue);
  const setUserId = userIdValue
    ? () => {
        console.log('');
      }
    : setId;
  const [from, setFrom] = useState(undefined);
  const [to, setTo] = useState(undefined);
  const [sort, setSort] = useState<'oldest' | 'newest'>('newest');
  const [jsonDetail, setJSONDetail] = useState<null | unknown>(null);
  const transactions = useTransactions(
    {
      page,
      to,
      from,
      method,
      status,
      type,
      sort,
      userId,
    },
    reload,
  );

  return (
    <>
      <TransactionFilterDialog
        userId={userId}
        setUserId={setUserId}
        type={type}
        setType={setType}
        method={method}
        setMethod={setMethod}
        firstName={name}
        setFirstName={setName}
        from={from}
        setFrom={setFrom}
        to={to}
        setTo={setTo}
        status={status}
        setStatus={setStatus}
        setPage={setPage}
        sort={sort}
        setSort={setSort}
      />

      <div style={{ margin: '16px' }}>
        {[
          { field: status, name: 'Status', setFunction: setStatus },
          { field: method, name: 'Method', setFunction: setMethod },
          { field: type, name: 'Type', setFunction: setType },
          { field: to, name: 'To', setFunction: setTo },
          { field: from, name: 'From', setFunction: setFrom },
          { field: userId, name: 'User', setFunction: setUserId },
        ].map(({ field, name, setFunction }) => {
          if (field) {
            return (
              <Chip
                id={'chip-' + name}
                label={name + ':' + field}
                onDelete={() => {
                  setFunction(undefined);
                }}
              />
            );
          } else {
            return null;
          }
        })}
      </div>
      <BrickwiseTable
        page={page}
        rowsPerPage={10}
        hidePagination={false}
        hideChangePageLengthChange={false}
        length={transactions?.count ?? 0}
        setPage={setPage}
        renderHeader={() => {
          return (
            <TableRow>
              <TableCell>{t('users.table.name')}</TableCell>
              <TableCell style={{ textAlign: 'center' }}>{t('transactions.table.type')}</TableCell>
              <TableCell style={{ textAlign: 'center' }}>{t('transactions.table.date')}</TableCell>
              <TableCell style={{ textAlign: 'center' }}>{t('transactions.table.status')}</TableCell>
              <TableCell style={{ textAlign: 'center' }}>{t('transactions.table.method')}</TableCell>
              <TableCell style={{ textAlign: 'center' }}>{t('transactions.table.app')}</TableCell>
              <TableCell style={{ textAlign: 'center' }}>{t('transactions.table.amount')}</TableCell>
              <TableCell style={{ textAlign: 'center' }}>{t('transactions.table.title')}</TableCell>
            </TableRow>
          );
        }}
        renderRows={() => {
          return (
            <>
              {transactions?.data.map((row, index) => {
                const name = row.user?.firstName ? row.user?.firstName + ' ' + row.user?.lastName : '';
                return (
                  <TableRow
                    onClick={() => {
                      setJSONDetail(row);
                    }}
                    key={index}
                    hover
                    style={{ cursor: 'pointer', textDecoration: 'none' }}
                  >
                    <TableCell>{name}</TableCell>
                    <TableCell style={{ textAlign: 'center' }}>{row.transaction.type}</TableCell>
                    <TableCell style={{ textAlign: 'center' }}>{row.transaction.createdAt}</TableCell>
                    <TableCell style={{ textAlign: 'center' }}>{row.transaction.status}</TableCell>
                    <TableCell style={{ textAlign: 'center' }}>{row.transaction.method}</TableCell>
                    <TableCell style={{ textAlign: 'center' }}>{row.transaction.transactionInformation?.senderApp}</TableCell>
                    <TableCell style={{ textAlign: 'center' }}>{row.transaction.amount as unknown as string}</TableCell>
                    <TableCell onClick={(e) => e.stopPropagation()}>
                      <TransactionMenu row={row} reload={() => setReload(!reload)} />
                    </TableCell>
                  </TableRow>
                );
              })}
            </>
          );
        }}
      />
      {Boolean(jsonDetail) ? <ReactJson src={jsonDetail as any} /> : null}
    </>
  );
};

interface MenuProps {
  row: TransactionResponse;
  reload: () => void;
}

export const TransactionMenu: FC<MenuProps> = ({ row, reload }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [refundDialogOpen, setRefundDialogOpen] = useState(false);
  const { transaction } = row;

  const open = !!anchorEl;
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setRefundDialogOpen(false);
  };
  const isRefundEnabled = transaction.status.toLowerCase() === 'success' && transaction.type === 'charge';

  return (
    <>
      <RefundDialog open={refundDialogOpen} transaction={transaction} handleClose={handleClose} triggerRefresh={reload} />
      <IconButton
        onClick={(event) => {
          event.stopPropagation();
          handleClick(event);
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <Tooltip title="Only possible for successful charges" open={isRefundEnabled ? false : undefined}>
          {/* Span, as otherwise the tooltip won't be shown if MenuItem is disabled */}
          <span>
            <MenuItem onClick={() => setRefundDialogOpen(true)} disabled={!isRefundEnabled}>
              {t('transactions.table.action.refund')}
            </MenuItem>
          </span>
        </Tooltip>
      </Menu>
    </>
  );
};
