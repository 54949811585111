import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { FC } from 'react';
import { useSearchParams } from 'react-router-dom';

const Tabs: FC<{
  tabs: {
    label: string;
    component: any;
  }[];
  parameterPrefix?: string;
}> = ({ tabs, parameterPrefix }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get(parameterPrefix + '_tab') || '0';
  const [value, setValue] = React.useState(tab);
  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    setSearchParams({
      [parameterPrefix + '_tab']: newValue,
    });
    setValue(newValue);
  };

  React.useEffect(() => {
    setValue(tab);
  }, [tab]);

  return (
    <Box>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example" variant="scrollable">
            {tabs.map((tab, index) => (
              <Tab
                style={{ marginRight: '4px', color: '#1F847F', fontFamily: 'Aestetico-semibold', fontSize: '18px', textTransform: 'none' }}
                key={index}
                label={tab.label}
                value={index.toString()}
              />
            ))}
          </TabList>
        </Box>
        {tabs.map((tab, index) => (
          <TabPanel style={{ padding: '0px' }} key={index} value={index.toString()}>
            {tab.component}
          </TabPanel>
        ))}
      </TabContext>
    </Box>
  );
};

export default Tabs;
