import { useEffect, useState } from 'react';
import { apiErrorHandling } from 'src/api/apierrorhandling';
import { getPotentialUsers } from 'src/api/oApi';
import { generalStore } from 'src/stores/GeneralStore';

export const usePotentialUsers = ({ page = 0, email }: { page: number; email?: string }) => {
  const [partners, setPartners] = useState<{ count: number; data: { email: string; id: string }[] } | null>(null);
  useEffect(() => {
    const load = async () => {
      generalStore.setIsLoading(true);
      try {
        const partners: { count: number; data: { email: string; id: string }[] } = await getPotentialUsers({
          offset: page * 20,
          limit: 20,
          email,
        });
        setPartners(partners);
      } catch (error: any) {
        console.log(error);
        apiErrorHandling(error);
      } finally {
        generalStore.setIsLoading(false);
      }
    };
    load();
  }, [page, email]);
  return partners;
};
