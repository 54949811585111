import { TableCell } from '@mui/material';
import { FC } from 'react';
import { Link } from 'react-router-dom';

interface Props {
  children: JSX.Element | JSX.Element[] | string | undefined | null;
  to: string;
}

export const LinkTableCell: FC<Props> = ({ children, to }) => {
  return (
    <TableCell>
      <Link
        onClick={(e) => e.stopPropagation()}
        style={{
          color: 'inherit',
          textDecoration: 'none',
        }}
        to={to}
      >
        {children}
      </Link>
    </TableCell>
  );
};
