import { Button, TableCell, TableRow } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OfferFilter } from 'src/components/dialogs/OfferFilterDialog';
import { BrickwiseTable } from 'src/components/mui-overrides/BrickwiseTable';
import { fetchOffers } from 'src/hooks/useOffers';
import { setStateQueryParameter } from 'src/hooks/useStateQueryParams';
import { Offer, OffersResponse } from 'src/models/offers';
import { cellStyle } from './UserDetailSite';
import { getUsersWithQuery } from 'src/api/oApi';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ObjectComponent } from 'src/components/ObjectCompnent';
import { useAssets } from 'src/hooks/useAssets';
import { CopyToClipboardButton } from 'src/components/ui/CopyToClipboardButton';

export const OfferSite = () => {
  const [page, setPage] = setStateQueryParameter<number>(0, 'page', 'number');
  const assets = useAssets();
  const [offerDetail, setOfferDetail] = useState<any | null>(null);
  const [offers, setOffers] = useState<OffersResponse | null>(null);
  offers?.offers.map((offer) => {
    offer.assetTitle = assets?.find((asset) => asset.id === offer.assetId)?.title;
    offer.assetStage = assets?.find((asset) => asset.id === offer.assetId)?.stage as Offer['assetStage'];
  });
  return (
    <>
      <OfferFilter
        submit={(values: any) => {
          values.page = page;
          fetchOffers(values, setOffers);
          setOfferDetail(null);
        }}
        setPage={setPage}
        page={page}
      />
      <OfferList page={page} offers={offers} setPage={setPage} setOfferDetail={setOfferDetail} offerDetail={offerDetail} />
      <ObjectComponent obj={offerDetail} />
    </>
  );
};

const rowsPerPage = 10;

export function OfferList({
  offers,
  page,
  offerDetail,
  setOfferDetail,
  setPage,
}: {
  page: number;
  offers: OffersResponse | null;
  setPage: any;
  offerDetail?: any;
  setOfferDetail: any;
}) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  /** Fake pagination. Could be implemented as a real pagination in the future. */
  const shownOffers = offers?.offers.splice(page * rowsPerPage, rowsPerPage) || [];
  return (
    <BrickwiseTable
      page={page}
      rowsPerPage={rowsPerPage}
      hidePagination={false}
      hideChangePageLengthChange={false}
      length={offers?.count ?? 0}
      setPage={setPage}
      renderHeader={() => {
        return (
          <TableRow>
            <TableCell>{t('offers.table.id')}</TableCell>
            <TableCell>{t('offers.table.assetId')}</TableCell>
            <TableCell>Asset Title</TableCell>
            <TableCell>Asset Stage</TableCell>
            <TableCell>{t('offers.table.firstParty')}</TableCell>
            <TableCell>{t('offers.table.secondParty')}</TableCell>
            <TableCell>{t('offers.table.status')}</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>{t('offers.table.amount')}</TableCell>
            <TableCell>{t('offers.table.price')}</TableCell>
            <TableCell>CreatedAt</TableCell>
            <TableCell>UpdatedAt</TableCell>
          </TableRow>
        );
      }}
      renderRows={() => {
        return (
          <>
            {shownOffers.map((row, index) => {
              return (
                <TableRow
                  onClick={() => setOfferDetail(row)}
                  key={index}
                  hover
                  style={{
                    cursor: 'pointer',
                    textDecoration: 'none',
                    backgroundColor: row?._id === offerDetail?._id ? 'oldlace' : 'white',
                  }}
                >
                  <TableCell>
                    <CopyToClipboardButton text={row.offerId} label={row.offerId} />
                  </TableCell>
                  <TableCell>{row.assetId}</TableCell>
                  <TableCell>{row.assetTitle}</TableCell>
                  <TableCell>{row.assetStage}</TableCell>
                  <TableCell>
                    {row.firstParty ? (
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ maxWidth: '80px' }}
                        onClick={async () => await navigateToUserByWallet(row.firstParty, navigate)}
                      >
                        <span style={{ ...cellStyle, maxWidth: '100px' }}>{row.firstParty}</span>
                      </Button>
                    ) : null}
                  </TableCell>
                  <TableCell>
                    {row.secondParty ? (
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ maxWidth: '80px' }}
                        onClick={async () => await navigateToUserByWallet(row.secondParty, navigate)}
                      >
                        <span style={{ ...cellStyle, maxWidth: '100px' }}>{row.secondParty}</span>
                      </Button>
                    ) : null}
                  </TableCell>
                  <TableCell>{row.status}</TableCell>
                  <TableCell>{row.type}</TableCell>
                  <TableCell>{row.amount?.toString()}</TableCell>
                  <TableCell>{row.price?.toString()}</TableCell>
                  <TableCell>{row.createdAt}</TableCell>
                  <TableCell>{row.updatedAt}</TableCell>
                </TableRow>
              );
            })}
          </>
        );
      }}
    />
  );
}

const navigateToUserByWallet = async (wallet: string, navigate: any) => {
  const response = await getUsersWithQuery({ wallet: wallet });
  if (response?.data?.data.count > 0) {
    const userId = response?.data?.data.users[0].userId;
    navigate(`/user/${userId}?userDetail_tab=0`);
    // we have to set user param:
    const [searchParams, setSearchParams] = useSearchParams();
    setSearchParams({ ...searchParams, userDetail_tab: '0' });
  }
};
