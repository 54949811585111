import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { LoadingButton } from '@mui/lab';
import { TextField } from '@mui/material';
import { createBonus } from 'src/api/oApi';
import { generalStore } from 'src/stores/GeneralStore';

// eslint-disable-next-line @typescript-eslint/naming-convention
export default function AddBonsDialog({
  open,
  setOpen,
  userData,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  userData: {
    firstName: string;
    lastName: string;
    phone: string;
    userId: string;
  };
}) {
  const handleClose = () => {
    setOpen(false);
  };
  const [bonusAmount, setBonusAmount] = React.useState('');
  const [bonusTitle, setBonusTitle] = React.useState('');

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Add Bonus To User</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <TextField
              fullWidth
              sx={{ m: 1 }}
              placeholder="Amount"
              type="number"
              value={bonusAmount}
              onChange={(e) => setBonusAmount(e.target.value)}
            />
            <TextField
              fullWidth
              sx={{ m: 1 }}
              placeholder="Title"
              type="text"
              value={bonusTitle}
              onChange={(e) => setBonusTitle(e.target.value)}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            disabled={!bonusAmount || !bonusTitle}
            onClick={async () => {
              try {
                generalStore.setIsLoading(true);
                await createBonus({
                  title: bonusTitle as string,
                  amount: Number(bonusAmount),
                  userId: userData?.userId as string,
                });
                setBonusAmount('');
                setBonusTitle('');
                generalStore.setMessage('Successfully Created the Bonus');
                generalStore.setIsLoading(false);
                handleClose();
              } catch (error: any) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                generalStore.setIsLoading(false);
                generalStore.setError(
                  error?.response?.data?.error?.message || error?.response?.data?.error || error?.message || 'Error on Operation',
                );
              }
            }}
            fullWidth
            sx={{ m: 1 }}
            color="primary"
            variant="contained"
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
