import { Button, TableCell, TableRow } from '@mui/material';
import { FC, useState } from 'react';
import { PartnerReports } from 'src/components/PartnerReports';
import { BrickwiseTable } from 'src/components/mui-overrides/BrickwiseTable';
import { usePartners } from 'src/hooks/usePartners';

export const PartnerSite = () => {
  const partners = usePartners();
  return (
    <>
      <PartnerList partners={partners} />
    </>
  );
};
export function PartnerList({ partners }: { partners: any }) {
  return (
    <BrickwiseTable
      hidePagination={true}
      length={partners?.length}
      hideChangePageLengthChange={true}
      renderHeader={() => {
        return (
          <TableRow>
            <TableCell></TableCell>
            <TableCell>PartnerId</TableCell>
            <TableCell>Legal Name</TableCell>
            <TableCell>Short Name</TableCell>
            <TableCell></TableCell>
          </TableRow>
        );
      }}
      renderRows={() => {
        return (
          <>
            {partners?.map((row: any, index: number) => {
              return (
                <TableRow
                  key={'partners-' + index}
                  hover
                  style={{
                    cursor: 'pointer',
                    textDecoration: 'none',
                  }}
                >
                  <TableCell style={{ width: '70px', height: '50px' }}>
                    <img style={{ width: '70px', height: '50px' }} src={row.logo} alt="logo" />
                  </TableCell>
                  <TableCell>{row.partnerId}</TableCell>
                  <TableCell>{row.partnerLegalName}</TableCell>
                  <TableCell>{row.partnerShortName}</TableCell>
                  <TableCell>
                    <ReportButton partnerId={row.partnerId} />
                  </TableCell>
                </TableRow>
              );
            })}
          </>
        );
      }}
    />
  );
}

const ReportButton: FC<{ partnerId: string }> = ({ partnerId }) => {
  const [showReportDialog, setShowReportDialog] = useState(false);
  return (
    <>
      <PartnerReports
        partnerId={partnerId}
        onClose={() => {
          setShowReportDialog(false);
        }}
        open={showReportDialog}
        key={'partnerid-' + partnerId}
      />
      <Button
        color="inherit"
        variant="outlined"
        style={{ fontSize: '10px' }}
        onClick={() => {
          setShowReportDialog(true);
        }}
      >
        Reports
      </Button>
    </>
  );
};
